import React from "react";
import moment from 'moment';
import { Container, FormControl, Accordion, Card, Row, Col, Button, Modal } from 'react-bootstrap';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import allergenService from '../../services/AllergenService';
import productService from '../../services/ProductService';
import userService from '../../services/UserService';
import ProductParser from '../../utils/ProductParser';
import '../../styles/scenes/ProductsScene.css';
import ProductForm from './ProductForm';
import SubmissionContainer from '../../components/SubmissionContainer';


let timeout = null
class ProductsScene extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			products: [],
			hintText: 'Buscar un producto',
			orderBy: "name",
			orderDirection: "asc",
			loading: true,
			page: 1,
			pages: null,
			searchText: '',
			editing: false,
			editingProductIndex: null,
			deletingProduct: null
		};

		this.checkedInRestaurant = undefined !== userService.getCheckInRestaurantInfo();
	}

	getProducts = () => {
		let params = { type: 'product', page: this.state.page }

		params['ordering'] = (this.state.orderDirection === 'asc' ? '' : '-') + this.state.orderBy
		params['name'] = this.state.searchText

		productService.getProducts(params).then(result => {
			result = JSON.parse(result)
			this.setState({
				products: this.state.products.concat(result.results),
				loading: false,
				pages: Math.ceil(result.count/50),
			});
		});
	}

	getProduct = id => {
		productService.getProduct(id).then(result => {
			result = JSON.parse(result)
			this.displayProduct(result);
		});
	}

	displayProduct = (product, replace) => {
		const products = this.state.products;
		if (replace)
			products[this.state.editingProductIndex] = product;

		this.setState({
			products: replace ? products : [product],
			loading: false,
			editing: false,
			editingProductIndex: null,
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			this.checkedInRestaurant &&
			(this.state.orderBy !== prevState.orderBy ||
			this.state.orderDirection !== prevState.orderDirection ||
			this.state.page !== prevState.page ||
			this.state.searchText !== prevState.searchText)) {
				this.getProducts();
		}
	}

	componentDidMount() {
		if (this.checkedInRestaurant) {
			const id = parseInt(this.props.match.params.id);
			isNaN(id) ? this.getProducts() : this.getProduct(id);
		}
		document.getElementById('content').addEventListener('scroll', this.onScroll);
	}

	componentWillUnmount() {
		document.getElementById('content').removeEventListener('scroll', this.onScroll);
	}

	onSearchChange = event => {
		if (timeout)
			clearTimeout(timeout);

		event.persist();
		timeout = setTimeout(() => {
			this.setState({
				searchText: event.target.value,
				products: [],
				page: 1
			});
		}, 500);
	}

	onNewClicked = event => {
		this.setState({
			editing: true,
		});
	}

	onEditClicked = (event, productIndex) => {
		this.setState({
			editing: true,
			editingProductIndex: productIndex,
		});
	}

	handleCloseEdit = () => {
		this.setState({
			editing: false,
			editingProductIndex: null,
		});
	}

	deleteProduct = () => {
		productService.deleteProduct(this.state.deletingProduct.id)
			.then((data) => {
				const i = this.state.products.indexOf(this.state.deletingProduct);
				this.state.products.splice(i, 1);

				this.setState({
					deletingProduct: null,
					products: this.state.products,
				})
			})
			.catch((error) => {
				for (let e of error.errors) {
					if (e.error_code === "product_under_use") {
						alert(this.props.t('product_scene_error_under_use') + "\n\n" + e.escandalls_using.join(', '))
					}
				}
			});
	}

	getRenderedInfoItem = (title, content) => {
		return (
			<div>
				<p className='property-header'>{title}</p>
				<p className='property-content'>{content}</p>
			</div>
		);
	}

	getRenderedAllergens = allergens => {
		const allergenIconStyle = {
			width: '50px',
			height: '50px',
			margin: '5px',
		}

		if (allergens && allergens.length > 0) {
			return allergens.map(allergen =>
				<img key={`allergen-img-${allergen}`} style={allergenIconStyle} src={allergenService.getAllergen(allergen)} alt={this.props.t(`allergen_${allergen}`)} title={this.props.t(`allergen_${allergen}`)}/>,
			);
		}
	}

	getRenderedRows = () => {
		const renderedRows = this.state.products.map((product, i) => {
			const productParser = new ProductParser(product);
			return (
				<Card key={`product-display-card${i}`}>
					<Accordion.Toggle as={Card.Header} eventKey={`${i}`} style={{backgroundColor: '#F6F5F6'}}>
						<span className="float-left">{product.name}</span>
						<span style={{ color: '#AAAAAA' }} className="float-left">&nbsp;&nbsp;&nbsp;&nbsp;{productParser.grossCost()}</span>
					</Accordion.Toggle>
					<Accordion.Collapse eventKey={`${i}`}>
						<Card.Body className="px-5" style={{backgroundColor: 'white'}}>
								<Row>
									<Col>
										{this.getRenderedInfoItem(this.props.t('product_scene_measure'), productParser.measure())}
										{this.getRenderedInfoItem(this.props.t('product_scene_price'), productParser.price())}
										{this.getRenderedInfoItem(this.props.t('product_scene_shrinkage'), productParser.shrinkage())}
									</Col>
									<Col>
										{this.getRenderedInfoItem(this.props.t('product_scene_net_cost'), productParser.netCost())}
										{this.getRenderedInfoItem(this.props.t('product_scene_gross_cost'), productParser.grossCost())}
									</Col>
									<Col>
										<p className='property-header'>{this.props.t('product_scene_allergens')}</p>
										{this.getRenderedAllergens(product.allergens)}
									</Col>
								</Row>
								<Row>
									<Col>
										<p className="float-left mb-0" style={{ color: '#dadada', position: 'absolute', bottom: 0 }}>{this.props.t('product_scene_last_updated')}{moment(product.last_updated).format('DD MMMM YYYY, kk:mm')}</p>
										<div className="float-right">
											<Button variant="outline-secondary" className="mr-2" onClick={event => this.onEditClicked(event, i)}>{this.props.t('product_scene_edit')}</Button>
											<Button variant="outline-destructive" onClick={() => this.setState({ deletingProduct: product })}>{this.props.t('product_scene_delete')}</Button>
										</div>
									</Col>
								</Row>
						</Card.Body>
					</Accordion.Collapse>
				</Card>);
		}
		);
		return renderedRows;
	}

	getRenderedEditionModal = () => {
		if (this.state.editing) {
			return (
				<ProductForm
					isNew={!this.state.products[this.state.editingProductIndex]}
					product={this.state.products[this.state.editingProductIndex]}
					handleCloseEdit={this.handleCloseEdit}
					displayProduct={this.displayProduct}
				/>
			);
		}
	}

	getRenderedDeletingModal = () => {
		if (this.state.deletingProduct) {
			return (
				<Modal show={true} onHide={() => this.setState({ deletingProduct: null })}>
					<Modal.Header closeButton>
						<Modal.Title>{this.props.t('product_scene_deleting_title')}</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<p>{this.props.t('product_scene_deleting_text')}{this.state.deletingProduct.name}?</p>
					</Modal.Body>

					<Modal.Footer>
						<SubmissionContainer loading={this.state.formSubmitting}>
							<Button variant="outline-secondary" onClick={() => this.setState({ deletingProduct: null })}>{this.props.t('product_scene_deleting_cancel')}</Button>
							<Button variant="destructive" onClick={this.deleteProduct}>{this.props.t('product_scene_deleting_delete')}</Button>
						</SubmissionContainer>
					</Modal.Footer>
				</Modal>
			);
		}
	}

	render() {
		const NoRestaurantPlaceholder = (
			<Card className='text-center'>
				<Card.Body>
					<Card.Text>
						{this.props.t('product_scene_no_restaurant_text')}
					</Card.Text>
					<Button variant="primary" onClick={() => this.props.history.push('/restaurants')}>{this.props.t('product_scene_no_restaurant_button')}</Button>
				</Card.Body>
			</Card>
		);
		return (
			<Container className='pt-4 pb-4'>
				<Row>
					<Col xs={4}>
						<Button variant="primary" onClick={this.onNewClicked} style={{ marginTop: '70px', left: 0 }}>
							<span style={{ textTransform: 'uppercase', fontWeight: '800' }}>{this.props.t('product_scene_new')}</span>
							<FontAwesomeIcon style={{ marginLeft: '10px' }} icon="plus"/>
						</Button>
					</Col>
					<Col>
						<div className="d-flex mb-3" >
							<FormControl type="text" placeholder={this.props.t('product_scene_search')} autoFocus size='lg' onChange={this.onSearchChange} />
						</div>
						<Accordion activeKey={1 === this.state.products.length ? '0' : undefined}>
							{this.getRenderedRows()}
						</Accordion>
						{!this.checkedInRestaurant && NoRestaurantPlaceholder}
						{this.getRenderedEditionModal()}
						{this.getRenderedDeletingModal()}
					</Col>
				</Row>
			</Container>
		);
	}
}

export default withTranslation()(withRouter(ProductsScene));
