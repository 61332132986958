import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import productService from '../../services/ProductService';
import ProductParser from '../../utils/ProductParser';

import React from 'react';
import { withTranslation } from 'react-i18next';


/**
 *  This class represents the lookahead name field used to select an ingredient for an escandall
 */
class IngredientSelector extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			allowNew: false,
			isLoading: false,
			multiple: false,
			ingredientsSrc: [],
		}
	}

	render() {
		return (
			<AsyncTypeahead
				id='ingredient-searcher'
				minLength={0}
				placeholder={this.props.t('escandall_form_ingredient_selector_placeholder')}
				promptText={this.props.t('escandall_form_ingredient_selector_hint')}
				searchText={this.props.t('escandall_form_ingredient_selector_searching')}
				emptyLabel={this.props.t('escandall_form_ingredient_selector_no_results_found')}
				defaultInputValue={this.props.value}
				onChange={selection => this.props.onIngredientSelection(selection[0])}

				isLoading={this.state.isLoading}
				// labelKey={option => `${option.name}`}
				labelKey={ingredient => {
					const productParser = new ProductParser(ingredient);
					return `${ingredient.name} (${productParser.grossCost(ingredient)})`;
				}}
				onSearch={(query) => {
					this.setState({isLoading: true});
					productService.getIngredients({ name: query }).then(result => { 
						result = JSON.parse(result)
						this.setState({
							isLoading: false,
							ingredientsSrc: result,
						});
					});
				}}
				options={this.state.ingredientsSrc}
			/>
		);
	}
}

export default withTranslation()(IngredientSelector);
