import i18n from "i18next";


export default class IngredientsParser {
	constructor(ingredients, customMeasure) {
		this.ingredients = ingredients;
		this.customMeasure = customMeasure;
	}

	measure(i, displayUnits=true) {
		const ingredient = this.ingredients[i];
		let measure = '-';
		if (ingredient.measure) {
			measure = (ingredient.unitary_measure * this.customMeasure).toFixed(2).replace(/\.00$/, '');
			if (displayUnits)
				measure += ' ' + i18n.t(`unit_${ingredient.units}`);
		}
		return measure;
	}

	shrinkage(i, displayUnits=true) {
		const ingredient = this.ingredients[i];
		let result = '-';
		if (ingredient.shrinkage || ingredient.shrinkage === 0) {
			result = ingredient.shrinkage * 100;
			if (displayUnits)
				result += '%';
		}
		return result;
	}

	grossPrice(i, displayUnits=true) {
		const ingredient = this.ingredients[i];
		let result = '-';
		if (ingredient.ingredient_type !== 'escandall' && (ingredient.gross_price || ingredient.gross_price === 0)) {
			result = ingredient.gross_price.toFixed(2).replace(/\.00$/, '');
			if (displayUnits)
				result += ' €/' + i18n.t(`unit_${ingredient.price_units}`);
		}
		return result;
	}

	netPrice(i, displayUnits=true) {
		const ingredient = this.ingredients[i];
		let result = '-';
		if (ingredient.net_price || ingredient.net_price === 0) {
			result = ingredient.net_price.toFixed(2).replace(/\.00$/, '');
			if (displayUnits)
				result += ' €/' + i18n.t(`unit_${ingredient.price_units}`);
		}
		return result;
	}

	netCost(i, displayUnits=true) {
		const ingredient = this.ingredients[i];
		let result = '-';
		if (ingredient.net_cost || ingredient.net_cost === 0) {
			result = (ingredient.net_cost * this.customMeasure).toFixed(2).replace(/\.00$/, '');
			if (displayUnits)
				result += ' €';
		}
		return result;
	}

	netCostPercent(i, displayUnits=true) {
		let totalCost = 0;
		let result = '-';
		for (let ing in this.ingredients) {
			if  (this.ingredients[ing].net_cost) {
				totalCost += this.ingredients[ing].net_cost;
			}
		}
		result = (this.ingredients[i].net_cost / totalCost * 100).toFixed(0);
		if (displayUnits)
			result += '%';
		return result;

	}
}
