import React from "react";

import { Row, Col, Form, Container, Button, InputGroup, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as yup from 'yup';

import productService from '../../services/ProductService';
import allergenService from '../../services/AllergenService';
import SubmissionContainer from '../../components/SubmissionContainer';


/**
 *  This class represents the modal that opens up to edit Products
 */
class ProductForm extends React.Component {
	constructor(props) {
		super(props);

		this.product = this.props.product || { shrinkage: 0 };

		this.state = {
			formSubmitting: false,
		};
	}

	allergenClicked = (allergen, isSelected) => {
		const product = this.product;
		if (isSelected) {
			if (!product.allergens)
				product.allergens = []

			if (!product.allergens.includes(allergen))
				product.allergens.push(allergen);
		}
		else {
			if (product.allergens.includes(allergen)) {
				const index = product.allergens.indexOf(allergen);
				if (index > -1)
					product.allergens.splice(index, 1);
			}
		}

		this.setState({
			product: product,
		})
	}

	handleSave = values => {
		this.setState({
			formSubmitting: true,
		});

		if (typeof(parseInt(values.shrinkage)) === 'number')
			values.shrinkage /= 100.0;

		if (values.measure === '')
			values.measure = null;

		if (values.price === '')
			values.price = null;

		const product = Object.assign({}, this.product, values);

		// create
		if (this.props.isNew) {
			productService.createProduct(product)
				.then(result => {
					this.props.displayProduct(JSON.parse(result));
				})
				.catch(error => {
					this.setState({
						formSubmitting: false,
					});
				});
		}

		// edit
		else {
			productService.saveProduct(product)
				.then(result => {
					this.props.displayProduct(JSON.parse(result), true);
				})
				.catch(error => {
					this.setState({
						formSubmitting: false,
					});
				});
		}
	}

	renderAllergenPicker = () => {
		const allergenPicker = allergen => {
			return (
				<Form.Check
						key={`checkbox-${allergen}`}
						id={`checkbox-${allergen}`}
						type='checkbox'
						label={this.props.t(`allergen_${allergen[0]}`)}
						checked={this.product.allergens !== undefined ? this.product.allergens.includes(allergen[0]) : false}
						onChange={event => this.allergenClicked(allergen[0], event.target.checked)}>
				</Form.Check>
			)
		};

		const allergens = allergenService.getAllergens();
		const half = Math.ceil(Object.keys(allergens).length / 2);
		return (
			<Row>
				<Col>
					{Object.entries(allergens).slice(0, half).map(allergenPicker)}
				</Col>
				<Col>
					{Object.entries(allergens).slice(half + 1, Object.entries(allergens).length).map(allergenPicker)}
				</Col>
			</Row>
		);
	}

	render() {
		const formSchema = yup.object({
			name: yup.string().required('form_required_field'),
			measure: yup.number().min(0).notRequired(),
			units: yup.string().notOneOf(['']).required('form_required_field'),
			shrinkage: yup.number().min(0).max(100).notRequired(),
			price: yup.number().min(0).notRequired(),
		});

		const initialValues = Object.assign({}, this.product);
		initialValues.shrinkage = initialValues.shrinkage * 100;
		initialValues.price = null !== initialValues.price ? initialValues.price : undefined;
		initialValues.measure = null !== initialValues.measure ? initialValues.measure : undefined;
		return (
			<Formik
				validationSchema={formSchema}
				initialValues={initialValues}
				// in theory this should serve to check fields when prefilled (empty form shouldn't be valid), but it's not working
				// https://github.com/formium/formik/issues/1950
				// we keep it to false then, because otherwise, and only the first time we select an allergen, the form re-renders and even
				// if it was filled, it becomes invalid with false errors
				validateOnMount={false}
			>
				{({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
					<Modal show={true} centered onHide={this.props.handleCloseEdit} size='lg' dialogClassName='product-modal'>
						<Modal.Header closeButton>
							{this.props.t('product_form_editing_title')}
						</Modal.Header>
						<Modal.Body>
							<Container>
								<Row>
									<Col className='property-form'>
										<p className='property-header'>{this.props.t('product_form_name')}</p>
										<Form.Control
											type='text'
											name='name'
											value={values.name || undefined}
											onChange={handleChange}
											isInvalid={errors.name}
											onBlur={handleBlur}
										/>
										<Form.Control.Feedback type="invalid">
											{this.props.t(errors.name)}
										</Form.Control.Feedback>
									</Col>
								</Row>
								<Row>
									<Col>
										<Row className='property-form'>
											<Col>
												<Form.Label className='property-header'>{this.props.t('product_form_measure')}</Form.Label>
												<Form.Control
													name='measure'
													value={values.measure}
													onChange={handleChange}
													isInvalid={errors.measure}
												/>
											</Col>
											<Col>
												<Form.Label className='property-header'>{this.props.t('product_form_units')}</Form.Label>
												<OverlayTrigger placement='right' overlay={<Tooltip id='product-units-tooltip'>{this.props.t('product_form_units_tooltip')}</Tooltip>}>
													<FontAwesomeIcon icon="info-circle"/>
												</OverlayTrigger>
												<Form.Control
													name='units'
													value={this.props.isNew && !touched.units ? undefined : values.units}
													as="select"
													onChange={handleChange}
													isInvalid={errors.units}
												>
													<option key='selectHint' hidden value></option>
													{productService.SystemUnits().map(unit => <option key={`unit-option-${unit}`} value={unit}>{this.props.t(`unit_${unit}`)}</option>)}
												</Form.Control>
												<Form.Control.Feedback type="invalid">
													{this.props.t(errors.units)}
												</Form.Control.Feedback>
											</Col>
										</Row>
										<Row className='property-form'>
											<Col>
												<p className='property-header'>{this.props.t('product_form_shrinkage')}</p>
												<InputGroup className='property-form'>
													<Form.Control
														name='shrinkage'
														value={values.shrinkage}
														onChange={handleChange}
														isInvalid={errors.shrinkage}
													/>
													<InputGroup.Append>
														<InputGroup.Text>%</InputGroup.Text>
													</InputGroup.Append>
												</InputGroup>
											</Col>
											<Col>
												<p className='property-header'>{this.props.t('product_form_price')}</p>
												<InputGroup>
													<Form.Control
														name='price'
														value={values.price}
														onChange={handleChange}
														isInvalid={errors.price}
														className='property-form'
													/>
													<InputGroup.Append>
														<InputGroup.Text>€</InputGroup.Text>
													</InputGroup.Append>
												</InputGroup>
											</Col>
										</Row>
									</Col>
									<Col className='ml-4'>
										<div className='property-form'>
											<p className='property-header'>{this.props.t('product_form_allergens')}</p>
											{this.renderAllergenPicker()}
										</div>
										<div className='property-form'>
											<p className='property-header'>{this.props.t('product_form_tags')}</p>
										</div>
									</Col>
								</Row>

							</Container>
						</Modal.Body>
						<Modal.Footer>
							<SubmissionContainer loading={this.state.formSubmitting}>
								<Button variant="primary" disabled={!isValid} onClick={isValid ? () => this.handleSave(values) : undefined}>
									{this.props.t('product_form_save')}
								</Button>
								<Button variant="outline-secondary" onClick={this.props.handleCloseEdit}>
									{this.props.t('product_form_close')}
								</Button>
							</SubmissionContainer>
						</Modal.Footer>
					</Modal>
				)}
			</Formik>
		);
	}
}

export default withTranslation()(withRouter(ProductForm));
