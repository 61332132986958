import React from 'react';

// import userService from '../../services/UserService';
// import restaurantService from '../../services/RestaurantService';
import SubmissionContainer from '../../components/SubmissionContainer';

// import { Formik } from 'formik';
// import * as yup from 'yup';
import { Container, Row, Col, Button, Modal, Table, Card } from 'react-bootstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';

import { Elements} from '@stripe/react-stripe-js';
import { loadStripe} from '@stripe/stripe-js';


const stripePromise = loadStripe('pk_test_JJ1eMdKN0Hp4UFJ6kWXWO4ix00jtXzq5XG');

/**
 *  This class represents the substription management tab, which includes payment process
 */
class RestaurantSubscription extends React.Component {
	constructor(props) {
		super(props);

		this.restaurantInfo = this.props.restaurantInfo;

		this.state = {
			formSubmitting: false,
			subscribing: false,
		}
	}

	getRenderedSubscriptionModal = () => {
		if (this.state.subscribing) {
			return (
				<Modal show={true} onHide={() => this.setState({ deletingRestaurant: null })}>
					<Modal.Header closeButton>
						<Modal.Title>{this.props.t('restaurant_scene_deleting_title')}</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<Elements stripe={stripePromise}>
						</Elements>
					</Modal.Body>

					<Modal.Footer>
						<SubmissionContainer loading={this.state.formSubmitting}>
							<Button variant="outline-secondary" onClick={() => this.setState({ deletingRestaurant: null })}>{this.props.t('restaurant_scene_deleting_cancel')}</Button>
							<Button variant="destructive" onClick={this.deleteRestaurant}>{this.props.t('restaurant_scene_deleting_delete')}</Button>
						</SubmissionContainer>
					</Modal.Footer>
				</Modal>
			);
		}
	}

	render () {
		return (
			<Container>
				<h4 className='text-left mb-4 mt-3'>{this.props.t('restaurant_subscription_current_plan_section_title')}</h4>
				<Row>
					<Col>
						<Table className="ml-4 mb-0">
							<tbody>
								<tr>
									<td>
										Plan
									</td>
									<td>
										PREMIUM (20€)
									</td>
								</tr>
								<tr>
									<td>
										Subscribing admin
									</td>
									<td>
										HOSÉ
									</td>
								</tr>
								<tr>
									<td>
										Next subscription date
									</td>
									<td>
										11-12-2020
									</td>
								</tr>
							</tbody>
						</Table>
					</Col>
				</Row>

				<h4 className='text-left mb-4 mt-3'>{this.props.t('restaurant_subscription_change_plan_section_title')}</h4>
				<Row>
					<Col>
						<Card>
							<Card.Body>
								<Card.Title>Card Title</Card.Title>
								<Card.Text>
									admin
									export
									t
									o
								</Card.Text>
								<Button variant="primary">Go somewhere</Button>
							</Card.Body>
						</Card>
					</Col>
					<Col>
						<Card style={{ width: '18rem' }}>
							<Card.Body>
								<Card.Title>Card Title</Card.Title>
								<Card.Text>
									admin
									export
									t
									o
								</Card.Text>
								<Button variant="primary">Go somewhere</Button>
							</Card.Body>
						</Card>
					</Col>
					<Col>
						<Card style={{ width: '18rem' }}>
							<Card.Body>
								<Card.Title>Card Title</Card.Title>
								<Card.Text>
									admin
									export
									t
									o
								</Card.Text>
								<Button variant="primary">Go somewhere</Button>
							</Card.Body>
						</Card>
					</Col>
					{this.getRenderedSubscriptionModal()}
				</Row>
			</Container>
		);
	}
};

export default withTranslation()(RestaurantSubscription); 
