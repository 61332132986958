export default {
	terms_title: 'Términos de servicio',
	terms_content: `
		1. Términos

		Al acceder al sitio web en https://cuintab.com, usted acepta estar sujeto a estos términos de servicio, todas las leyes y regulaciones aplicables, y acepta que es responsable del cumplimiento de las leyes locales aplicables. Si no está de acuerdo con alguno de estos términos, tiene prohibido utilizar o acceder a este sitio. Los materiales contenidos en este sitio web están protegidos por las leyes de derechos de autor y marcas registradas aplicables.

		2. Licencia de uso

		Se otorga permiso para descargar temporalmente una copia de los materiales (información o software) en el sitio web de Cuintab para visualización transitoria personal y no comercial únicamente. Esta es la concesión de una licencia, no una transferencia de título, y bajo esta licencia no puede:
		        - modificar o copiar los materiales;
		        - utilizar los materiales para cualquier propósito comercial o para cualquier exhibición pública (comercial o no comercial);
		        - intentar descompilar o realizar ingeniería inversa de cualquier software contenido en el sitio web de Cuintab;
		        - eliminar los derechos de autor u otras anotaciones de propiedad de los materiales; o
		        - transferir los materiales a otra persona o "reflejar" los materiales en cualquier otro servidor.
		Esta licencia terminará automáticamente si viola cualquiera de estas restricciones y Cuintab puede cancelarla en cualquier momento. Al terminar su visualización de estos materiales o al finalizar esta licencia, debe destruir cualquier material descargado en su posesión, ya sea en formato electrónico o impreso.


		3. Descargo de responsabilidad

		Los materiales en el sitio web de Cuintab se proporcionan "tal cual". Cuintab no ofrece garantías, expresas o implícitas, y por la presente renuncia y niega todas las demás garantías, incluidas, entre otras, las garantías implícitas o términos de comerciabilidad, idoneidad para un propósito particular o no infracción de la propiedad intelectual u otra violación de derechos.
		Además, Cuintab no garantiza ni hace ninguna declaración con respecto a la precisión, los resultados probables o la confiabilidad del uso de los materiales en su sitio web o en relación con dichos materiales o en cualquier sitio vinculado a este sitio.


		4. Limitaciones

		En ningún caso Cuintab o sus proveedores serán responsables de los daños (incluidos, entre otros, daños por pérdida de datos o ganancias, o debido a la interrupción del negocio) que surjan del uso o la imposibilidad de usar los materiales en el sitio web de Cuintab, incluso si Cuintab o un representante autorizado de Cuintab han sido notificados oralmente o por escrito de la posibilidad de tal daño. Debido a que algunas jurisdicciones no permiten limitaciones sobre garantías implícitas o limitaciones de responsabilidad por daños consecuentes o incidentales, estas limitaciones pueden no aplicarse en su caso.

		5. Precisión de los materiales

		Los materiales que aparecen en el sitio web de Cuintab pueden incluir errores técnicos, tipográficos o fotográficos. Cuintab no garantiza que ninguno de los materiales de su sitio web sea preciso, completo o actualizado. Cuintab puede realizar cambios en los materiales contenidos en su sitio web en cualquier momento sin previo aviso. Sin embargo, Cuintab no se compromete a actualizar los materiales.

		6. Enlaces

		Cuintab no ha revisado todos los sitios vinculados a su sitio web y no es responsable del contenido de dichos sitios vinculados. La inclusión de cualquier enlace no implica la aprobación del sitio por parte de Cuintab. El uso de cualquier sitio web vinculado es por cuenta y riesgo del usuario.

		7. Modificaciones

		Cuintab puede revisar estos términos de servicio para su sitio web en cualquier momento sin previo aviso. Al utilizar este sitio web, acepta regirse por la versión actual de estos términos de servicio.

		8. Ley aplicable

		Estos términos y condiciones se rigen e interpretan de acuerdo con las leyes de Barcelona y usted se somete irrevocablemente a la jurisdicción exclusiva de los tribunales de ese estado o ubicación.`,

	privacy_title: 'Política de privacidad',
	privacy_content: `
		Su privacidad es importante para nosotros. La política de Cuintab es respetar su privacidad con respecto a cualquier información que podamos recopilar de usted en nuestro sitio web, https://cuintab.com y otros sitios que poseemos y operamos.

		Solo solicitamos información personal cuando realmente la necesitamos para brindarle un servicio. La recopilamos por medios justos y legales, con su conocimiento y consentimiento. También le informamos por qué lo recopilamos y cómo se utilizará.

		Solo retenemos la información recopilada durante el tiempo que sea necesario para brindarle el servicio solicitado. Los datos que almacenamos los protegeremos dentro de medios comercialmente aceptables para evitar pérdidas y robos, así como acceso, divulgación, copia, uso o modificación no autorizados.

		No compartimos ninguna información de identificación personal públicamente ni con terceros, excepto cuando así lo requiera la ley.

		Nuestro sitio web puede tener enlaces a sitios externos que no son operados por nosotros. Tenga en cuenta que no tenemos control sobre el contenido y las prácticas de estos sitios, y no podemos aceptar responsabilidad u obligación por sus respectivas políticas de privacidad.

		Usted es libre de rechazar nuestra solicitud de información personal, en el entendimiento de que es posible que no podamos brindarle algunos de los servicios que desea.

		Su uso continuado de nuestro sitio web se considerará como la aceptación de nuestras prácticas en materia de privacidad e información personal. Si tiene alguna pregunta sobre cómo manejamos los datos del usuario y la información personal, no dude en contactarnos.

		Esta política es efectiva a partir del 1 de junio de 2020.`,
};
