export default {
	landing_home_title: 'Inicio',
	landing_go_to_app_button: 'Entra a Cuintab',
	landing_title: 'Gestiona tu restaurant de forma simple y clara',
	landing_subtitle: 'Obtén información valuosa con una inversión de tiempo mínima, que conducirá a decisiones inteligentes y fomentadas sobre los costes de tu negocio',
	landing_subtitle_register_button: 'Crea una cuenta',
	landing_about_title: 'Qué es Cuintab?',
	landing_about_subtitle: 'Cuintab surge de dos conceptos principales - el primero es que hacer escandallos y cuatro operaciones básicas de gestión, aporta una información y transparencia en el restaurante que puede evidenciar problemas que no se veían a simple vista. Además puede mostrar oportunidades para mejorar el crecimiento de este, sea cual sea la dimensión del negocio.',
	landing_about_subtitle_2: 'El segundo, es que las alternativas a Cuintab son o el papel rudimentario, o aplicaciones de una complejidad y detalle que requieren alguien dedicado a aprender, usar, y tener la herramienta actualizada... hechos que además acaban resultando en precios poco asequibles para la mayoría de negocios. Cuintab pretende llegar a todo el mundo que tenga un restaurante, tanto a nivel permisivo, como a nivel técnico.',
	landing_about_card_1_title: 'Simple',
	landing_about_card_1_content: 'Una herramienta de apoyo debe permitir centrarte en tu trabajo primordial - el que te gusta de verdad. Cuando la gestión requiere demasiada dedicación y mantenimiento, siempre se termina dejando de lado',
	landing_about_card_2_title: 'Intuitiva',
	landing_about_card_2_content: 'Usa Cuintab directamente, sin necesidad de formaciones ni tutoriales tediosos',
	landing_about_card_3_title: 'Al alcance de todo el mundo',
	landing_about_card_3_content: 'No hace falta ser una multinacional - todo el mundo tiene derecho a disfrutar de las ventajas digitales, profesionalizarse, y poder gestionar sus recursos de una forma más cómoda y moderna',
	landing_features_title: 'Funcionalidades',
	landing_features_subtitle: 'Las justas y necesarias.',
	landing_features_subtitle_2: 'Estamos convencidos de que la diferencia entre hacer unos mínimos de gestión y no hacer nada, es abismal y determinante para el éxito de nuestro restaurante. A partir de estos mínimos, añadir detalles en la gestión seguramente aportará valor, pero será de tan poca relevancia que la complejidad o esfuerzo necesarios en estos casos no lo valdrá. Por ello Cuintab tiene un compromiso con la simplicidad y se centra en los puntos de mayor impacto.',
	landing_features_f1_title: 'Olvídate de las matemáticas',
	landing_features_f1_content: 'Cuintab calcula todas las cantidades y proporciones para ti. Los cambios de unidades también te los gestiona Cuintab: compra en kg y cocina en gramos, los escandallos harán el cambio automáticamente.',
	landing_features_f2_title: 'Consigue resultados aportando un mínimo de información',
	landing_features_f2_content: 'Crear ingredientes y escandallos es muy rápido, Cuintab sólo te pedirá la información indispensable para que no te hayas de entretener demasiado tiempo en cada ingrediente.',
	landing_features_f3_title: 'Información compleja, visualmente simple',
	landing_features_f3_content: 'Consigue ver e interpretar información de costes y precios de una forma clara, simple, y fácil, en tablas y gráficos.',
	landing_features_f4_title: 'Alérgenos y etiquetas',
	landing_features_f4_content: 'Organiza los escandallos con etiquetas y despreocupate de los alérgenos. Si en algún paso de una preparación se ha utilizado algún ingrediente con alérgenos, Cuintab arrastrará esta información y lo mostrará en la preparación final.',
	landing_features_f5_title: 'Propagación de la información y los cambios',
	landing_features_f5_content: 'Al igual que con los alérgenos, al cambiar el precio o cantidad de un ingrediente, este se aplicará automáticamente a todos los escandallos que lo estaban utilizando',
	landing_features_f6_title: 'Hazlo a tu manera',
	landing_features_f6_content: 'Cuintab te dota de herramientas y posibilidades: mermado, productos, bases, y recetas. Pero está en tus manos como organizar tu cocina. Puedes introducir los productos limpios sin merma y con el costo calculado para ti, o dejar que Cuintab te lo gestione con el campo de merma. También puedes introducir ingredientes procesados como producto, o como base mermada directamente dependiendo de cómo se trate a tu restaurante.',
	landing_pricing_title: 'Tarifes',
	landing_pricing_subtitle: 'Las cuentas de usuario de Cuintab son siempre gratuitas - las suscripciones van asociadas a los restaurantes.',
	landing_pricing_subtitle_2: 'La mejor manera de probar la plataforma gratuitamente es creando un restaurante básico, que tiene limitaciones, pero no tiene ningún coste.',
	landing_pricing_subtitle_3: 'Todas las actualizaciones y mejoras de la aplicación serán automáticas, y gratuitas!',
	landing_pricing_card_1_title: 'Restaurante básico',
	landing_pricing_card_1_subtitle: 'Gratis',
	landing_pricing_card_2_title: 'Restaurante profesional',
	landing_pricing_card_quota_subtitle: 'al mes',
	landing_pricing_card_3_title: 'Restaurante de empresa',
	landing_pricing_card_1_members: '1 miembro (tú)',
	landing_pricing_card_1_recipes: '5 recetas',
	landing_pricing_card_1_ingredients: '15 ingredientes',
	landing_pricing_card_allergens: 'Alérgenos',
	landing_pricing_card_tags: 'Etiquetas',
	landing_pricing_card_2_members: '2 miembros',
	landing_pricing_card_unlimited_recipes: 'Recetas ilimitadas',
	landing_pricing_card_unlimited_ingredients: 'Ingredientes ilimitados',
	landing_pricing_card_unlimited_members: 'Miembros ilimitados',
	landing_screenshots_title: 'Capturas de pantalla',
	landing_screenshots_subtitle: 'Puedes hacerte una idea rápida de cómo es la aplicación, pero lo mejor es crear una cuenta y un restaurante básico.',
	landing_contact_title: 'Contacto',
	landing_contact_subtitle: 'Estamos abiertos a absolutamente cualquier tipo de consulta, ya sea general, técnica, de curiosidad, para reportar un error, o para sugerir cambios, mejoras, o funcionalidades nuevas.',
	landing_contact_email_title: 'Email o teléfono (o no sabremos donde responderte)',
	landing_contact_content_title: 'Mensaje',
	landing_contact_send_button: 'Enviar mensaje',
	landing_form_contact_invalid: 'Información de contacto inválida',
	landing_form_content_short: 'Se deben escribir un mínimo de 20 caracteres',
	landing_form_error: 'Ocurrió un error, por favor contacta con contact@cuintab.com',
	landing_form_sent: 'Tu mensaje se ha enviado correctamente, recibirás una respuesta tan pronto como lo vemos :)',
	landing_terms_link: 'Términos y condiciones',
	landing_privacy_link: 'Política de privacidad',

	landing_promo_title: 'Cuintab es GRATIS por ahora!',
	landing_promo_content: 'Como consideramos que todavía tenemos que pulir algunas cosas de la aplicación, hemos decidido ofrecerla gratuitamente y sin restricciones hasta que esté del todo lista. Además creemos que es una buena oportunidad para el sector de la restauración durante el tiempo de pandemia, que desgraciadamente da más tiempo para dedicar a la revisión de los procesos internos del negocio, y donde la revisión de costes y márgenes ahora es más importante que nunca.',
	landing_promo_disclaimer: 'Antes de activar el modelo de suscripción, nos comprometemos a: avisar con antelación, mantener los datos que haya introducido, las tarifas no serán superiores a las ahora mostradas, y podrá descargar los datos que ya habíais introducido en un archivo CSV por si no te interesa la suscripción a Cuintab.'
};
