import userService from './UserService';
import Api from "./Api";


class RestaurantService {
	createRestaurant = restaurant => {
		const requestSetup = {
			endpoint: `/restaurants/`,
			method: 'POST',
			data: restaurant,
			headers: Api.getHeaders(true),
		};
		return Api.request(requestSetup)
	}

	updateRestaurant = restaurant => {
		const requestSetup = {
			endpoint: `/restaurants/${restaurant.id}/`,
			method: 'PUT',
			data: restaurant,
			headers: Api.getHeaders(true),
		};
		return Api.request(requestSetup)
	}

	deleteRestaurant = id => {
		let headers = Api.getHeaders(true)
		const options = {
			endpoint: `/restaurants/${id}/`,
			method: 'DELETE',
			headers: headers,
		};
		return Api.request(options);
	}

	updateTag = tag => {
		const requestSetup = {
			endpoint: `/restaurants/${userService.getCheckInRestaurantId()}/tags/${tag.id}/`,
			method: 'PUT',
			data: tag,
			headers: Api.getHeaders(true),
		};
		return Api.request(requestSetup)
	}

	createTag = tag => {
		const restaurantId = userService.getCheckInRestaurantId();
		tag.restaurant = restaurantId;
		const requestSetup = {
			endpoint: `/restaurants/${restaurantId}/tags/`,
			method: 'POST',
			data: tag,
			headers: Api.getHeaders(true),
		};
		return Api.request(requestSetup)
	}

	deleteTag = tag => {
		const requestSetup = {
			endpoint: `/restaurants/${userService.getCheckInRestaurantId()}/tags/${tag.id}/`,
			method: 'DELETE',
			headers: Api.getHeaders(true),
		};
		return Api.request(requestSetup)
	}

	getLog = page => {
		let params;
		if (page) {
			params = {
				page: page,
			}
		}
		const requestSetup = {
			endpoint: `/restaurants/${userService.getCheckInRestaurantId()}/log/`,
			params: params,
			method: 'GET',
			headers: Api.getHeaders(true),
		};
		return Api.request(requestSetup)
	}
}

export default new RestaurantService();
