export default {
	unit_kg: 'kg',
	unit_g: 'g',
	unit_ml: 'ml',
	unit_l: 'l',
	unit_servings: 'servings',
	unit_dozen: 'dozens',
	unit_tbsp: 'tbsp',
	unit_tsp: 'tsp',
	unit_unit: 'units',

	allergen_celery: 'Celery',
	allergen_crustaceans: 'Crustaceans',
	'allergen_E220-E228': 'Sulfites',
	allergen_eggs: 'Eggs',
	allergen_fish: 'Fish',
	allergen_gluten: 'Gluten',
	allergen_lupin: 'Lupin',
	allergen_milk: 'Milk',
	allergen_molluscs: 'Molluscs',
	allergen_mustard: 'Mustard',
	allergen_nuts: 'Nut',
	allergen_peanuts: 'Peanuts',
	allergen_sesame: 'Sesame',
	allergen_soybeans: 'Soybeans',

	application_bar_recipes_tab: 'Recipes',
	application_bar_bases_tab: 'Preps',
	application_bar_products_tab: 'Products',
	application_bar_menu_switch_restaurant: 'Switch restaurant',
	application_bar_menu_manage_restaurants: 'Manage restaurants',
	application_bar_menu_user_configuration: 'User configuration',
	application_bar_menu_logout: 'Logout',
	application_bar_switching_restaurant_title: 'Switch restaurant',

	escandall_form_creating_recipe_title: 'Creating recipe',
	escandall_form_editing_recipe_title: 'Modifying recipe',
	escandall_form_creating_prep_title: 'Creating prep',
	escandall_form_editing_prep_title: 'Modifying prep',
	escandall_form_name: 'Name',
	escandall_form_measure: 'Yield',
	escandall_form_measure_tooltip: 'The resulting amount from preparing the recipe with the listed ingredient measures. It is recommended to execute the recipe by writing down the ingredient measures first, and then weight the resulting output in the end.',
	escandall_form_units: 'Units',
	escandall_form_units_tooltip: 'This unit will determine the format of the prices of this recipe. i.e, for kg, the price would be shown as €/kg',
	escandall_form_search_ingredient_placeholder: 'Search for an ingredient...',
	escandall_form_ingredients: 'Ingredients',
	escandall_form_ingredient_name: 'Name',
	escandall_form_ingredient_notes: 'Notes',
	escandall_form_ingredient_measure: 'Amount',
	escandall_form_ingredient_units: 'Units',
	escandall_form_add_ingredient: 'Add ingredient',
	escandall_form_ingredient_selector_placeholder: 'Search for an ingredient',
	escandall_form_ingredient_selector_hint: 'Type to search...',
	escandall_form_ingredient_selector_searching: 'Searching...',
	escandall_form_ingredient_selector_no_results_found: 'No results found',
	escandall_form_procedure: 'Procedure',
	escandall_form_add_step: 'Add step',
	escandall_form_tags: 'Tags',
	escandall_form_save: 'Save',
	escandall_form_close: 'Cancel',
	escandall_form_cycle_error: 'Can\'t use this base as an ingredient of itself.',
	escandall_form_under_use_error: 'This product is currently being used by other preps, can only update units with a related alternative (for example, `kg` and `g`).',
	escandall_form_ingredient_measure_not_number: 'An invalid measure has been used in one of the ingredients',

	escandall_scene_search: 'Search',
	escandall_scene_new: 'New recipe',
	escandall_scene_measure: 'Amount',
	escandall_scene_price: 'Price',
	escandall_scene_net_cost: 'Net cost',
	escandall_scene_allergens: 'Allergens',
	escandall_scene_tags: 'Tags',
	escandall_scene_ingredients: 'Ingredients',
	escandall_scene_ingredient_name: 'Name',
	escandall_scene_ingredient_measure: 'Amount',
	escandall_scene_ingredient_net_price: 'Net price',
	escandall_scene_ingredient_cost: 'Cost',
	escandall_scene_procedure: 'Procedure',
	escandall_scene_procedure_undefined: 'No procedure has been defined',
	escandall_scene_edit: 'Edit',
	escandall_scene_delete: 'Delete',
	escandall_scene_deleting_title: 'Deleting prep',
	escandall_scene_deleting_text: 'Are you sure you want to delete the prep?',
	escandall_scene_deleting_cancel: 'Cancel',
	escandall_scene_deleting_delete: 'Delete',
	escandall_scene_no_restaurant_text: 'You have no restaurant, create one so that you can manage its recipes',
	escandall_scene_no_restaurant_button: 'Manage your restaurants',

	product_form_creating_title: 'New product',
	product_form_editing_title: 'Modifying product',
	product_form_name: 'Name',
	product_form_measure: 'Amount',
	product_form_shrinkage: 'Shrinkage',
	product_form_units: 'Units',
	product_form_units_tooltip: 'This unit will determine the format of the prices of this product. i.e, for kg, the price would be shown as €/kg',
	product_form_price: 'Price',
	product_form_allergens: 'Allergens',
	product_form_tags: 'Tags',
	product_form_save: 'Save',
	product_form_close: 'Cancel',

	product_scene_search: 'Search',
	product_scene_new: 'New product',
	product_scene_measure: 'Amount',
	product_scene_price: 'Price',
	product_scene_shrinkage: 'Shrinkage',
	product_scene_net_cost: 'Net cost',
	product_scene_gross_cost: 'Gross cost',
	product_scene_allergens: 'Allergens',
	product_scene_last_updated: 'Last updated:',
	product_scene_edit: 'Edit',
	product_scene_delete: 'Delete',
	product_scene_deleting_title: 'Deleting product',
	product_scene_deleting_text: 'Are you sure you want to delete this product?',
	product_scene_deleting_cancel: 'Cancel',
	product_scene_deleting_delete: 'Delete',
	product_scene_error_under_use: 'The product cannot be deleted because it is currently being used as an ingredient by the following preps:',
	product_scene_no_restaurant_text: 'You have no restaurant, create one so that you can manage its products',
	product_scene_no_restaurant_button: 'Manage your restaurants',

	restaurant_scene_new_restaurant: 'New restaurant',
	restaurant_scene_new_restaurant_tooltip: 'You are not a member of any restaurant, create one here',
	restaurant_scene_configuration_tab: 'General',
	restaurant_scene_members_tab: 'Members',
	restaurant_scene_log_tab: 'Log',
	restaurant_scene_subscription_tab: 'Subscription',
	restaurant_scene_delete: 'Delete restaurant',
	restaurant_scene_deleting_title: 'Deleting restaurant',
	restaurant_scene_deleting_text: 'Are you sure you want to delete the restaurant',
	restaurant_scene_deleting_cancel: 'Cancel',
	restaurant_scene_deleting_delete: 'Delete',

	restaurant_form_title: 'Creating a new restaurant',
	restaurant_form_name: 'Restaurant name',
	restaurant_form_name_placeholder: 'Restaurant name',
	restaurant_form_save: 'Create',
	restaurant_form_close: 'Cancel',

	restaurant_configuration_basic_section_title: 'Basic configuration',
	restaurant_configuration_name_title: 'Restaurant name',
	restaurant_configuration_name_placeholder: 'Restaurant name',
	restaurant_configuration_basic_config_save_button: 'Save',
	restaurant_configuration_tags_section_title: 'Tags',
	restaurant_configuration_tag_placeholder: 'Tag text',
	restaurant_configuration_tag_save_button: 'Save',
	restaurant_configuration_tag_cancel_button: 'Cancel',
	restaurant_configuration_create_tag_button: 'New tag',
	restaurant_configuration_tag_delete_title: 'Deleting a tag',
	restaurant_configuration_tag_delete_subtitle: 'Are you sure you want to delete this tag?',
	restaurant_configuration_tag_delete_cancel: 'Cancel',
	restaurant_configuration_tag_delete_action: 'Delete',

	user_configuration_title: 'User configuration',
	user_configuration_change_basic_info: 'Update basic information',
	user_configuration_change_name: 'User name',
	user_configuration_first_name_placeholder: 'Name',
	user_configuration_change_language: 'Language',
	user_configuration_change_basic_info_save: 'Save',
	user_configuration_change_password_title: 'Update password',
	user_configuration_change_password_password_title: 'New password',
	user_configuration_change_password_password_placeholder: 'New password',
	user_configuration_password_info: 'Must be at least 8 characters long and can\'t  be just numbers',
	user_configuration_change_password_repeat_password_title: 'Repeat new password',
	user_configuration_change_password_repeat_password_placeholder: 'New password',
	user_configuration_change_password_save: 'Save',
	user_configuration_error_common_password: 'The password is too common',
	user_configuration_error_unknown_password_error: 'The password is not safe enough',

	user_login_login_title: 'Login',
	user_login_email_title: 'Email address',
	user_login_email_placeholder: 'Email',
	user_login_password_title: 'Password',
	user_login_password_placeholder: 'Password',
	user_login_login_action: 'Login',
	user_login_forgot_password_button: 'Forgot your password?',
	user_login_go_to_register: 'Create an account',
	user_login_register_header: 'Don\'t have a Cuintab account yet? Create a free one here!',
	user_login_feedback_from_email_confirmation: 'Your email has been verified, you can log in to your account now!',
	user_login_feedback_from_password_reset: 'Your new password was saved, you can use it to log in to your account now!',
	user_login_email_not_verified: 'This email hasn\'t been verified yet',

	user_password_reset_title: 'Password reset',
	user_password_reset_email_title: 'Email address',
	user_password_reset_email_placeholder: 'Email',
	user_password_reset_reset_action: 'Send',
	user_password_reset_back_to_login_button: 'Back to login',
	user_password_reset_email_sent_feedback: 'If an account existed for the provided address, an email has been sent there with a password reset link',
	user_password_reset_complete_goto_login: 'Back to login',

	user_registration_title: 'New account',
	user_registration_registration_complete_feedback: 'You have been successfully registered!\n\nBefore being able to log in, you must confirm your email, please review your inbox.',
	user_registration_registration_complete_goto_login: 'Go to login',
	user_registration_name_title: 'Name',
	user_registration_name_placeholder: 'Name',
	user_registration_email_title: 'Email address',
	user_registration_email_placeholder: 'Email',
	user_registration_password_title: 'Password',
	user_registration_password_placeholder: 'Password',
	user_registration_password_info: 'Must be at least 8 characters long and can\'t  be just numbers',
	user_registration_repeat_password_title: 'Repeat password',
	user_registration_repeat_password_placeholder: 'Password',
	user_registration_registration_action: 'Register',
	user_registration_already_registered_button: 'Already have an account? Go to login',
	user_registration_error_common_password: 'The password is too common',
	user_registration_error_unknown_password_error: 'The password is not safe enough',
	user_registration_acknowledge_legal: 'By creating an account you are agreeing to:',
	user_registration_terms_link: 'Terms and conditions',
	user_registration_privacy_link: 'Privacy policy',

	user_password_reset_form_title: 'New password',
	user_password_reset_form_password_title: 'Password',
	user_password_reset_form_password_info: 'Must be at least 8 characters long and can\'t  be just numbers',
	user_password_reset_form_password_placeholder: 'password',
	user_password_reset_form_password_repeat_title: 'Repeat password',
	user_password_reset_form_password_repeat_placeholder: 'password',
	user_password_reset_form_save_password: 'Save new password',

	form_password_length: 'Must be at least 8 characters long',
	form_format_email: 'Not an email address',
	form_required_field: 'Required field',
	form_passwords_dont_match: 'Passwords don\'t match',
	form_numeric_password: 'Can\'t be just numbers',
};
