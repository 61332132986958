import Api from "./Api";
import userService from './UserService';


class AuthenticationService {
	constructor() {
		this.sessionTokenKey = 'session_token';
	}

	login = (email, password) => {
		const headers = new Headers();
		headers.append('Content-Type', 'application/json');

		const requestSetup = {
			endpoint: '/auth/login/',
			method: 'POST',
			data: { email, password },
			headers
		};
		return Api.request(requestSetup)
			.then(result => {
				this.setToken(JSON.parse(result).key);
			});
	}

	setToken = token => {
		localStorage.setItem(this.sessionTokenKey, token);
	}

	getToken = () => localStorage.getItem(this.sessionTokenKey);

	reset = () => {
		localStorage.removeItem(this.sessionTokenKey);
		userService.clearUserInfo();
	}

	isAuthenticated = () => this.getToken() !== null && this.getToken() !== undefined;

	register = (firstName, password1, password2, email) => {
		const headers = new Headers();
		headers.append('Content-Type', 'application/json');

		const requestSetup = {
			endpoint: '/auth/registration/',
			method: 'POST',
			data: { first_name: firstName, password1, password2, email },
			headers
		};
		return Api.request(requestSetup);
	}

	passwordReset = email => {
		const headers = new Headers();
		headers.append('Content-Type', 'application/json');

		const requestSetup = {
			endpoint: '/auth/password/reset/',
			method: 'POST',
			data: { email },
			headers
		};
		return Api.request(requestSetup);
	}

	passwordResetUpdatePassword = (uid, token, password1, password2) => {
		const headers = new Headers();
		headers.append('Content-Type', 'application/json');

		const requestSetup = {
			endpoint: '/auth/password/reset/confirm/',
			method: 'POST',
			data: { uid, token, new_password1: password1, new_password2: password2 },
			headers
		};
		return Api.request(requestSetup);
	}
}

export default new AuthenticationService();
