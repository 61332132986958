export default {
	landing_home_title: 'Home',
	landing_go_to_app_button: 'Go to Cuintab',
	landing_title: 'Manage your restaurant in a simple and clear way',
	landing_subtitle: 'Get valuable information with minimum dedication, that will allow intelligent and founded decision-making regarding your business\' costs',
	landing_subtitle_register_button: 'Create an account',
	landing_about_title: 'What is Cuintab?',
	landing_about_subtitle: 'Cuintab is born from two main concepts - the first being that creating technical sheets or recipes and few very basic management operations, adds a piece of information and transparency to the restaurant that can bring afloat issues that were not detected at first glance. Moreover, growth opportunities could be evidenced regardless of the size of the business',
	landing_about_subtitle_2: 'The second, is that alternatives to Cuintab are either cumbersome paper support, or applications of a detail and complexity that require someone dedicated to learning, maintaining, and keeping the tool updated... which end up in unaffordable prices or situations for the majority of real world restaurant businesses. Cuintab pretends to reach anyone who owns a restaurant, both in affordability, and in technical levels',
	landing_about_card_1_title: 'Simple',
	landing_about_card_1_content: 'A tool must allow you to focus on your main job - the one you love. When management requires too much dedication and maintenance, it always ends up being put aside',
	landing_about_card_2_title: 'Intuitive',
	landing_about_card_2_content: 'Use Cuintab straight away, without any need of trainings or annoying and lengthy tutorials',
	landing_about_card_3_title: 'For anyone',
	landing_about_card_3_content: 'You don\'t need to be a multinational - everyone has a right to the digital transformation, to be professional, and to be able to manage their resources in a more comfortable and modern way',
	landing_features_title: 'Features',
	landing_features_subtitle: 'Just the ones you need.',
	landing_features_subtitle_2: 'We are convinced that the difference between minimum "paperwork" and doing nothing is huge and decisive for our restaurant\'s success. From those minimums on, adding details to the administration will probably add value, but it will be so irrelevant that the added complexity or effort won\'t be worth it. Hence why Cuintab is committed with simplicity and focuses on the most impactful matters.',
	landing_features_f1_title: 'Forget about maths',
	landing_features_f1_content: 'Cuintab computes all the measures and proportions for you. Unit conversion is also managed by Cuintab: buy in kg and cook in grams, the recipes will automatically manage the conversion.',
	landing_features_f2_title: 'Get results from minimal input',
	landing_features_f2_content: 'Promptly create ingredients and recipes, Cuintab will require you for only essential data so that you don\'t have to spend too long in each ingredient',
	landing_features_f3_title: 'Complex data, easy to the eyes',
	landing_features_f3_content: 'Achieve display and interpretation of price and cost data in a simple, clear manner in graphs and tables.',
	landing_features_f4_title: 'Allergens and tags',
	landing_features_f4_content: 'Organize your recipes with tags and forget about allergen management. If a recipe used an allergen in any of its previous preparations, Cuintab will carry and display the allergen information up to the final recipe data',
	landing_features_f5_title: 'Automatic spread of data updates',
	landing_features_f5_content: 'Just like with allergens, price or measure updates of an ingredient, automatically will spread to recipes that are making use of it',
	landing_features_f6_title: 'Use Cuintab to your liking',
	landing_features_f6_content: 'Cuintab endows you with tools and possibilities: shrinkage, products, bases, and recipes. However, it\'s up to you how to orgainze your kitchen. You can input processed products disregarding shrinkage and with the appropiate price, or you can let Cuintab manage it automatically with the shrinkage field. You can as well input processed ingredients as a product, or as an shrinked preparation directly, depending on the role of this ingredient in your restaurant.',
	landing_pricing_title: 'Pricing',
	landing_pricing_subtitle: 'Cuintab accounts are always free - subscriptions are applied in a restaurant basis',
	landing_pricing_subtitle_2: 'The best way to freely explore Cuintab is by creating a basic restaurant, it has limitations but comes at no cost.',
	landing_pricing_subtitle_3: 'All the updates and improvements of the aplication are automatic and free!',
	landing_pricing_card_1_title: 'Basic restaurant',
	landing_pricing_card_1_subtitle: 'Free',
	landing_pricing_card_2_title: 'Professional restaurant',
	landing_pricing_card_quota_subtitle: 'monthly',
	landing_pricing_card_3_title: 'Enterprise restaurant',
	landing_pricing_card_1_members: '1 member (you)',
	landing_pricing_card_1_recipes: '5 recipes',
	landing_pricing_card_1_ingredients: '15 ingredients',
	landing_pricing_card_allergens: 'Allergens',
	landing_pricing_card_tags: 'Tags',
	landing_pricing_card_2_members: '2 members',
	landing_pricing_card_unlimited_recipes: 'Unlimited recipes',
	landing_pricing_card_unlimited_ingredients: 'Unlimited ingredients',
	landing_pricing_card_unlimited_members: 'Unlimited members',
	landing_screenshots_title: 'Screenshots',
	landing_screenshots_subtitle: 'You can get an idea of what the application looks like, but it would be best to create an account and test it hands on with a basic restaurant.',
	landing_contact_title: 'Contact',
	landing_contact_subtitle: 'We are fully open to any kind of inquiry, be it of general interest, technical, a curiosity, to report an error, or to suggest changes, improvements or new features.',
	landing_contact_email_title: 'Email or phone number (or we won\'t have a way to get back to you)',
	landing_contact_content_title: 'Message',
	landing_contact_send_button: 'Send message',
	landing_form_contact_invalid: 'Invalid contact information',
	landing_form_content_short: 'Your message must be at least 20 characters long',
	landing_form_error: 'An error occurred, please contact contact@cuintab.com',
	landing_form_sent: 'Your message was successfully sent, we will get back to you as soon as we see it :)',
	landing_terms_link: 'Terms and conditions',
	landing_privacy_link: 'Privacy policy',

	landing_promo_title: 'Cuintab is fully FREE for now!',
	landing_promo_content: 'Since we consider that the application still needs some polishing, we decided to open it freely and unrestricted until it is ready. Moreover, we believe it is a good opportunity for the food industry under the pandemic situation. Since, sadly, it allows for a chance to put time into reviewing business processes, and where cost and margin analysis is now more important than ever before.',
	landing_promo_disclaimer: 'Prior to activating the subscription model, we commit ourselves to: giving you a heads up beforehand, keeping the data you have introduced, pricing won\'t be higiher than currently announced, and you will be able to download introduced data in a CSV file in case you are not interested in a Cuintab subscription.'
};
