import React from 'react';
import authenticationService from "../services/AuthenticationService";
import userService from "../services/UserService";
import SubmissionContainer from '../components/SubmissionContainer';
import { Form, Button, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import * as yup from 'yup';

import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';


/*
 * Embeds the landing with react - simplifies deploys and takes advantage of routing, state, and translations.
 * CSS files can be imported from within src scope of landing, however, old static JS imports have to be put in the `public` directory of
 * the project, this is why those dependencies may look/be duplicate and separated.
*/
class Landing extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			formSubmitting: false,
			formSubmitted: false,
			error: null,
		}

		this.scripts = [
			'https://ajax.googleapis.com/ajax/libs/jquery/1.11.3/jquery.min.js',
			'https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.3/umd/popper.min.js',
			'bootstrap-4.1.1-dist/js/bootstrap.min.js',
			'https://cdn.jsdelivr.net/npm/vivid-icons',
			'https://unpkg.com/vivid-icons',
			'js/plugins/owl-carousel/owl.carousel.js',
			'js/plugins/wow/wow.min.js',
			'js/plugins/jquery.easing.min.js',
			'js/main.js'
		];
	}

	componentDidMount() {
		this.scripts.forEach(scriptPath => {
			const script = document.createElement("script");
			script.src = scriptPath;
			script.async = false;	// at least for jquery
			document.body.appendChild(script);

			// apparently there is no need to unset those in unmount
			document.body.setAttribute('data-spy', 'scroll');
			document.body.setAttribute('data-target', '.navbar');
		})
	}

	componentWillUnmount() {
		this.scripts.forEach(scriptToremove => {
			let allsuspects=document.getElementsByTagName("script");
			for (let i=allsuspects.length; i>=0; i--)
				if (allsuspects[i] && allsuspects[i].getAttribute("src") !== null  && allsuspects[i].getAttribute("src").indexOf(`${scriptToremove}`) !== -1 )
					allsuspects[i].parentNode.removeChild(allsuspects[i]);
		});
	}

	handleSubmit = values => {
		this.setState({
			formSubmitting: true,
			error: null,
		});

		userService.contact(values.email, values.content)
			.then(() => {
				this.setState({
					formSubmitting: false,
					formSubmitted: false,
					error: false
				});
			}).catch(result => {
				this.setState({
					formSubmitting: false,
					error: true
				});
			});
	}

	render() {
		// better to autocontain style files or they will carry on to other parts of the project
		// require('./bootstrap-4.1.1-dist/css/bootstrap.min.css');
		require('./js/plugins/owl-carousel/owl.carousel.css');
		require('./js/plugins/owl-carousel/owl.theme.css');
		require('./js/plugins/owl-carousel/owl.transitions.css');
		require('./css/animate.css');
		require('./css/style.css');

		const featureTextStyle = { display: 'flex', 'flexDirection': 'column', 'justifyContent': 'center' };

		const formSchema = yup.object({
			email: yup.string().min(8, 'landing_form_contact_invalid').required('form_required_field'),
			content: yup.string().min(20, 'landing_form_content_short').required('form_required_field')
		});

		return (
			<div className='body'>
				<header id="home">

					{/* /// Navbar /// */}

					<nav className="navbar navbar-expand-lg fixed-top">
						<div className="container">
							{/* // Brand // */}

							<a className="navbar-brand" href='#home'>C<span>uintab</span></a>
							<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"><FontAwesomeIcon icon="bars" size='lg' color='white'/></button>
							<div className="collapse navbar-collapse" id="navbarNav">
								{/* / NavLinks / */}

								<ul className="nav navbar-nav ml-auto">
									<li className="nav-item">
										<a className="nav-link page-scroll" href="#home">{this.props.t('landing_home_title')}</a>
									</li>
									<li className="nav-item">
										<a className="nav-link page-scroll" href="#prices">{this.props.t('landing_pricing_title')}</a>
									</li>
									<li className="nav-item">
										<a className="nav-link page-scroll" href="#features">{this.props.t('landing_features_title')}</a>
									</li>
									<li className="nav-item">
										<a className="nav-link page-scroll" href="#contact">{this.props.t('landing_contact_title')}</a>
									</li>
									<li>
										<a href='/recipes'><button className="btn btn-primary-landing">{this.props.t('landing_go_to_app_button')}</button></a>
									</li>
								</ul>

							</div>
						</div>
					</nav>

					{/* /// BANNER /// */}
					<div className="banner">
						<div className="container">
							<div className="row">
								<div className="col-md-8 offset-md-2">
									{/* // Caption // */}
									<div className="caption">
										<h1 className='h1'>{this.props.t('landing_title')}</h1>
										<p className="sub">{this.props.t('landing_subtitle')}</p>
										{ authenticationService.isAuthenticated() ?
											<a className="btn btn-primary-landing" href='/recipes'>{this.props.t('landing_go_to_app_button')}</a>
										:
											<a className="btn btn-primary-landing" href='/register'>{this.props.t('landing_subtitle_register_button')}</a>
										}
										{/* / Macbook IMG / */}
										<img className="img-fluid mx-auto wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s" src={require("./imgs/macbook.png")} alt="macbook"/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>


				{/* ==============================================
				ABOUT 
				=============================================== */}

				<section id="about">
					<div className="container">
						<div className="row">
							<div className="col-md-12 text-center title-container">
								{/* /// Title /// */}
								<h2 className="h2 section-title">{this.props.t('landing_about_title')}</h2>
								<p>{this.props.t('landing_about_subtitle')}</p>
								<p>{this.props.t('landing_about_subtitle_2')}</p>
							</div>
						</div>
						<div className="row" style={{ marginTop: '20px' }}>
							<div className="col-md-4 wow bounceIn" data-wow-duration=".5s" data-wow-delay=".2s">
								{/* /// Icon /// */}
								<FontAwesomeIcon icon="smile" size='3x' color='var(--accent)' style={{ marginBottom: '20px' }}/>
								{/* // Title // */}
								<h4 className='h4'>{this.props.t('landing_about_card_1_title')}</h4>
								{/* / Caption / */}
								<p>{this.props.t('landing_about_card_1_content')}</p>
							</div>
							<div className="col-md-4 wow bounceIn" data-wow-duration=".5s" data-wow-delay=".4s">
								{/* /// Icon /// */}
								<FontAwesomeIcon icon="hand-sparkles" size='3x' color='var(--accent)' style={{ marginBottom: '20px' }}/>
								{/* // Title // */}
								<h4 className='h4'>{this.props.t('landing_about_card_2_title')}</h4>
								{/* / Caption / */}
								<p>{this.props.t('landing_about_card_2_content')}</p>
							</div>
							<div className="col-md-4 wow bounceIn" data-wow-duration=".5s" data-wow-delay=".6s">
								{/* /// Icon /// */}
								<FontAwesomeIcon icon="users" size='3x' color='var(--accent)' style={{ marginBottom: '20px' }}/>
								{/* // Title // */}
								<h4 className='h4'>{this.props.t('landing_about_card_3_title')}</h4>
								{/* / Caption / */}
								<p>{this.props.t('landing_about_card_3_content')}</p>
							</div>
						</div>
					</div>
				</section>


				{/* ==============================================
				PRICING 
				=============================================== */}

				<section id="prices">
					<div className="container">
						<div className="row">
							{/* /// Title /// */}
							<div className="col-md-12 text-center title-container">
								<h2 className="h2 section-title">{this.props.t('landing_pricing_title')}</h2>
								<p>{this.props.t('landing_pricing_subtitle')}</p>
								<p>{this.props.t('landing_pricing_subtitle_2')}</p>
								<p>{this.props.t('landing_pricing_subtitle_3')}</p>
							</div>
						</div>
						<Alert key={'free-pricing-alert'} variant='success'>
							<h2>{this.props.t('landing_promo_title')}</h2>
							<p>{this.props.t('landing_promo_content')}</p>
							<p>{this.props.t('landing_promo_disclaimer')}</p>
						</Alert>
						{/* /// Pricing Tables /// */}
						<div className="row">
							{/*// First Table // */}
							<div className="col-lg-4 wow fadeInLeft" data-wow-duration=".5s" data-wow-delay=".2s">
								<div className="price-table">
									<div className="header">
										<h5 className="title">{this.props.t('landing_pricing_card_1_title')}</h5>
										<div className="price">0€</div>
										<h4 className='h4'>{this.props.t('landing_pricing_card_1_subtitle')}</h4>
									</div>
									<ul>
										{/*<li>{this.props.t('landing_pricing_card_1_members')}</li>*/}
										<li>{this.props.t('landing_pricing_card_1_recipes')}</li>
										<li>{this.props.t('landing_pricing_card_1_ingredients')}</li>
										<li>{this.props.t('landing_pricing_card_allergens')}</li>
										<li>{this.props.t('landing_pricing_card_tags')}</li>
									</ul>
								</div>
							</div>
							{/*// First Table // */}
							<div className="col-lg-4 wow bounceIn" data-wow-duration=".5s" data-wow-delay=".4s">
								<div className="price-table">
									<div className="header">
										<h5 className="title">{this.props.t('landing_pricing_card_2_title')}</h5>
										<div className="price">26€</div>
										<h4 className='h4'>{this.props.t('landing_pricing_card_quota_subtitle')}</h4>
									</div>
									<ul>
										{/*<li>{this.props.t('landing_pricing_card_2_members')}</li>*/}
										<li>{this.props.t('landing_pricing_card_unlimited_recipes')}</li>
										<li>{this.props.t('landing_pricing_card_unlimited_ingredients')}</li>
										<li>{this.props.t('landing_pricing_card_allergens')}</li>
										<li>{this.props.t('landing_pricing_card_tags')}</li>
									</ul>
								</div>
							</div>
							{/*// First Table // */}
							<div className="col-lg-4 wow fadeInRight" data-wow-duration=".5s" data-wow-delay=".2s">
								<div className="price-table">
									<div className="header">
										<h5 className="title">{this.props.t('landing_pricing_card_3_title')}</h5>
										<div className="price">45€</div>
										<h4 className='h4'>{this.props.t('landing_pricing_card_quota_subtitle')}</h4>
									</div>
									<ul>
										{/*<li>{this.props.t('landing_pricing_card_unlimited_members')}</li>*/}
										<li>{this.props.t('landing_pricing_card_unlimited_recipes')}</li>
										<li>{this.props.t('landing_pricing_card_unlimited_ingredients')}</li>
										<li>{this.props.t('landing_pricing_card_allergens')}</li>
										<li>{this.props.t('landing_pricing_card_tags')}</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</section>


				{/* ==============================================
				APP SCREENSHOTS 
				=============================================== 

				<section id="screenshots">
					<div className="container">
						<div className="row">
							<div className="col-md-6 col-sm-offset-2 col-md-offset-3 text-center title-container">
								{/* /// Title /// 
								<h2 className="h2 section-title">{this.props.t('landing_screenshots_title')}</h2>
								<p>{this.props.t('landing_screenshots_subtitle')}</p>
							</div>
						</div>
						<div className="row">
							<div id="owl-screenshots">
								<div className="item"><img src={require("./imgs/screenshots/1.png")} className="img-fluid" alt="screen-1"/></div>
								<div className="item"><img src={require("./imgs/screenshots/2.png")} className="img-fluid" alt="screen-2"/></div>
								<div className="item"><img src={require("./imgs/screenshots/3.png")} className="img-fluid" alt="screen-3"/></div>
								<div className="item"><img src={require("./imgs/screenshots/1.png")} className="img-fluid" alt="screen-1"/></div>
								<div className="item"><img src={require("./imgs/screenshots/2.png")} className="img-fluid" alt="screen-2"/></div>
								<div className="item"><img src={require("./imgs/screenshots/3.png")} className="img-fluid" alt="screen-3"/></div>
								<div className="item"><img src={require("./imgs/screenshots/1.png")} className="img-fluid" alt="screen-1"/></div>
								<div className="item"><img src={require("./imgs/screenshots/2.png")} className="img-fluid" alt="screen-2"/></div>
							</div>
						</div>
					</div>
				</section>
				*/}

				{/* ==============================================
				FEATURES (old SERVICES)
				=============================================== */}

				<section id="features">
					<div className="container">
						<div className="row">
							<div className="col-md-12 text-center title-container">
								{/* /// Title /// */}
								<h2 className="h2 section-title">{this.props.t('landing_features_title')}</h2>
								<p>{this.props.t('landing_features_subtitle')}</p>
								<p>{this.props.t('landing_features_subtitle_2')}</p>
							</div>
						</div>
						<div className="row">
							<div className="col-md-5 wow fadeInLeft" data-wow-duration=".5s" data-wow-delay=".2s" style={featureTextStyle}>
								<h3>{this.props.t('landing_features_f1_title')}</h3>
								<p>{this.props.t('landing_features_f1_content')}</p>
							</div>
							<div className="col-md-7 wow fadeInRight" data-wow-duration=".5s" data-wow-delay=".5s" style={{ margin: 'auto '}}>
								<div className="img-table">
									<img src={require("./imgs/features/units.png")} className="img-fluid" alt="table"/>
								</div>
							</div>
						</div>
						<div className="row seconde mt-5">
							<div className="col-md-7 wow fadeInLeft" data-wow-duration=".5s" data-wow-delay=".2s" style={{ margin: 'auto '}}>
								<img src={require("./imgs/features/prod_form.png")} className="img-fluid" alt="update"/>
							</div>
							<div className="col-md-5 wow fadeInRight" data-wow-duration=".5s" data-wow-delay=".5s" style={featureTextStyle}>
								<h3>{this.props.t('landing_features_f2_title')}</h3>
								<p>{this.props.t('landing_features_f2_content')}</p>
							</div>
						</div>
						<div className="row mt-5">
							<div className="col-md-5 wow fadeInLeft" data-wow-duration=".5s" data-wow-delay=".2s" style={featureTextStyle}>
								<h3>{this.props.t('landing_features_f3_title')}</h3>
								<p>{this.props.t('landing_features_f3_content')}</p>
							</div>
							<div className="col-md-7 wow fadeInRight" data-wow-duration=".5s" data-wow-delay=".5s" style={{ margin: 'auto '}}>
								<div className="img-table">
									<img src={require("./imgs/features/taula_preus.png")} className="img-fluid" alt="table"/>
								</div>
							</div>
						</div>
						<div className="row seconde mt-5">
							<div className="col-md-7 wow fadeInLeft" data-wow-duration=".5s" data-wow-delay=".2s" style={{ margin: 'auto '}}>
								<img src={require("./imgs/features/allergens_tags.png")} className="img-fluid" alt="update"/>
							</div>
							<div className="col-md-5 wow fadeInRight" data-wow-duration=".5s" data-wow-delay=".5s" style={featureTextStyle}>
								<h3>{this.props.t('landing_features_f4_title')}</h3>
								<p>{this.props.t('landing_features_f4_content')}</p>
							</div>
						</div>
						<div className="row mt-5">
							<div className="col-md-5 wow fadeInLeft" data-wow-duration=".5s" data-wow-delay=".2s" style={featureTextStyle}>
								<h3>{this.props.t('landing_features_f5_title')}</h3>
								<p>{this.props.t('landing_features_f5_content')}</p>
							</div>
							<div className="col-md-7 wow fadeInRight" data-wow-duration=".5s" data-wow-delay=".5s" style={{ margin: 'auto '}}>
								<div className="img-table">
									<img src={require("./imgs/features/add_ingredient.png")} className="img-fluid" alt="table"/>
								</div>
							</div>
						</div>
						<div className="row seconde mt-5">
							<div className="col-md-7 wow fadeInLeft" data-wow-duration=".5s" data-wow-delay=".2s" style={{ margin: 'auto '}}>
								<img src={require("./imgs/features/shrinkage_management.png")} className="img-fluid" alt="update"/>
							</div>
							<div className="col-md-5 wow fadeInRight" data-wow-duration=".5s" data-wow-delay=".5s" style={featureTextStyle}>
								<h3>{this.props.t('landing_features_f6_title')}</h3>
								<p>{this.props.t('landing_features_f6_content')}</p>
							</div>
						</div>
					</div>
				</section>


				{/* ==============================================
				FOOTER
				=============================================== */}

				<section id="contact">
					<div className="container">
						<div className="row">
							<div className="col-xs-12 col-sm-8 col-md-10 col-sm-offset-2 col-md-offset-3 text-center title-container">
								{/* /// Title /// */}
								<h2 className="h2 section-title">{this.props.t('landing_contact_title')}</h2>
								<p>{this.props.t('landing_contact_subtitle')}</p>
							</div>
						</div>

						{/* /// CONTACT FORM /// */}

						<Formik validationSchema={formSchema} initialValues={{ email: '', content: '' }}>
							{({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
								<div>
									<div className="form-row">
										<div className="form-group col-xs-12 col-sm-6">
											<Form.Group controlId="contact-form">
												<Form.Label>{this.props.t('landing_contact_email_title')}</Form.Label>
												<Form.Control
													name="email"
													value={values.email}
													onChange={handleChange}
													isInvalid={errors.email}
													onBlur={handleBlur}
												/>
												<Form.Control.Feedback type="invalid">
													{this.props.t(errors.email)}
												</Form.Control.Feedback>
											</Form.Group>
										</div>
									</div>
									<div className="form-group">
										<Form.Group controlId="formBasicPassword">
											<Form.Label>{this.props.t('landing_contact_content_title')}</Form.Label>
											<Form.Control
												name="content"
												as='textarea'
												cols='30'
												rows='5'
												value={values.content}
												onChange={handleChange}
												isInvalid={errors.content}
												onBlur={handleBlur}
											/>
											<Form.Control.Feedback type="invalid">
												{this.props.t(errors.content)}
											</Form.Control.Feedback>
										</Form.Group>
									</div>

									<SubmissionContainer loading={this.state.formSubmitting} style={{ justifyContent: 'left', display: this.state.formSubmitted ? 'none' : 'flex' }}>
										<Button variant="primary" type="submit" disabled={!isValid} onClick={isValid ? () => this.handleSubmit(values) : undefined}>
											{this.props.t('landing_contact_send_button')}
										</Button>
									</SubmissionContainer>

									<p style={{ visibility: this.state.error ? 'visible' : 'hidden', color: '#dc3545', fontWeight: '600' }}>
											{this.props.t('landing_form_error')}
										</p>
									<p style={{ visibility: this.state.formSubmitted ? 'visible' : 'hidden', color: '#64dd17', fontWeight: '600' }}>
										{this.props.t('landing_form_sent')}
									</p>
								</div>
							)}
						</Formik>

						<p>
							<a href={'/terms'} style={{ color: 'var(--accent)' }}>{this.props.t('landing_terms_link')}</a>
							{` | `}
							<a href={'/privacy'} style={{ color: 'var(--accent)' }}>{this.props.t('landing_privacy_link')}</a>
						</p>
						<p>Copyright &copy; 2020, Roger Hernandez</p>
					</div>
				</section>
			</div>
		);
	}
};

export default withTranslation()(withRouter(Landing));
