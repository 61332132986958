import userService from './UserService';
import Api from "./Api";

import cloneDeep from 'lodash/cloneDeep';


class ProductService {
	SystemUnits = () => [
		'kg',
		'g',
		'ml',
		'l',
		'servings',
		'dozen',
		'tbsp',
		'tsp',
		'unit'
	];

	deepCopyProduct = product => {
		return cloneDeep(product);
	}

	getProducts = params => {
		let headers = Api.getHeaders(true)

		const options = {
			endpoint: `/restaurants/${userService.getCheckInRestaurantId()}/products/`,
			method: 'GET',
			params: params,
			headers: headers,
		};
		return Api.request(options);
	}

	getProduct = id => {
		let headers = Api.getHeaders(true)

		const options = {
			endpoint: `/restaurants/${userService.getCheckInRestaurantId()}/products/${id}/`,
			method: 'GET',
			headers: headers,
		};
		return Api.request(options);
	}

	createProduct = product => {
		let headers = Api.getHeaders(true)

		const restaurantId = userService.getCheckInRestaurantId();
		product.restaurant = restaurantId;
		const options = {
			endpoint: `/restaurants/${restaurantId}/products/`,
			method: 'POST',
			headers: headers,
			data: product,
		};
		return Api.request(options);
	}

	saveProduct = product => {
		let headers = Api.getHeaders(true)

		const options = {
			endpoint: `/restaurants/${userService.getCheckInRestaurantId()}/products/${product.id}/`,
			method: 'PUT',
			headers: headers,
			data: product,
		};
		return Api.request(options);
	}

	createEscandall = escandall => {
		let headers = Api.getHeaders(true)

		const restaurantId = userService.getCheckInRestaurantId();
		escandall.restaurant = restaurantId;
		const options = {
			endpoint: `/restaurants/${restaurantId}/escandalls/`,
			method: 'POST',
			headers: headers,
			data: escandall,
		};
		return Api.request(options);
	}

	saveEscandall = escandall => {
		let headers = Api.getHeaders(true)

		const options = {
			endpoint: `/restaurants/${userService.getCheckInRestaurantId()}/escandalls/${escandall.id}/`,
			method: 'PUT',
			headers: headers,
			data: escandall,
		};
		return Api.request(options);
	}

	deleteProduct = id => {
		let headers = Api.getHeaders(true)
		const options = {
			endpoint: `/restaurants/${userService.getCheckInRestaurantId()}/products/${id}/`,
			method: 'DELETE',
			headers: headers,
		};
		return Api.request(options);
	}

	getIngredients = params => {
		let headers = Api.getHeaders(true)

		const options = {
			endpoint: `/restaurants/${userService.getCheckInRestaurantId()}/ingredients/`,
			method: 'GET',
			params: params,
			headers: headers,
		};
		return Api.request(options);
	}
}

export default new ProductService();
