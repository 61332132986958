import celeryIcon from '../assets/images/allergens/celery.svg';
import crustaceansIcon from '../assets/images/allergens/crustaceans.svg';
import eggsIcon from '../assets/images/allergens/eggs.svg';
import fishIcon from '../assets/images/allergens/fish.svg';
import glutenIcon from '../assets/images/allergens/gluten.svg';
import lupinIcon from '../assets/images/allergens/lupin.svg';
import milkIcon from '../assets/images/allergens/milk.svg';
import molluscsIcon from '../assets/images/allergens/molluscs.svg';
import mustardIcon from '../assets/images/allergens/mustard.svg';
import nutsIcon from '../assets/images/allergens/nuts.svg';
import peanutsIcon from '../assets/images/allergens/peanuts.svg';
import sesameIcon from '../assets/images/allergens/sesame.svg';
import soybeansIcon from '../assets/images/allergens/soybeans.svg';
import sulfidesIcon from '../assets/images/allergens/sulfides.svg';


class AllergenService {
	constructor() {
		this.allergens = {
			celery: celeryIcon,
			crustaceans: crustaceansIcon,
			'E220-E228': sulfidesIcon,
			eggs: eggsIcon,
			fish: fishIcon,
			gluten: glutenIcon,
			lupin: lupinIcon,
			milk: milkIcon,
			molluscs: molluscsIcon,
			mustard: mustardIcon,
			nuts: nutsIcon,
			peanuts: peanutsIcon,
			sesame: sesameIcon,
			soybeans: soybeansIcon,
		}
	}

	getAllergens() {
		return this.allergens;
	}

	getAllergen(allergen) {
		return this.allergens[allergen];
	}
}

export default new AllergenService();
