import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import { Button, Form } from 'react-bootstrap'
import IngredientSelector from './IngredientSelector'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';

import React from 'react';


const DragHandle = SortableHandle(() => <span><FontAwesomeIcon icon="grip-lines"/></span>);

const getNameField = (name, onIngredientSelectionAction) => {
	if (!name)
		return <IngredientSelector onIngredientSelection={onIngredientSelectionAction}/>;
	return <p>{name}</p>;
}

/**
 *  This class represents the ingredient row form that appears when editing the ingredients of an escandall
 */
class EscandallIngredientForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = Object.keys(this.props.ingredient).length ? this.props.ingredient: { available_units: [] };
	}

	onIngredientSelection = ingredient => {
		ingredient.ingredient = ingredient.id;
		delete ingredient['id'];
		// delete ingredient price units, or it will conflict with user-chosen units for the recipe, the original were not needed for
		// anything anyway
		delete ingredient['units'];
		this.props.handleIngredientUpdated(ingredient, this.props.rowIndex, true);
	}

	handleNoteUpdated = event => {
		const state = this.state;
		state.note = event.target.value;
		this.props.handleIngredientUpdated(state, this.props.rowIndex);
		this.setState(state);
	}

	handleMeasureUpdated = event => {
		const state = this.state;
		state.measure = event.target.value;
		this.props.handleIngredientUpdated(state, this.props.rowIndex);
		this.setState(state);
	}

	handleUnitsUpdated = event => {
		const state = this.state;
		state.units = event.target.value;
		this.props.handleIngredientUpdated(state, this.props.rowIndex);
		this.setState(state);
	}

	render() {
		return (
			<tr style={{ zIndex: 9999 }}>
				<td>
					<DragHandle/>
				</td>
				<td className="text-left">
					{getNameField(this.state.name, this.onIngredientSelection)}
				</td>
				<td className="text-left">
					<Form.Control
						id={`ingredient-form-name-${this.props.rowIndex}`}
						style={{ width: '150px' }}
						type='text'
						value={this.state.note || ''}
						onChange={this.handleNoteUpdated}
						disabled={!this.state.name}
					/>
				</td>
				<td>
					<Form.Control
						id={`ingredient-form-measure-${this.props.rowIndex}`}
						style={{ width: '100px' }}
						type='number'
						min='0'
						step='0.0001'
						value={this.state.measure || ''}
						onChange={this.handleMeasureUpdated}
						disabled={!this.state.name}
					/>
				</td>
				<td>
					<Form.Control
						id={`ingredient-form-units-${this.props.rowIndex}`}
						value={this.state.units || ''}
						as="select"
						name="units"
						onChange={this.handleUnitsUpdated}
						disabled={!this.state.name}
					>
						<option key='selectHint' hidden value></option>
						{this.state.available_units.map(availableUnit => <option key={`esc-units-form-${availableUnit}`} value={availableUnit}>{this.props.t(`unit_${availableUnit}`)}</option>)}
					</Form.Control>
				</td>
				<td>
					<Button variant="outline-destructive" onClick={() => this.props.handleIngredientRemoved(this.props.rowIndex)}>
						<FontAwesomeIcon icon="ban"/>
					</Button>
				</td>
			</tr>
		);
	}
}

export default withTranslation()(SortableElement(EscandallIngredientForm));
