import authenticationService from "./AuthenticationService";

const API_BASE_URL = "https://api-escandalls.vdedalt.info/api";
// const API_BASE_URL = "https://api-hofmann.vdedalt.info/api";
// const API_BASE_URL = "http://localhost:8000/api";

class Api {
	static getHeaders(authorized) {
		let myHeaders = new Headers();

		if (authorized) {
			myHeaders.append('Authorization', 'Token ' + authenticationService.getToken());
		}
		myHeaders.append('Content-Type', 'application/json');
		return myHeaders
	}

	static request = ({ endpoint, method, params, data, headers }) => {
		let url = `${API_BASE_URL}${endpoint}`;
		if (params) {
			const query = Api.urlEncode(params)
			url = `${url}?${query}`
		}

		let parsedData = null;
		if (data !== undefined && data !== null) {
			parsedData = JSON.stringify(data);
		}

		return new Promise((resolve, reject) => {
			fetch(url, {method: method, body: parsedData, headers: headers})
				.then(response => {
					if (response.ok) {
						return response.text()
					}
					else {
						if (response.status === 401) {
							authenticationService.reset();
							if (!window.location.pathname.includes('/login')) {
								window.location.reload();
							}
						}
						else {
							return response.json().then(error => {
								reject(error);
							});
						}
					}
				})
				.then(resolve)
				.catch(error => {
					reject(error);
				})
		})
	}

	static urlEncode(params) {
		return Object.keys(params)
			.map(key => `${key}=${params[key]}`)
			.join('&');
	}

};

export default Api
