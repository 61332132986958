export default {
	unit_kg: 'kg',
	unit_g: 'g',
	unit_ml: 'ml',
	unit_l: 'l',
	unit_servings: 'racions',
	unit_dozen: 'dotzenes',
	unit_tbsp: 'cs',
	unit_tsp: 'cc',
	unit_unit: 'unitats',

	allergen_celery: 'Api',
	allergen_crustaceans: 'Crustacis',
	'allergen_E220-E228': 'Sulfits',
	allergen_eggs: 'Ous',
	allergen_fish: 'Peix',
	allergen_gluten: 'Gluten',
	allergen_lupin: 'Llobins',
	allergen_milk: 'Llet',
	allergen_molluscs: 'Mol·luscs',
	allergen_mustard: 'Mostassa',
	allergen_nuts: 'Fruits secs',
	allergen_peanuts: 'Cacauets',
	allergen_sesame: 'Sèsam',
	allergen_soybeans: 'Soja',

	application_bar_recipes_tab: 'Receptes',
	application_bar_bases_tab: 'Bases',
	application_bar_products_tab: 'Productes',
	application_bar_menu_switch_restaurant: 'Canviar restaurant',
	application_bar_menu_manage_restaurants: 'Gestionar restaurants',
	application_bar_menu_user_configuration: 'Configuració d\'usuari',
	application_bar_menu_logout: 'Tancar sessió',
	application_bar_switching_restaurant_title: 'Canvia de restaurant',

	escandall_form_creating_recipe_title: 'Creant recepta',
	escandall_form_editing_recipe_title: 'Modificant recepta',
	escandall_form_creating_prep_title: 'Creant base',
	escandall_form_editing_prep_title: 'Modificant base',
	escandall_form_name: 'Nom',
	escandall_form_measure: 'Mesura',
	escandall_form_measure_tooltip: 'La quantitat resultant de fer la preparació amb les quantitats dels ingredients llistats. Es recomana fer la preparació anotant les quantitats dels ingredients primer, i pesar-ne el resultat al final',
	escandall_form_units: 'Unitats',
	escandall_form_units_tooltip: 'Aquesta unitat determinarà com es representen els preus d\'aquest escandall. Per exemple, per kg el preu es mostraria en €/kg',
	escandall_form_search_ingredient_placeholder: 'Cerca un ingredient...',
	escandall_form_ingredients: 'Ingredients',
	escandall_form_ingredient_name: 'Nom',
	escandall_form_ingredient_notes: 'Notes',
	escandall_form_ingredient_measure: 'Mesura',
	escandall_form_ingredient_units: 'Unitats',
	escandall_form_add_ingredient: 'Afegir ingredient',
	escandall_form_ingredient_selector_placeholder: 'Cercar ingredient',
	escandall_form_ingredient_selector_hint: 'Escriu per cercar...',
	escandall_form_ingredient_selector_searching: 'Cercant...',
	escandall_form_ingredient_selector_no_results_found: 'No s\'han trobat resultats',
	escandall_form_procedure: 'Procediment',
	escandall_form_add_step: 'Afegir pas',
	escandall_form_tags: 'Etiquetes',
	escandall_form_save: 'Guardar',
	escandall_form_close: 'Cancel·lar',
	escandall_form_cycle_error: 'No es pot fer servir aquest escandall com a ingredient d\'ell mateix',
	escandall_form_under_use_error: 'Aquest producte s\'està fent servir en altres escandalls, només s\'admeten canvis d\'unitats que estiguin relacionades (per exemple, de `kg` a `g`)',
	escandall_form_ingredient_measure_not_number: 'S\'ha introduït una mesura invàlida en un dels ingredients',

	escandall_scene_search: 'Cercar',
	escandall_scene_new: 'Nou escandall',
	escandall_scene_measure: 'Mesura',
	escandall_scene_price: 'Preu',
	escandall_scene_net_cost: 'Cost net',
	escandall_scene_allergens: 'Al·lergens',
	escandall_scene_tags: 'Etiquetes',
	escandall_scene_ingredients: 'Ingredients',
	escandall_scene_ingredient_name: 'Nom',
	escandall_scene_ingredient_measure: 'Mesura',
	escandall_scene_ingredient_net_price: 'Preu net',
	escandall_scene_ingredient_cost: 'Cost',
	escandall_scene_procedure: 'Procediment',
	escandall_scene_procedure_undefined: 'No s\'ha definit cap procediment',
	escandall_scene_edit: 'Editar',
	escandall_scene_delete: 'Eliminar',
	escandall_scene_deleting_title: 'Eliminant escandall',
	escandall_scene_deleting_text: 'Estàs segur que vols eliminar l\'escandall?',
	escandall_scene_deleting_cancel: 'Cancel·lar',
	escandall_scene_deleting_delete: 'Eliminar',
	escandall_scene_no_restaurant_text: 'No tens cap restaurant, crea\'n un abans de poder gestionar els escandalls',
	escandall_scene_no_restaurant_button: 'Gestiona els teus restaurants',

	product_form_creating_title: 'Nou producte',
	product_form_editing_title: 'Modificant producte',
	product_form_name: 'Nom',
	product_form_measure: 'Mesura',
	product_form_shrinkage: 'Minvat',
	product_form_units: 'Unitats',
	product_form_units_tooltip: 'Aquesta unitat determinarà com es representen els preus d\'aquest producte. Per exemple, per kg el preu es mostraria en €/kg',
	product_form_price: 'Preu',
	product_form_allergens: 'Al·lergens',
	product_form_tags: 'Etiquetes',
	product_form_save: 'Guardar',
	product_form_close: 'Cancel·lar',

	product_scene_search: 'Cercar',
	product_scene_new: 'Nou producte',
	product_scene_measure: 'Mesura',
	product_scene_price: 'Preu',
	product_scene_shrinkage: 'Minvat',
	product_scene_net_cost: 'Cost net',
	product_scene_gross_cost: 'Cost brut',
	product_scene_allergens: 'Al·lergens',
	product_scene_last_updated: 'Última actualització:',
	product_scene_edit: 'Editar',
	product_scene_delete: 'Eliminar',
	product_scene_deleting_title: 'Eliminant producte',
	product_scene_deleting_text: 'Estàs segur que vols eliminar el producte?',
	product_scene_deleting_cancel: 'Cancel·lar',
	product_scene_deleting_delete: 'Eliminar',
	product_scene_error_under_use: 'El producte no es pot eliminar perquè els següents escandalls l\'estan fent servir com a ingredient:',
	product_scene_no_restaurant_text: 'No tens cap restaurant, crea\'n un abans de poder gestionar els productes',
	product_scene_no_restaurant_button: 'Gestiona els teus restaurants',

	restaurant_scene_new_restaurant: 'Nou restaurant',
	restaurant_scene_new_restaurant_tooltip: 'No formes part de cap restaurant, crea\'n un aquí',
	restaurant_scene_configuration_tab: 'General',
	restaurant_scene_members_tab: 'Membres',
	restaurant_scene_log_tab: 'Historial',
	restaurant_scene_subscription_tab: 'Subscripció',
	restaurant_scene_delete: 'Eliminar restaurant',
	restaurant_scene_deleting_title: 'Eliminant restaurant',
	restaurant_scene_deleting_text: 'Estàs segur que vols eliminar el restaurant',
	restaurant_scene_deleting_cancel: 'Cancel·lar',
	restaurant_scene_deleting_delete: 'Eliminar',

	restaurant_form_title: 'Creant un nou restaurant',
	restaurant_form_name: 'Nom del restaurant',
	restaurant_form_name_placeholder: 'Nom del restaurant',
	restaurant_form_save: 'Crear',
	restaurant_form_close: 'Cancel·lar',

	restaurant_configuration_basic_section_title: 'Configuració bàsica',
	restaurant_configuration_name_title: 'Nom del restaurant',
	restaurant_configuration_name_placeholder: 'Nom del restaurant',
	restaurant_configuration_basic_config_save_button: 'Guardar',
	restaurant_configuration_tags_section_title: 'Etiquetes',
	restaurant_configuration_tag_placeholder: 'Text de l\'etiqueta',
	restaurant_configuration_tag_save_button: 'Guardar',
	restaurant_configuration_tag_cancel_button: 'Cancel·lar',
	restaurant_configuration_create_tag_button: 'Nova etiqueta',
	restaurant_configuration_tag_delete_title: 'Eliminant una etiqueta',
	restaurant_configuration_tag_delete_subtitle: 'Estàs segur que vols eliminar aquesta etiqueta?',
	restaurant_configuration_tag_delete_cancel: 'Cancel·lar',
	restaurant_configuration_tag_delete_action: 'Eliminar',

	user_configuration_title: 'Configuració d\'usuari',
	user_configuration_change_basic_info: 'Canviar informació bàsica',
	user_configuration_change_name: 'Nom d\'usuari',
	user_configuration_first_name_placeholder: 'Nom',
	user_configuration_change_language: 'Idioma',
	user_configuration_change_basic_info_save: 'Guardar',
	user_configuration_change_password_title: 'Canviar contrasenya',
	user_configuration_change_password_password_title: 'Nova contrasenya',
	user_configuration_change_password_password_placeholder: 'Nova contrasenya',
	user_configuration_password_info: 'Ha de tindre més de 8 caràcters i no pot ser únicament numèrica',
	user_configuration_change_password_repeat_password_title: 'Repetir nova contrasenya',
	user_configuration_change_password_repeat_password_placeholder: 'Nova contrasenya',
	user_configuration_change_password_save: 'Guardar',
	user_configuration_error_common_password: 'La contrasenya és massa típica',
	user_configuration_error_unknown_password_error: 'La contrasenya no és prou segura',

	user_login_login_title: 'Login',
	user_login_email_title: 'Adreça d\'email',
	user_login_email_placeholder: 'Email',
	user_login_password_title: 'Contrasenya',
	user_login_password_placeholder: 'Contrasenya',
	user_login_login_action: 'Entrar',
	user_login_forgot_password_button: 'Has oblidat la teva contrasenya?',
	user_login_register_header: 'No tens un compte de Cuintab? Crea\'n un gratuïtament aquí!',
	user_login_go_to_register: 'Crea un compte',
	user_login_feedback_from_email_confirmation: 'El teu email s\'ha verificat, ja pots entrar amb el teu compte!',
	user_login_feedback_from_password_reset: 'La teva nova contrasenya s\'ha guardat, ja pots fer-la servir per entrar amb el teu compte!',
	user_login_email_not_verified: 'Aquest email encara no s\'ha verificat',

	user_password_reset_title: 'Reset de contrasenya',
	user_password_reset_email_title: 'Adreça d\'email',
	user_password_reset_email_placeholder: 'Email',
	user_password_reset_reset_action: 'Enviar',
	user_password_reset_back_to_login_button: 'Tornar al login',
	user_password_reset_email_sent_feedback: 'Si existia un compte associat a l\'email, s\'hi ha enviat un correu per reiniciar la contrasenya',
	user_password_reset_complete_goto_login: 'Tornar al login',

	user_registration_title: 'Nou compte',
	user_registration_registration_complete_feedback: 'El registre s\'ha completat correctament!\n\nAbans de poder entrar, has de confirmar l\'email, si us plau revisa el teu correu.',
	user_registration_registration_complete_goto_login: 'Anar al login',
	user_registration_name_title: 'Nom',
	user_registration_name_placeholder: 'Nom',
	user_registration_email_title: 'Adreça d\'email',
	user_registration_email_placeholder: 'Email',
	user_registration_password_title: 'Contrasenya',
	user_registration_password_placeholder: 'Contrasenya',
	user_registration_password_info: 'Ha de tindre més de 8 caràcters i no pot ser únicament numèrica',
	user_registration_repeat_password_title: 'Repetir contrasenya',
	user_registration_repeat_password_placeholder: 'Contrasenya',
	user_registration_registration_action: 'Registrar',
	user_registration_already_registered_button: 'Ja tens un compte? Ves al login',
	user_registration_error_common_password: 'La contrasenya és massa típica',
	user_registration_error_unknown_password_error: 'La contrasenya no és prou segura',
	user_registration_acknowledge_legal: 'Al crear un compte estàs acceptant:',
	user_registration_terms_link: 'Termes i condicions',
	user_registration_privacy_link: 'Política de privacitat',

	user_password_reset_form_title: 'Nova contrasenya',
	user_password_reset_form_password_title: 'Contrasenya',
	user_password_reset_form_password_info: 'Ha de tindre més de 8 caràcters i no pot ser únicament numèrica',
	user_password_reset_form_password_placeholder: 'Contrasenya',
	user_password_reset_form_password_repeat_title: 'Repetir contrasenya',
	user_password_reset_form_password_repeat_placeholder: 'Contrasenya',
	user_password_reset_form_save_password: 'Guardar nova contrasenya',

	form_password_length: 'Ha de tindre un mínim de 8 caràcters',
	form_format_email: 'No és un email',
	form_required_field: 'Camp obligatori',
	form_passwords_dont_match: 'No coincideix amb l\'altra contrasenya',
	form_numeric_password: 'No poden ser només números',
};
