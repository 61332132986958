import React from 'react';
import { Spinner } from 'react-bootstrap';


export default class SubmissionContainer extends React.Component {
	render() {
		const containerStyle = Object.assign({
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
			alignItems: 'center',
		}, this.props.style);

		const LoadingSpinner = (
			<Spinner animation="border" role="status" style={{ position: 'absolute', visibility: this.props.loading ? 'visible' : 'hidden' }}>
				<span className="sr-only"></span>
			</Spinner>
		);

		const Children = React.Children.map(this.props.children, child => (
			<div style={{ visibility: this.props.loading ? 'hidden' : 'visible', margin: '0' }}>
				{child}
			</div>
		));
		return (
			<div style={containerStyle}>
				{Children}
				{LoadingSpinner}
			</div>
		);
	}
}
