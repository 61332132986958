import React, { Component } from 'react';

import { Switch, Route, Redirect } from "react-router-dom";
import ProductsScene from "./scenes/products/ProductsScene";
import EscandallsScene from "./scenes/escandalls/EscandallsScene";
import RestaurantsScene from "./scenes/restaurant/RestaurantsScene";
import Landing from "./landing/Landing";
import Terms from "./landing/Terms";
import Privacy from "./landing/Privacy";
import RestaurantConfiguration from "./scenes/restaurant/RestaurantConfiguration";
import Login from "./scenes/user/Login";
import Registration from "./scenes/user/Registration";
import PasswordReset from "./scenes/user/PasswordReset";
import PasswordResetFromEmail from "./scenes/user/PasswordResetFromEmail";
import UserConfiguration from "./scenes/user/UserConfiguration";
import authenticationService from "./services/AuthenticationService";
import userService from './services/UserService';
import './styles/App.css';
import ApplicationBar from "./components/ApplicationBar";
import PrivateRoute from "./PrivateRoute";
import { Spinner } from 'react-bootstrap';
import i18n from "i18next";


class App extends Component {
	constructor(props){
		super(props);

		this.state = {
			userInfo: null,
		};
	}

	componentDidMount() {
		this.refreshUserInfo();
	}

	refreshUserInfo = () => {
		if (authenticationService.isAuthenticated()) {
			return userService.getUserInfo()
				.then(userInfo => {
					i18n.changeLanguage(userInfo.locale)
					this.setState({ userInfo: userInfo });
				}
			);
		}
	}

	render() {
		if ('/' === window.location.pathname)
			return <Landing />;

		if (authenticationService.isAuthenticated() && this.state.userInfo === null) {
			return (
				<div className="full-screen-container">
					<Spinner animation="border" role="status">
						<span className="sr-only">Loading...</span>
					</Spinner>
				</div>
			);
		}
		else {
			return (
				<div className="App">
					<ApplicationBar userInfo={this.state.userInfo}/>
					{/* id=content required for scroll detection */}
					<div id="content" className="contentClass">
						<Switch>
							<Route path="/terms" component={Terms}></Route>
							<Route path="/privacy" component={Privacy}></Route>

							<Route path="/login" render={() => (
								authenticationService.isAuthenticated() ? (
									<Redirect to="/recipes"/>
								) : (
									<Route path="/login" component={() => (<Login onLoginSuccessful={this.refreshUserInfo}/>)}></Route>
								)
							)} />

							<Route path="/register" component={Registration}></Route>
							<Route path="/passwordReset" component={PasswordReset}></Route>
							<Route path="/reset/:uid/:token" component={PasswordResetFromEmail}/>
							
							<PrivateRoute exact path="/products" component={ProductsScene}></PrivateRoute>
							<PrivateRoute path="/products/:id" component={ProductsScene}></PrivateRoute>

							<PrivateRoute exact path="/recipes" component={() => (<EscandallsScene is_base={false}/>)}></PrivateRoute>
							<PrivateRoute path="/recipes/:id" component={() => (<EscandallsScene is_base={false}/>)}></PrivateRoute>
							<PrivateRoute exact path="/preps" component={() => (<EscandallsScene is_base={true} />)}></PrivateRoute>
							<PrivateRoute path="/preps/:id" component={() => (<EscandallsScene is_base={true} />)}></PrivateRoute>

							<PrivateRoute exact path="/restaurants" component={RestaurantsScene}></PrivateRoute>
							<PrivateRoute path="/restaurants/:id" component={RestaurantsScene}></PrivateRoute>
							<PrivateRoute path="/restaurantConfiguration" component={RestaurantConfiguration}></PrivateRoute>

							<PrivateRoute path="/userConfiguration" component={() => (<UserConfiguration/>)}></PrivateRoute>
						</Switch>
					</div>
				</div>
			);
		}
	}
}

export default App;
