import React from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import userService from '../services/UserService';
import authenticationService from '../services/AuthenticationService';
import { Row, Col, Button, NavDropdown, Modal, ListGroup } from 'react-bootstrap';

import packageJson from '../../package.json';
import '../styles/components/ApplicationBar.css';

class ApplicationBar extends React.Component {
	constructor(props) {
		super(props);

		this.userInfo = this.props.userInfo;

		this.state = {
			userMenuOpen: false,
			switchingRestaurant: false,
		}
	}

	switchToRestaurant = id => {
		userService.checkInRestaurant(id);
		window.location.reload();
	}

	renderSwitchRestaurantModal = () => {
		const getRenderedSwitchRestaurantRows = () => {
			const renderedRows = userService.getRestaurantMemberships().map((membership, i) => {
				return (
					<ListGroup.Item key={`restaurant-option-row-${i}`} action onClick={() => this.switchToRestaurant(membership.restaurant.id)}>
						{membership.restaurant.name}
					</ListGroup.Item>);
				}
			);
			return renderedRows;
		}

		if (this.state.switchingRestaurant) {
			return (
				<Modal show={true} onHide={() => this.setState({ switchingRestaurant: false })}>
					<Modal.Header closeButton>
						<Modal.Title>{this.props.t('application_bar_switching_restaurant_title')}</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<ListGroup variant="flush">
							{getRenderedSwitchRestaurantRows()}
						</ListGroup>
					</Modal.Body>
				</Modal>
			);
		}
	}

	renderLoggedOutBar = () => {
		return (
			<Row className='navBar'>
				<Col className='brandCell' style={{ justifyContent: 'left', paddingLeft: '40px' }}>
					Cuintab
				</Col>
			</Row>
		);
	}

	renderLoggedInBar = () => {
		const checkedInRestaurant = userService.getCheckInRestaurantInfo();
		return (
			<Row className='navBar'>
				<Col xs={4} className='brandCell'>
					Cuintab
				</Col>
				<Col className='col-auto navCell'>
					<Button variant='link' className={window.location.pathname.includes('recipes') ? 'navCellButtonVisible' : 'navCellButton'} onClick={() => this.props.history.push('/recipes')}>
						{this.props.t('application_bar_recipes_tab')}
					</Button>
					<div className={window.location.pathname.includes('recipes') ? 'navCellUnderlineVisible' : 'navCellUnderline'}/>
				</Col>
				<Col className='col-auto navCell'>
					<Button variant='link' className={window.location.pathname.includes('preps') ? 'navCellButtonVisible' : 'navCellButton'} onClick={() => this.props.history.push('/preps')}>
						{this.props.t('application_bar_bases_tab')}
					</Button>
					<div className={window.location.pathname.includes('preps') ? 'navCellUnderlineVisible' : 'navCellUnderline'}/>
				</Col>
				<Col className=' col-auto navCell'>
					<Button variant='link' className={window.location.pathname.includes('products') ? 'navCellButtonVisible' : 'navCellButton'} onClick={() => this.props.history.push('/products')}>
						{this.props.t('application_bar_products_tab')}
					</Button>
					<div className={window.location.pathname.includes('products') ? 'navCellUnderlineVisible' : 'navCellUnderline'}/>
				</Col>
				<Col>
					<NavDropdown className='navDropdown' style={{ float: 'right'}} title={
						<div style={{ display: 'inline-block'}}>
							<p style={{ color: '#D4E056' }} className='navDropLabel restaurantLabel'>{checkedInRestaurant ? checkedInRestaurant.restaurant.name : '-'}</p>
							<p className='navDropLabel userLabel'>{this.userInfo.email}</p>
						</div>
					}>
						<NavDropdown.Item disabled={userService.getRestaurantMemberships().length < 2} onClick={() => this.setState({ switchingRestaurant: true })}>{this.props.t('application_bar_menu_switch_restaurant')}</NavDropdown.Item>
						<NavDropdown.Item onClick={() => this.props.history.push('/restaurants')}>{this.props.t('application_bar_menu_manage_restaurants')}</NavDropdown.Item>
						<NavDropdown.Divider />
						<NavDropdown.Item onClick={() => this.props.history.push('/userConfiguration')}>{this.props.t('application_bar_menu_user_configuration')}</NavDropdown.Item>
						<NavDropdown.Item onClick={this.logoutClicked} >{this.props.t('application_bar_menu_logout')}</NavDropdown.Item>
						<NavDropdown.Divider />
						<NavDropdown.Item disabled>v{packageJson.version}</NavDropdown.Item>
					</NavDropdown>
				</Col>
				{this.renderSwitchRestaurantModal()}
			</Row>
		);
	}

	render () {
		return this.userInfo ? this.renderLoggedInBar() : this.renderLoggedOutBar();
	}

	logoutClicked = () => {
		authenticationService.reset()
		window.location.reload();
	}
}

ApplicationBar.defaultProps = {
	userInfo: null,
};

export default withTranslation()(withRouter(ApplicationBar));
