import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import authenticationService from "./services/AuthenticationService";

const PrivateRoute = ref => (
	!authenticationService.isAuthenticated() ?
		(
			<Redirect
				to={{
					pathname: '/login',
					state: {
						from: ref.location,
					},
				}}
			/>
		) : (
			<Route
				path={ref.path}
				component={ref.component}
				render={ref.render}
			/>
	)
);

export default PrivateRoute;
