import React from 'react';
import authenticationService from "../../services/AuthenticationService";
import SubmissionContainer from '../../components/SubmissionContainer';

import { Container, Col, Row, Form, Button, Alert } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as yup from 'yup';


class Login extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			error: '',
			formSubmitting: false,
		}
	}

	handleSubmit = values => {
		this.setState({
			formSubmitting: true,
		});

		authenticationService.login(values.email, values.password)
			.then(() => {
				this.props.onLoginSuccessful()
					.then(() => {
						// with reload navBar and locale are updated as well
						// this._reactInternalInstance._context.router.history.push('/products/');
						window.location.reload();
					});
			}).catch(result => {
				this.setState({
					formSubmitting: false,
				});
				if (result) {
					if ('non_field_errors' in result) {
						if (result['non_field_errors'].includes('E-mail is not verified.'))
							this.setState({ error: 'user_login_email_not_verified' });
					}
				}
			})
	}

	renderForm = () => {
		const formSchema = yup.object({
			email: yup.string().email('form_format_email').required('form_required_field'),
			password: yup.string().required('form_required_field')
		});
		return (
			<Container className='pt-4 pb-4'>
				<Row>
					<Col xs={4} style={{ marginTop: '70px' }}>
						<p style={{ color: '#D4E056', textAlign: 'right', fontWeight: 800, marginBottom: '5px', fontSize: '36px' }}>{this.props.t('user_login_login_title')}</p>
					</Col>
					<Col xs={4} className='ml-4' style={{ marginTop: '70px' }}>
						<Formik validationSchema={formSchema} initialValues={{email: '', password: ''}}>
							{({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
								<div>
									<Form.Group controlId="login">
										<Form.Label>{this.props.t('user_login_email_title')}</Form.Label>
										<Form.Control
											autoFocus
											type="email"
											name="email"
											placeholder={this.props.t('user_login_email_placeholder')}
											value={values.email}
											onChange={handleChange}
											isInvalid={errors.email}
											onBlur={handleBlur}
										/>
										<Form.Control.Feedback type="invalid">
											{this.props.t(errors.email)}
										</Form.Control.Feedback>
									</Form.Group>
									<Form.Group controlId="formBasicPassword">
										<Form.Label>{this.props.t('user_login_password_title')}</Form.Label>
										<Form.Control
											type="password"
											name="password"
											placeholder={this.props.t('user_login_password_placeholder')}
											value={values.password}
											onChange={handleChange}
											isInvalid={errors.password}
											onBlur={handleBlur}
										/>
										<Form.Control.Feedback type="invalid">
											{this.props.t(errors.password)}
										</Form.Control.Feedback>
									</Form.Group>
									<SubmissionContainer loading={this.state.formSubmitting} style={{ justifyContent: 'left' }}>
										<Form.Control.Feedback type="invalid">
											{this.state.error}
										</Form.Control.Feedback>
										<Button variant="primary" type="submit" disabled={!isValid} onClick={isValid ? () => this.handleSubmit(values) : undefined}>
											{this.props.t('user_login_login_action')}
										</Button>
									</SubmissionContainer>
								</div>
							)}
						</Formik>
						<Button variant="link" onClick={() => this.props.history.push('/passwordReset')}>
							{this.props.t('user_login_forgot_password_button')}
						</Button>
						<div className="error">{this.props.t(this.state.error)}</div>

						<div style={{ marginTop: '50px'}}>
							<h5>{this.props.t('user_login_register_header')}</h5>
							<Button variant="secondary" onClick={() => this.props.history.push('/register')}>
								{this.props.t('user_login_go_to_register')}
							</Button>
						</div>
					</Col>
				</Row>
			</Container>
		);
	}

	render () {
		const getFeedbackBanner = feedbackText =>
			feedbackText ? (
				<Alert variant='success'>
					{feedbackText} 
				</Alert>
			) : null;

		let FeedbackBanner = null;
		if (this.props.location.search.includes('?confirmedEmail'))
			FeedbackBanner = getFeedbackBanner('user_login_feedback_from_email_confirmation');
		else if (this.props.location.search.includes('?passwordReset'))
			FeedbackBanner = getFeedbackBanner('user_login_feedback_from_password_reset');

		return (
			<Container>
				{FeedbackBanner}
				{this.renderForm()}
			</Container>
		);
	}
};

export default withTranslation()(withRouter(Login));
