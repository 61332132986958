import React from 'react';
import authenticationService from "../../services/AuthenticationService";
import SubmissionContainer from '../../components/SubmissionContainer';

import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Col, Row, Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';


class PasswordResetFromEmail extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			error: '',
			formSubmitting: false,
		}
	}

	handleSubmit = values => {
		this.setState({
			formSubmitting: true,
		});

		authenticationService.passwordResetUpdatePassword(this.props.match.params.uid, this.props.match.params.token, values.password1, values.password2)
			.then(() => {
				this.props.history.push('/login?passwordReset');
			}).catch(result => {
				this.setState({
					formSubmitting: false,
				});
				if (result) {
					if ('non_field_errors' in result) {
						this.setState({
							error: result.non_field_errors,
						});
					}
				}
			});
	}

	renderForm = () => {
		const Errors = <div className="error"> {this.state.error} </div>

		const formSchema = yup.object({
			password1: yup.string().min(8, 'form_password_length').matches(/(?!^\d+$)^.+$/, 'form_numeric_password').required('form_required_field'),
			password2: yup.string().required('form_required_field').oneOf([yup.ref('password1')], 'form_passwords_dont_match')
		});

		return (
			<Container className='pt-4 pb-4'>
				<Row>
					<Col xs={4} style={{ marginTop: '70px' }}>
						<p style={{ color: '#D4E056', textAlign: 'right', fontWeight: 800, marginBottom: '5px', fontSize: '36px' }}>{this.props.t('user_password_reset_form_title')}</p>
					</Col>
					<Col xs={4} className='ml-4' style={{ marginTop: '70px' }}>
						<Formik validationSchema={formSchema} initialValues={{ password1: '', password2: '' }}>
							{({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
								<div>
									{Errors}
									<Form.Group controlId="login">
										<Form.Label>{this.props.t('user_password_reset_form_password_title')}</Form.Label>
										<OverlayTrigger overlay={<Tooltip id='password-info-tooltip'>{this.props.t('user_password_reset_form_password_info')}</Tooltip>}>
											<FontAwesomeIcon icon="info-circle"/>
										</OverlayTrigger>
										<Form.Control
											autoFocus
											type="password"
											name="password1"
											placeholder={this.props.t('user_password_reset_form_password_placeholder')}
											value={values.password1}
											onChange={handleChange}
											isInvalid={errors.password1}
											onBlur={handleBlur}
										/>
										<Form.Control.Feedback type="invalid">
											{this.props.t(errors.password1)}
										</Form.Control.Feedback>
									</Form.Group>
									<Form.Group controlId="formBasicPassword">
										<Form.Label>{this.props.t('user_password_reset_form_password_repeat_title')}</Form.Label>
										<Form.Control
											type="password"
											name="password2"
											placeholder={this.props.t('user_password_reset_form_password_repeat_placeholder')}
											value={values.password2}
											onChange={handleChange}
											isInvalid={errors.password2}
											onBlur={handleBlur}
										/>
										<Form.Control.Feedback type="invalid">
											{this.props.t(errors.password2)}
										</Form.Control.Feedback>
									</Form.Group>
									<SubmissionContainer loading={this.state.formSubmitting} style={{ justifyContent: 'left' }}>
										<Button variant="primary" type="submit" disabled={!isValid} onClick={isValid ? () => this.handleSubmit(values) : undefined}>
											{this.props.t('user_password_reset_form_save_password')}
										</Button>
									</SubmissionContainer>
								</div>
							)}
						</Formik>
					</Col>
				</Row>
			</Container>
		);
	}

	render () {    
		return this.renderForm();
	}
};

export default withTranslation()(withRouter(PasswordResetFromEmail));
