export default {
	landing_home_title: 'Inici',
	landing_go_to_app_button: 'Entra a Cuintab',
	landing_title: 'Gestiona el teu restaurant de forma simple i clara',
	landing_subtitle: 'Obtingues informació valuosa amb una inversió de temps mínima, que conduirà a decisions intel·ligents i fomentades sobre els costos del teu negoci',
	landing_subtitle_register_button: 'Crea un compte',
	landing_about_title: 'Què és Cuintab?',
	landing_about_subtitle: 'Cuintab sorgeix de dos conceptes principals - el primer és que fer escandalls i quatre operacions bàsiques de gestió, aporta una informació i transparència al restaurant que pot evidenciar problemes que no es veien a simple vista. A més pot mostrar oportunitats per millorar el creixement d\'aquest, sigui quina sigui la dimensió del negoci.',
	landing_about_subtitle_2: 'El segon, és que les alternatives a Cuintab són o el paper rudimentari, o aplicacions d\'una complexitat i detall que requereixen algú dedicat a aprendre, fer servir, i tindre l\'eina actualitzada... fets que a més acaben resultant en preus poc assequibles per la majoria de negocis. Cuintab preten arribar a tothom que tingui un restaurant, tant a nivell permissiu, com a nivell tècnic.',
	landing_about_card_1_title: 'Simple',
	landing_about_card_1_content: 'Una eina de suport ha de permetre centrar-te en la teva feina primordial - la que t\'agrada de debó. Quan la gestió requereix massa dedicació i manteniment, sempre s\'acaba deixant de banda',
	landing_about_card_2_title: 'Intuitiva',
	landing_about_card_2_content: 'Fes servir Cuintab directament, sense necessitat de formacions ni tutorials tediosos',
	landing_about_card_3_title: 'A l\'abast de tothom',
	landing_about_card_3_content: 'No fa falta ser una multinacional - tothom té dret a gaudir d\'avantatges digitals, professionalitzar-se, i poder gestionar els seus recursos d\'una forma més còmoda i moderna',
	landing_features_title: 'Funcionalitats',
	landing_features_subtitle: 'Les justes i necessaries.',
	landing_features_subtitle_2: 'Estem convençuts que la diferència entre fer uns mínims de gestió i no fer res, és abismal i determinant per l\'èxit del nostre restaurant. A partir d\'aquests mínims, afegir detalls en la gestió segurament aportarà valor, però serà de tan poca rellevancia que la complexitat o esforç necessaris en aquests casos no ho valdrà. Per això Cuintab té un compromís amb la simplicitat i es centra en els punts de més impacte.',
	landing_features_f1_title: 'Oblida\'t de les matemàtiques',
	landing_features_f1_content: 'Cuintab calcula totes les quantitats i proporcions per a tu. Els canvis d\'unitats també te\'ls gestiona Cuintab: compra en kg i cuina en grams, els escandalls faran el canvi automàticament.',
	landing_features_f2_title: 'Obtingues resultats aportant un mínim d\'informació',
	landing_features_f2_content: 'Crear ingredients i escandalls és molt ràpid, Cuintab només et demanarà la informació indispensable perquè no t\'hagis d\'entretindre massa temps en cada ingredient.',
	landing_features_f3_title: 'Informació complexa, visualment simple',
	landing_features_f3_content: 'Aconsegueix veure i interpretar informació de costos i preus d\'una forma clara, simple, i fàcil, en taules i gràfics.',
	landing_features_f4_title: 'Al·lèrgens i etiquetes',
	landing_features_f4_content: 'Organitza els escandalls amb etiquetes i despreocupa\'t dels al·lèrgens. Si en algun pas d\'una preparació s\'ha fet servir algun ingredient amb al·lèrgens, Cuintab n\'arrossegarà la informació i el mostrarà en la preparació final.',
	landing_features_f5_title: 'Propagació de la informació i els canvis',
	landing_features_f5_content: 'Igual que amb els al·lèrgens, canviar el preu o quantitat d\'un ingredient automàticament s\'aplicarà a tots els escandalls que l\'estiguin fent servir.',
	landing_features_f6_title: 'Fes-ho al teu gust',
	landing_features_f6_content: 'Cuintab et dota d\'eines i possibilitats: minvat, productes, bases, i receptes. Però està a les teves mans com organitzar la teva cuina. Pots introduir els productes nets sense minvat i amb el cost calculat per a tu, o deixar que Cuintab t\'ho gestioni amb el camp de minvat. També pots introduir ingredients processats com a producte, o com a base minvada directament depenent de com es tracti al teu restaurant.',
	landing_pricing_title: 'Tarifes',
	landing_pricing_subtitle: 'Els comptes d\'usuari de Cuintab són sempre gratuïts - les subscripcions van associades als restaurants.',
	landing_pricing_subtitle_2: 'La millor manera de provar la plataforma gratuïtament és creant un restaurant bàsic, que té limitacions, però no té cap cost.',
	landing_pricing_subtitle_3: 'Totes les actualitzacions i millores de l\'aplicació seran automàtiques, i gratuïtes!',
	landing_pricing_card_1_title: 'Restaurant bàsic',
	landing_pricing_card_1_subtitle: 'Gratuït',
	landing_pricing_card_2_title: 'Restaurant professional',
	landing_pricing_card_quota_subtitle: 'al mes',
	landing_pricing_card_3_title: 'Restaurant d\'empresa',
	landing_pricing_card_1_members: '1 membre (tu)',
	landing_pricing_card_1_recipes: '5 receptes',
	landing_pricing_card_1_ingredients: '15 ingredients',
	landing_pricing_card_allergens: 'Al·lergens',
	landing_pricing_card_tags: 'Etiquetes',
	landing_pricing_card_2_members: '2 membres',
	landing_pricing_card_unlimited_recipes: 'Receptes il·limitades',
	landing_pricing_card_unlimited_ingredients: 'Ingredients il·limitats',
	landing_pricing_card_unlimited_members: 'Membres il·limitats',
	landing_screenshots_title: 'Captures de pantalla',
	landing_screenshots_subtitle: 'Pots fer-te una idea ràpida de com és l\'aplicació, però el millor es crear un compte i un restaurant bàsic.',
	landing_contact_title: 'Contacte',
	landing_contact_subtitle: 'Estem oberts a absolutament qualsevol tipus de consulta, ja sigui general, tècnica, de curiositat, per reportar un error, o per suggerir canvis, millores, o funcionalitats noves.',
	landing_contact_email_title: 'Email o telèfon (o no sabrem a on respondre)',
	landing_contact_content_title: 'Missatge',
	landing_contact_send_button: 'Enviar missatge',
	landing_form_contact_invalid: 'Informació de contacte invàlida',
	landing_form_content_short: 'S\'han d\'escriure un mínim de 20 caràcters',
	landing_form_error: 'S\'ha produït un error, si us plau contacta amb contact@cuintab.com',
	landing_form_sent: 'El teu missatge s\'ha enviat correctament, rebràs una resposta tan aviat com el veiem :)',
	landing_terms_link: 'Termes i condicions',
	landing_privacy_link: 'Política de privacitat',

	landing_promo_title: 'Cuintab és GRATUÏT per ara!',
	landing_promo_content: 'Com que considerem que encara hem de polir algunes coses de l\'aplicació, hem decidit d\'oferir-la gratuïtament i sense restriccions fins que estigui del tot llesta. A més creiem que és una bona oportunitat pel sector de la restauració durant el temps de pandemia, que malauradament dóna més temps per dedicar a la revisió dels processos interns del negoci, i on la revisió de costos i marges ara és més important que mai.',
	landing_promo_disclaimer: 'Abans d\'activar el model de subscripció, ens comprometem a: avisar amb antelació, mantenir les dades que hagueu introduït, les tarifes no seran superiors a les ara mostrades, i us podreu descarregar les dades que ja havieu introduït en un fitxer CSV per si no us interessa la subscripció a Cuintab.'
};
