export default {
	unit_kg: 'kg',
	unit_g: 'g',
	unit_ml: 'ml',
	unit_l: 'l',
	unit_servings: 'raciones',
	unit_dozen: 'docenas',
	unit_tbsp: 'cs',
	unit_tsp: 'cc',
	unit_unit: 'unidades',

	allergen_celery: 'Apio',
	allergen_crustaceans: 'Curstáceos',
	'allergen_E220-E228': 'Sulfitos',
	allergen_eggs: 'Huevos',
	allergen_fish: 'Pescado',
	allergen_gluten: 'Gluten',
	allergen_lupin: 'Altramuces',
	allergen_milk: 'Leche',
	allergen_molluscs: 'Moluscos',
	allergen_mustard: 'Mostaza',
	allergen_nuts: 'Frutos secos',
	allergen_peanuts: 'Cacahuetes',
	allergen_sesame: 'Sésamo',
	allergen_soybeans: 'Soja',

	application_bar_recipes_tab: 'Recetas',
	application_bar_bases_tab: 'Bases',
	application_bar_products_tab: 'Productos',
	application_bar_menu_switch_restaurant: 'Cambiar restaurante',
	application_bar_menu_manage_restaurants: 'Gestionar restaurantes',
	application_bar_menu_user_configuration: 'Configuración de usuario',
	application_bar_menu_logout: 'Cerrar sessión',
	application_bar_switching_restaurant_title: 'Cambia de restaurante',

	escandall_form_creating_recipe_title: 'Creando receta',
	escandall_form_editing_recipe_title: 'Modificando receta',
	escandall_form_creating_prep_title: 'Creando base',
	escandall_form_editing_prep_title: 'Modificando base',
	escandall_form_name: 'Nombre',
	escandall_form_measure: 'Medida',
	escandall_form_measure_tooltip: 'La cantidad resultante de hacer la preparación con las cantidades de los ingredientes listados. Se recomienda hacer la preparación anotando las cantidades de los ingredientes primero, y pesar el resultado al final',
	escandall_form_units: 'Unidades',
	escandall_form_units_tooltip: 'Esta unidad determinará cómo se representan los precios de este escandallo. Por ejemplo, por kg el precio se mostraría en €/kg',
	escandall_form_search_ingredient_placeholder: 'Busca un ingrediente...',
	escandall_form_ingredients: 'Ingredientes',
	escandall_form_ingredient_name: 'Nombre',
	escandall_form_ingredient_notes: 'Notas',
	escandall_form_ingredient_measure: 'Medida',
	escandall_form_ingredient_units: 'Unidades',
	escandall_form_add_ingredient: 'Añadir ingrediente',
	escandall_form_ingredient_selector_placeholder: 'Buscar ingrediente',
	escandall_form_ingredient_selector_hint: 'Escribe para buscar...',
	escandall_form_ingredient_selector_searching: 'Buscando...',
	escandall_form_ingredient_selector_no_results_found: 'No se han encontrado resultados',
	escandall_form_procedure: 'Procedimiento',
	escandall_form_add_step: 'Añadir paso',
	escandall_form_tags: 'Etiquetas',
	escandall_form_save: 'Guardar',
	escandall_form_close: 'Cancelar',
	escandall_form_cycle_error: 'No se puede utilizar este escandallo como ingrediente de él mismo ',
	escandall_form_under_use_error: 'Este producto se está utilizando en otros escandallos, sólo se admiten cambios de unidades que estén relacionadas (por ejemplo, de `kg` a` g`)',
	escandall_form_ingredient_measure_not_number: 'Se ha introducido una medida inválida en uno de los ingredientes',

	escandall_scene_search: 'Buscar',
	escandall_scene_new: 'Nuevo escandallo',
	escandall_scene_measure: 'Medida',
	escandall_scene_price: 'Precio',
	escandall_scene_net_cost: 'Coste neto',
	escandall_scene_allergens: 'Alérgenos',
	escandall_scene_tags: 'Etiquetas',
	escandall_scene_ingredients: 'Ingredientes',
	escandall_scene_ingredient_name: 'Nombre',
	escandall_scene_ingredient_measure: 'Medida',
	escandall_scene_ingredient_net_price: 'Precio neto',
	escandall_scene_ingredient_cost: 'Coste',
	escandall_scene_procedure: 'Procedimiento',
	escandall_scene_procedure_undefined: 'No se ha definido ningún procedimiento',
	escandall_scene_edit: 'Editar',
	escandall_scene_delete: 'Eliminar',
	escandall_scene_deleting_title: 'Eliminando escandallo',
	escandall_scene_deleting_text: '¿Estás seguro que quieres eliminar el escandallo?',
	escandall_scene_deleting_cancel: 'Cancelar',
	escandall_scene_deleting_delete: 'Eliminar',
	escandall_scene_no_restaurant_text: 'No tienes ningún restaurante, crea uno antes de poder gestionar los escandallos',
	escandall_scene_no_restaurant_button: 'Gestiona tus restaurantes',

	product_form_creating_title: 'Nuevo producto',
	product_form_editing_title: 'Modificando producto',
	product_form_name: 'Nombre',
	product_form_measure: 'Medida',
	product_form_shrinkage: 'Merma',
	product_form_units: 'Unidades',
	product_form_units_tooltip: 'Esta unidad determinará cómo se representan los precios de este producto. Por ejemplo, por kg el precio se mostraría en €/kg',
	product_form_price: 'Precio',
	product_form_allergens: 'Alérgenos',
	product_form_tags: 'Etiquetas',
	product_form_save: 'Guardar',
	product_form_close: 'Cancelar',

	product_scene_search: 'Buscar',
	product_scene_new: 'Nuevo producto',
	product_scene_measure: 'Medida',
	product_scene_price: 'Precio',
	product_scene_shrinkage: 'Merma',
	product_scene_net_cost: 'Coste neto',
	product_scene_gross_cost: 'Coste bruto',
	product_scene_allergens: 'Alérgenos',
	product_scene_last_updated: 'Última actualización:',
	product_scene_edit: 'Editar',
	product_scene_delete: 'Eliminar',
	product_scene_deleting_title: 'Eliminando producto',
	product_scene_deleting_text: '¿Estás seguro que quieres eliminar el producto?',
	product_scene_deleting_cancel: 'Cancelar',
	product_scene_deleting_delete: 'Eliminar',
	product_scene_error_under_use: 'El producto no se puede eliminar porque los siguientes escandallos lo están usando como ingrediente:',
	product_scene_no_restaurant_text: 'No tienes ningún restaurante, crea uno antes de poder gestionar los productos',
	product_scene_no_restaurant_button: 'Gestiona tus restaurantes',

	restaurant_scene_new_restaurant: 'Nuevo restaurante',
	restaurant_scene_new_restaurant_tooltip: 'No formas parte de ningún restaurante, crea uno aquí',
	restaurant_scene_configuration_tab: 'General',
	restaurant_scene_members_tab: 'Miembros',
	restaurant_scene_log_tab: 'Historial',
	restaurant_scene_subscription_tab: 'Suscripción',
	restaurant_scene_delete: 'Eliminar restaurante',
	restaurant_scene_deleting_title: 'Eliminando restaurante',
	restaurant_scene_deleting_text: '¿Estás seguro que quieres eliminar el restaurante?',
	restaurant_scene_deleting_cancel: 'Cancelar',
	restaurant_scene_deleting_delete: 'Eliminar',

	restaurant_form_title: 'Creando un nou restaurante',
	restaurant_form_name: 'Nombre del restaurante',
	restaurant_form_name_placeholder: 'Nombre del restaurante',
	restaurant_form_save: 'Crear',
	restaurant_form_close: 'Cancelar',

	restaurant_configuration_basic_section_title: 'Configuración básica',
	restaurant_configuration_name_title: 'Nombre del restaurante',
	restaurant_configuration_name_placeholder: 'Nombre del restaurante',
	restaurant_configuration_basic_config_save_button: 'Guardar',
	restaurant_configuration_tags_section_title: 'Etiquetas',
	restaurant_configuration_tag_placeholder: 'Texto de la etiqueta',
	restaurant_configuration_tag_save_button: 'Guardar',
	restaurant_configuration_tag_cancel_button: 'Cancelar',
	restaurant_configuration_create_tag_button: 'Nueva etiqueta',
	restaurant_configuration_tag_delete_title: 'Eliminando una etiqueta',
	restaurant_configuration_tag_delete_subtitle: '¿Seguro que quieres eliminar esta etiqueta?',
	restaurant_configuration_tag_delete_cancel: 'Cancelar',
	restaurant_configuration_tag_delete_action: 'Eliminar',

	user_configuration_title: 'Configuración de usuario',
	user_configuration_change_basic_info: 'Cambiar información básica',
	user_configuration_change_name: 'Nombre de usuario',
	user_configuration_first_name_placeholder: 'Nombre',
	user_configuration_change_language: 'Idioma',
	user_configuration_change_basic_info_save: 'Guardar',
	user_configuration_change_password_title: 'Cambiar contraseña',
	user_configuration_change_password_password_title: 'Nueva contraseña',
	user_configuration_change_password_password_placeholder: 'Nueva contraseña',
	user_configuration_password_info: 'Debe tener más de 8 caracteres y no puede ser únicamente numérica',
	user_configuration_change_password_repeat_password_title: 'Repetir nueva contraseña',
	user_configuration_change_password_repeat_password_placeholder: 'Nueva contraseña',
	user_configuration_change_password_save: 'Guardar',
	user_configuration_error_common_password: 'La contraseña es demasiado común',
	user_configuration_error_unknown_password_error: 'La contraseña no es suficientemente segura',

	user_login_login_title: 'Login',
	user_login_email_title: 'Dirección de email',
	user_login_email_placeholder: 'Email',
	user_login_password_title: 'Contraseña',
	user_login_password_placeholder: 'Contraseña',
	user_login_login_action: 'Entrar',
	user_login_forgot_password_button: '¿Olvidaste tu contraseña?',
	user_login_register_header: '¿No tienes una cuenta de Cuintab? Crea una gratuitamente aquí!',
	user_login_go_to_register: 'Crear una cuenta',
	user_login_feedback_from_email_confirmation: 'Tu email se ha verificado, ya puedes entrar con tu cuenta!',
	user_login_feedback_from_password_reset: 'Tu nueva contraseña se ha guardado, ya puedes usarla para entrar con tu cuenta!',
	user_login_email_not_verified: 'Este email todavía no se ha verificado',

	user_password_reset_title: 'Reset de contraseña',
	user_password_reset_email_title: 'Dirección de email',
	user_password_reset_email_placeholder: 'Email',
	user_password_reset_reset_action: 'Enviar',
	user_password_reset_back_to_login_button: 'Volver al login',
	user_password_reset_email_sent_feedback: 'Si existía una cuenta asociada a la del email, se le ha enviado un correo para reiniciar la contraseña',
	user_password_reset_complete_goto_login: 'Volver al login',

	user_registration_title: 'Nueva cuenta',
	user_registration_registration_complete_feedback: 'El registro se ha completado correctamente!\n\nAntes de poder entrar, tienes que confirmar el email, por favor revisa tu correo.',
	user_registration_registration_complete_goto_login: 'Ir al login',
	user_registration_name_title: 'Nombre',
	user_registration_name_placeholder: 'Nombre',
	user_registration_email_title: 'Dirección de email',
	user_registration_email_placeholder: 'Email',
	user_registration_password_title: 'Contraseña',
	user_registration_password_placeholder: 'Contraseña',
	user_registration_password_info: 'Debe tener más de 8 caracteres y no puede ser únicamente numérica',
	user_registration_repeat_password_title: 'Repetir contraseña',
	user_registration_repeat_password_placeholder: 'Contraseña',
	user_registration_registration_action: 'Registrar',
	user_registration_already_registered_button: '¿Tienes cuenta? Ir al login',
	user_registration_error_common_password: 'La contraseña es demasiado común',
	user_registration_error_unknown_password_error: 'La contraseña no es lo suficientemente segura',
	user_registration_acknowledge_legal: 'Al crear una cuenta estás aceptando:',
	user_registration_terms_link: 'Términos y condiciones',
	user_registration_privacy_link: 'Política de privacidad',

	user_password_reset_form_title: 'Nueva contraseña',
	user_password_reset_form_password_title: 'Contraseña',
	user_password_reset_form_password_info: 'Debe tener más de 8 caracteres y no puede ser únicamente numérica',
	user_password_reset_form_password_placeholder: 'Contraseña',
	user_password_reset_form_password_repeat_title: 'Repetir contraseña',
	user_password_reset_form_password_repeat_placeholder: 'Contraseña',
	user_password_reset_form_save_password: 'Guardar nueva contraseña',

	form_password_length: 'Debe tener un mínimo de 8 caracteres',
	form_format_email: 'No es un email',
	form_required_field: 'Campo obligatorio',
	form_passwords_dont_match: 'No coincide con la otra contraseña',
	form_numeric_password: 'No pueden ser solo números',
};
