import React from "react";

import { Row, Col, Form, Container, Button, Table, Modal, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { SortableContainer, arrayMove } from 'react-sortable-hoc';
import { Formik } from 'formik';
import * as yup from 'yup';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import productService from '../../services/ProductService';
import userService from '../../services/UserService';
import EscandallIngredientForm from './EscandallIngredientForm'
import EscandallStepForm from './EscandallStepForm'
import SubmissionContainer from '../../components/SubmissionContainer';


/**
 *  This class represents the modal that opens up to edit Escandalls, with their basic info, and sortable ingredients and steps
 */
class EscandallForm extends React.Component {
	constructor(props) {
		super(props);

		this.isNew = this.props.isNew;
		const product = this.props.product || { is_base: this.props.isBase, ingredients: [], steps: [], tags: [] };

		// those need to be decoupled because they are changed by this component and the subcomponent, and state updates would conflict
		this.ingredients = product.ingredients;
		this.steps = product.steps;
		product.ingredients = undefined;
		product.steps = undefined;

		this.state = {
			product: product,
			formSubmitting: false,
		}
	}

	onIngredientSortEnd = ({oldIndex, newIndex}) => {
		this.ingredients = arrayMove(this.ingredients, oldIndex, newIndex);
		this.setState(this.state);
	};

	onStepSortEnd = ({oldIndex, newIndex}) => {
		this.steps = arrayMove(this.steps, oldIndex, newIndex);
		this.setState(this.state);
	};

	handleIngredientUpdated = (ingredient, index, updateState=false) => {
		this.ingredients[index] = Object.assign({}, this.ingredients[index], ingredient);
		if (updateState)
			this.setState(this.state);
	}

	handleStepUpdated = (step, index) => {
		this.steps[index] = step;
	}

	handleAddIngredient = () => {
		this.ingredients = this.ingredients.concat([{}]);
		this.setState(this.state);
	}

	handleAddStep = () => {
		this.steps.push('');
		this.setState(this.state);
	}

	handleRemoveIngredient = index => {
		const ingredients = this.ingredients;
		ingredients.splice(index, 1);
		const product = this.state.product;
		product.ingredients = ingredients;
		this.setState({ product });
	}

	handleRemoveStep = index => {
		const steps = this.steps;
		steps.splice(index, 1);
		const product = this.state.product;
		product.steps = steps;
		this.setState({ product });
	}

	tagClicked = (tag, isSelected) => {
		const product = this.state.product;
		if (isSelected) {
			product.tags.push(tag.id);
		}
		else {
			const index = product.tags.indexOf(tag.id);
			if (index > -1)
				product.tags.splice(index, 1);
		}

		this.setState({
			product: product,
		})
	}

	handleSave = values => {
		this.setState({
			formSubmitting: true,
		});

		// sanitize empty values
		const ingredients = this.ingredients.filter(ingredient => ingredient.ingredient);
		const steps = this.steps.filter(step => '' !== step);

		// sanitize measures made empty (set as string)
		ingredients.map(ingredient => '' === ingredient.measure ? ingredient.measure = null : ingredient.measure);

		const escandall = Object.assign({}, this.state.product, values, { ingredients, steps });

		// create
		if (this.isNew) {
			productService.createEscandall(escandall)
				.then(result => {
					const resultEscandall = JSON.parse(result);
					this.props.handleCreatedEscandall(resultEscandall);
				})
				.catch(error => {
					this.setState({
						formSubmitting: false,
					});

					if (error) {
						if ('ingredients' in error) {
							if (JSON.stringify(error['ingredients'][0]).includes('escandall_cycle'))
								alert(this.props.t('escandall_form_cycle_error'));
							else if (JSON.stringify(error['ingredients'][0]).includes('A valid number is required.'))
								alert(this.props.t('escandall_form_ingredient_measure_not_number'));
						}
						else if ('units' in error) {
							if (error['units'][0].includes('product_under_use'))
								alert(this.props.t('escandall_form_under_use_error'));
						}
					}
				});
		}

		// edit
		else {
			const product = Object.assign({}, this.state.product, escandall)
			productService.saveEscandall(product)
				.then(result => {
					const resultEscandall = JSON.parse(result);
					this.props.handleEditedEscandall(resultEscandall);
				})
				.catch(error => {
					this.setState({
						formSubmitting: false,
					});

					if (error) {
						if ('ingredients' in error) {
							if (JSON.stringify(error['ingredients'][0]).includes('escandall_cycle'))
								alert(this.props.t('escandall_form_cycle_error'));
							else if (JSON.stringify(error['ingredients'][0]).includes('A valid number is required.'))
								alert(this.props.t('escandall_form_ingredient_measure_not_number'));
						}
						else if ('units' in error) {
							if (error['units'][0].includes('product_under_use'))
								alert(this.props.t('escandall_form_under_use_error'));
						}
					}
				});
		}
	}

	getRenderedTableRow = (ingredient, index) => {
		return <EscandallIngredientForm
			ingredient={ingredient}
			key={`esc-form-ingr-row-${index}`}
            index={index}
            rowIndex={index}
            handleIngredientUpdated={this.handleIngredientUpdated}
            handleIngredientRemoved={this.handleRemoveIngredient}
		/>
	}

	getRenderedIngredientsTable = () => {
		const SortableTable = SortableContainer(() => {
			return (
				<Table className="ml-4 mb-0">
					<thead>
						<tr>
							<th style={{ borderTop: 0 }}></th>
							<th style={{ borderTop: 0 }}>{this.props.t('escandall_form_ingredient_name')}</th>
							<th style={{ borderTop: 0 }}>{this.props.t('escandall_form_ingredient_notes')}</th>
							<th style={{ borderTop: 0 }}>{this.props.t('escandall_form_ingredient_measure')}</th>
							<th style={{ borderTop: 0 }}>{this.props.t('escandall_form_ingredient_units')}</th>
							<th style={{ borderTop: 0 }}></th>
						</tr>
					</thead>
					<tbody>
						{this.ingredients.map(this.getRenderedTableRow)}
					</tbody>
				</Table>
			);
		});

		return <SortableTable useDragHandle lockAxis='y' items={this.ingredients} onSortEnd={this.onIngredientSortEnd}/>;
	}

	getRenderedStepRow = (step, index) => {
		return <EscandallStepForm
			key={`esc-form-step-row-${index}`}
			index={index}
			value={{ step, index }}
			handleStepUpdated={this.handleStepUpdated}
			handleStepRemoved={this.handleRemoveStep}
		/>
	}

	getRenderedStepsTable = () => {
		const SortableTable = SortableContainer(() => {
			return (
				<Table className="ml-4  mb-0">
					<tbody>
						{this.steps.map(this.getRenderedStepRow)}
					</tbody>
				</Table>
			);
		});

		return <SortableTable useDragHandle lockAxis='y' items={this.steps} onSortEnd={this.onStepSortEnd}/>;
	}

	getRenderedTagsTable = () => {
		return userService.getCheckInRestaurantInfo().restaurant.tags.map(tag => {
			const tagStyle = {
				backgroundColor: tag.color,
				color: tag.text_color
			};
			return (
				<Row key={`tag-form-row${tag.id}`} style={{ marginLeft: '35px', marginBottom: '20px' }}>
					<Form.Check
						key={`checkbox-tag-${tag.id}`}
						id={`checkbox-tag-${tag.id}`}
						type='checkbox'
						checked={this.state.product.tags.includes(tag.id)}
						label=''
						onChange={event => this.tagClicked(tag, event.target.checked)}
						style={{ lineHeight: '1' }}
					>
					</Form.Check>
					<Badge style={tagStyle}>
						{tag.name}
					</Badge>
				</Row>
			)
		});
	}

	render() {
		const modalTitle = `escandall_form_${this.props.isNew ? 'creating' : 'editing'}_${this.props.isBase ? 'prep' : 'recipe'}_title`;

		const formSchema = yup.object({
			name: yup.string().required('form_required_field'),
			measure: yup.number().min(0).required('form_required_field'),
			units: yup.string().required('form_required_field').notOneOf(['']),
		});

		const initialValues = {
			name: this.state.product.name,
			measure: this.state.product.measure,
			units: this.state.product.units
		};
		initialValues.shrinkage = initialValues.shrinkage * 100;
		return (
			<Formik
				validationSchema={formSchema}
				initialValues={initialValues}
				validateOnMount={true}
			>
				{({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
					<Modal show={true} centered onHide={this.props.handleCloseEdit} size='lg' dialogClassName="modal-90w">
						<Modal.Header closeButton>
							<Modal.Title>{this.props.t(modalTitle)}</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Container>
								<Row>
									<Col>
										<Row>
											<Col className='property-form'>
												<Form.Label className='property-header'>{this.props.t('escandall_form_name')}</Form.Label>
												<Form.Control
													type='text'
													name='name'
													value={values.name}
													onChange={handleChange}
													isInvalid={errors.name}
													onBlur={handleBlur}
												/>
											</Col>
										</Row>
										<Row>
											<Col className='property-form'>
												<Form.Label className='property-header'>{this.props.t('escandall_form_measure')}</Form.Label>
												<OverlayTrigger placement='right' overlay={<Tooltip id='escandall-measure-tooltip'>{this.props.t('escandall_form_measure_tooltip')}</Tooltip>}>
													<FontAwesomeIcon icon="info-circle"/>
												</OverlayTrigger>
												<Form.Control
													name='measure'
													value={values.measure}
													onChange={handleChange}
													isInvalid={errors.measure}
												/>
											</Col>
											<Col className='property-form'>
												<Form.Label className='property-header'>{this.props.t('escandall_form_units')}</Form.Label>
												<OverlayTrigger placement='right' overlay={<Tooltip id='escandall-units-tooltip'>{this.props.t('escandall_form_units_tooltip')}</Tooltip>}>
													<FontAwesomeIcon icon="info-circle"/>
												</OverlayTrigger>
												<Form.Control
													name='units'
													value={this.isNew && !touched.units ? undefined : values.units}
													as="select"
													onChange={handleChange}
													isInvalid={errors.units}
												>
													<option key='selectHint' hidden value></option>
													{productService.SystemUnits().map(unit => <option key={`esc-form-units-choose-${unit}`} value={unit}>{this.props.t(`unit_${unit}`)}</option>)}
												</Form.Control>
											</Col>
										</Row>
									</Col>
									<Col>
										Photo
									</Col>
								</Row>
								<Row>
									<Col>
										<p className='section-header'>{this.props.t('escandall_form_ingredients')}</p>
										{0 !== this.ingredients.length && this.getRenderedIngredientsTable()}
										<Button variant="secondary" style={{ marginLeft: '50px' }} onClick={this.handleAddIngredient}>{this.props.t('escandall_form_add_ingredient')}</Button>
									</Col>
								</Row>
								<Row>
									<Col>
										<p className='section-header'>{this.props.t('escandall_form_procedure')}</p>
										{this.getRenderedStepsTable()}
										<Button variant="secondary" style={{ marginLeft: '50px' }} onClick={this.handleAddStep}>{this.props.t('escandall_form_add_step')}</Button>
									</Col>
								</Row>
								<Row>
									<Col>
										<p className='section-header'>{this.props.t('escandall_form_tags')}</p>
										<Col>
											{this.getRenderedTagsTable()}
										</Col>
									</Col>
								</Row>
							</Container>
						</Modal.Body>
						<Modal.Footer>
							<SubmissionContainer loading={this.state.formSubmitting}>
								<Button variant="primary" disabled={!isValid} onClick={isValid ? () => this.handleSave(values) : undefined}>
									{this.props.t('escandall_form_save')}
								</Button>
								<Button variant="outline-secondary" onClick={this.props.handleCloseEdit}>
									{this.props.t('escandall_form_close')}
								</Button>
							</SubmissionContainer>
						</Modal.Footer>
					</Modal>
				)}
			</Formik>
		);
	}
}

export default withTranslation()(EscandallForm);
