import React from "react";

import { Row, Col, Form, Container, Button, Modal } from 'react-bootstrap'
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as yup from 'yup';

import restaurantService from '../../services/RestaurantService';
import userService from '../../services/UserService';
import SubmissionContainer from '../../components/SubmissionContainer';


/**
 *  This class represents the modal that opens up to create a new restaurant
 */
class RestaurantForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			formSubmitting: false,
		};
	}

	handleSave = values => {
		this.setState({
			formSubmitting: true,
		});

		restaurantService.createRestaurant(values)
			.then(result => {
				return userService._forceGetUserInfo()
					.then(() => {
						return this.props.createdRestaurant(JSON.parse(result).id);
					});
			})
			.catch(error => {
				this.setState({
					formSubmitting: false,
				});
			});
	}

	render() {
		const formSchema = yup.object({
			name: yup.string().required('form_required_field'),
		});

		return (
			<Formik validationSchema={formSchema} initialValues={{ name: '' }}>
				{({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
					<Modal show={true} centered onHide={this.props.handleCloseEdit} size='md' dialogClassName='restaurant-modal'>
						<Modal.Header closeButton>
							{this.props.t('restaurant_form_title')}
						</Modal.Header>
						<Modal.Body>
							<Container>
								<Row>
									<Col className='property-form'>
										<p className='property-header'>{this.props.t('restaurant_form_name')}</p>
										<Form.Control
											type='text'
											name='name'
											placeholder={this.props.t('restaurant_form_name_placeholder')}
											value={values.name || undefined}
											onChange={handleChange}
											isInvalid={errors.name}
											onBlur={handleBlur}
										/>
										<Form.Control.Feedback type="invalid">
											{this.props.t(errors.name)}
										</Form.Control.Feedback>
									</Col>
								</Row>
							</Container>
						</Modal.Body>
						<Modal.Footer>
							<SubmissionContainer loading={this.state.formSubmitting}>
								<Button variant="primary" disabled={!isValid} onClick={isValid ? () => this.handleSave(values) : undefined}>
									{this.props.t('restaurant_form_save')}
								</Button>
								<Button variant="outline-secondary" onClick={this.props.handleCloseEdit}>
									{this.props.t('restaurant_form_close')}
								</Button>
							</SubmissionContainer>
						</Modal.Footer>
					</Modal>
				)}
			</Formik>
		);
	}
}

export default withTranslation()(withRouter(RestaurantForm));
