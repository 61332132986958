import React from 'react';

import userService from '../../services/UserService';
import SubmissionContainer from '../../components/SubmissionContainer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Button, OverlayTrigger, Tooltip, Container, Col, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as yup from 'yup';


class UserConfiguration extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			error: '',
			formSubmitting: false,
			name: userService._getLocalUserInfo().first_name,
			locale: userService.getLocale()
		}
	}

	handleSubmitPassword = values => {
		this.setState({
			formSubmitting: true,
		});
		userService.changePassword(values.password1, values.password2)
			.then(() => {
				this.setState({
					formSubmitting: false,
				});
			}).catch(result => {
				this.setState({
					formSubmitting: false,
				});
				if (result) {
					if ('password1' in result) {
						if (result['password1'].includes('This password is too common.'))
							this.setState({ error: 'user_configuration_error_common_password' });
						else
							this.setState({ error: 'user_configuration_error_unknown_password_error' });
					}
				}
			});
	}

	handleSubmitBasicInfo = () => {
		this.setState({
			formSubmitting: true,
		});
		userService.updateUser(this.state.name, this.state.locale)
			.then(() => {
				window.location.reload();
			}).catch(result => {
				this.setState({
					formSubmitting: false,
				});
			});
	}

	handleNameChange = event => {
		this.setState({ name: event.target.value });
	}

	handleLocaleChange = event => {
		this.setState({ locale: event.target.value });
	}

	render () {
		const formSchema = yup.object({
			password1: yup.string().min(8, 'form_password_length').matches(/(?!^\d+$)^.+$/, 'form_numeric_password').required('form_required_field'),
			password2: yup.string().required('form_required_field').oneOf([yup.ref('password1')], 'form_passwords_dont_match')
		});

		const userInfo = userService._getLocalUserInfo();

		return (
			<Container className='pt-4 pb-4'>
				<Row>
					<Col xs={4} style={{ marginTop: '70px' }}>
						<p style={{ color: '#D4E056', textAlign: 'right', fontWeight: 800, marginBottom: '5px' }}>{userInfo.first_name}</p>
						<p style={{ textAlign: 'right' }}>{userInfo.email}</p>
					</Col>
					<Col xs={4} className='ml-4'>
						<h2 style={{ marginBottom: '40px' }}>{this.props.t('user_configuration_title')}</h2>
						<Row style={{ display: 'block', paddingTop: '10px', paddingLeft: '10px' }}>
							<h5>{this.props.t('user_configuration_change_basic_info')}</h5>
							<Form.Group controlId="change-language-form">
								<Form.Label>{this.props.t('user_configuration_change_name')}</Form.Label>
								<Form.Control
									name="firstNname"
									placeholder={this.props.t('user_configuration_first_name_placeholder')}
									value={this.state.name}
									onChange={this.handleNameChange}
								/>
								<Form.Label>{this.props.t('user_configuration_change_language')}</Form.Label>
								<Form.Control
									name='language'
									value={this.state.locale}
									as="select"
									onChange={this.handleLocaleChange}
								>
									<option key={`language-option-ca`} value='ca-ES'>Català</option>
									<option key={`language-option-en`} value='en-US'>English</option>
									<option key={`language-option-es`} value='es-ES'>Español</option>
								</Form.Control>
							</Form.Group>
							<SubmissionContainer loading={this.state.formSubmitting} style={{ justifyContent: 'left' }}>
								<Button variant="primary" type="submit" onClick={this.handleSubmitBasicInfo}>
									{this.props.t('user_configuration_change_basic_info_save')}
								</Button>
								<div className="error">{this.props.t(this.state.error)}</div>
							</SubmissionContainer>
						</Row>
						<Row style={{ display: 'block', paddingTop: '40px', paddingLeft: '10px' }}>
							<h5>{this.props.t('user_configuration_change_password_title')}</h5>
							<Formik validationSchema={formSchema} initialValues={{ password1: '', password2: '' }}>
								{({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
									<div>
										<Form.Group controlId="change-password-form">
											<Form.Label>{this.props.t('user_configuration_change_password_password_title')}</Form.Label>
											<OverlayTrigger overlay={<Tooltip id='password-info-tooltip'>{this.props.t('user_configuration_password_info')}</Tooltip>}>
												<FontAwesomeIcon icon="info-circle"/>
											</OverlayTrigger>
											<Form.Control
												autoFocus
												type="password"
												name="password1"
												placeholder={this.props.t('user_configuration_change_password_password_placeholder')}
												value={values.password1}
												onChange={handleChange}
												isInvalid={errors.password1}
												onBlur={handleBlur}
											/>
											<Form.Control.Feedback type="invalid">
												{this.props.t(errors.password1)}
											</Form.Control.Feedback>
										</Form.Group>
										<Form.Group controlId="formBasicPassword">
											<Form.Label>{this.props.t('user_configuration_change_password_repeat_password_title')}</Form.Label>
											<Form.Control
												type="password"
												name="password2"
												placeholder={this.props.t('user_configuration_change_password_repeat_password_placeholder')}
												value={values.password2}
												onChange={handleChange}
												isInvalid={errors.password2}
												onBlur={handleBlur}
											/>
											<Form.Control.Feedback type="invalid">
												{this.props.t(errors.password2)}
											</Form.Control.Feedback>
										</Form.Group>
										<SubmissionContainer loading={this.state.formSubmitting} style={{ justifyContent: 'left' }}>
											<Button variant="primary" type="submit" disabled={!isValid} onClick={isValid ? () => this.handleSubmitPassword(values) : undefined}>
												{this.props.t('user_configuration_change_password_save')}
											</Button>
											<div className="error">{this.props.t(this.state.error)}</div>
										</SubmissionContainer>
									</div>
								)}
							</Formik>
						</Row>
					</Col>
				</Row>
			</Container>
		);
	}
}

export default withTranslation()(UserConfiguration);
