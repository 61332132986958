import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';

import React from 'react';


/**
 *  This represents a step row when editing an escandall, this was decoupled because it needs to be declared out of
 *. class-component scope, and cluttered the class file
 */
const DragHandle = SortableHandle(() => <span><FontAwesomeIcon icon="grip-lines"/></span>);

class EscandallStepForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			step: this.props.value.step
		}
	}

	handleStepUpdated = event => {
		this.props.handleStepUpdated(event.target.value, this.props.value.index)
		this.setState({ step: event.target.value });
	}

	render() {
		return (
			<tr style={{ zIndex: 9999 }}>
				<td style={{ paddingRight: 0, borderTop: 0 }}>
					<DragHandle/>
				</td>
				<td style={{ borderTop: 0 }}>
					<InputGroup>
						<InputGroup.Prepend>
							<InputGroup.Text>{this.props.value.index + 1}</InputGroup.Text>
						</InputGroup.Prepend>
						<Form.Control as='textarea'
							type='text'
							value={this.state.step}
							onChange={this.handleStepUpdated}
						/>
					</InputGroup>
				</td>
				<td style={{ borderTop: 0 }}>
					<Button variant="outline-destructive" onClick={() => this.props.handleStepRemoved(this.props.value.index)}>
						<FontAwesomeIcon icon="ban"/>
					</Button>
				</td>
			</tr>
		);
	}
}

export default withTranslation()(SortableElement(EscandallStepForm));
