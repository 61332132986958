import React from "react";
import moment from 'moment';

import { Row, Col, Form, ListGroup, Container, Button, InputGroup, Spinner, Badge, Modal, Card } from 'react-bootstrap'
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import allergenService from '../../services/AllergenService';
import productService from '../../services/ProductService';
import userService from '../../services/UserService';
import ProductParser from '../../utils/ProductParser';
import EscandallIngredients from './EscandallIngredients'
import EscandallForm from './EscandallForm'
import SubmissionContainer from '../../components/SubmissionContainer';

import '../../styles/scenes/escandalls/EscandallsScene.css';


/**
 *  This class represents the display of the list of escandalls and a side escandall detail
 */

 const EditingAction = Object.freeze({
	editing: 'editing',
	creating: 'creating'
});

let timeout = null
class EscandallsScene extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			products: [],
			orderBy: "name",
			orderDirection: "asc",
			loadingProducts: true,
			loadingProduct: false,
			page: 1,
			pages: null,
			searchText: '',

			displayedProductIndex: undefined,
			displayedProduct: null,
			customMeasure: null,

			editing: undefined,
			deletingProduct: false,

			formSubmitting: false,
		};

		this.customMeasureChanged = this.customMeasureChanged.bind(this);
		this.checkedInRestaurant = undefined !== userService.getCheckInRestaurantInfo();
	}

	getEscandalls = () => {
		let params = { type: 'escandall', page: this.state.page }

		params['is_base'] = this.props.is_base
		params['ordering'] = (this.state.orderDirection === 'asc' ? '' : '-') + this.state.orderBy
		params['name'] = this.state.searchText

		productService.getProducts(params).then(result => {
			result = JSON.parse(result);
			this.setState({
				products: this.state.products.concat(result.results),
				loadingProducts: false,
				pages: Math.ceil(result.count/50),
			});
		});
	}

	getEscandall = id => {
		productService.getProduct(id).then(result => {
			const escandall = JSON.parse(result);
			this.setState({
				products: [escandall],
				customMeasure: escandall.measure,
				displayedProduct: escandall,
				displayedProductIndex: 0,
				loadingProduct: false,
				loadingProducts: false,
				pages: Math.ceil(result.count/50),
			});
		});
	}

	// ???
	componentDidUpdate(prevProps, prevState) {
	if (
		this.checkedInRestaurant &&
		(this.state.orderBy !== prevState.orderBy ||
		this.state.orderDirection !== prevState.orderDirection ||
		this.state.page !== prevState.page ||
		this.state.searchText !== prevState.searchText)) {
			this.getEscandalls();
		}
	}

	componentDidMount() {
		if (this.checkedInRestaurant) {
			const id = parseInt(this.props.match.params.id);
			isNaN(id) ? this.getEscandalls() : this.getEscandall(id);
		}
		document.getElementById('content').addEventListener('scroll', this.onScroll);
	}

	componentWillUnmount() {
		document.getElementById('content').removeEventListener('scroll', this.onScroll);
	}

	sortTable = (key) => {
		if (key === this.state.orderBy) {
			if (this.state.orderDirection === 'asc') {
				this.setState({
					orderDirection: 'desc',
					products: [],
					page: 1
				})
			}
			else {
				this.setState({
					orderDirection: 'asc',
					products: [],
					page: 1
				})
			}
		}
		else {
			this.setState({
				orderDirection: 'asc',
				orderBy: key,
				products: [],
				page: 1
			})
		}
	}

	onSearchChange = (event) => {
		if (timeout)
			clearTimeout(timeout);

		event.persist();
		timeout = setTimeout(() => {
			this.setState({
				searchText: event.target.value,
				displayedProductIndex: undefined,
				products: [],
				page: 1
			});
		}, 500);
	}

	onEditClicked = () => {
		this.setState({
			editing: EditingAction.editing
		});
	}

	onNewClicked = () => {
		this.setState({
			editing: EditingAction.creating
		});
	}

	onDeleteClicked = () => {
		this.setState({
			formSubmitting: true,
		});

		productService.deleteProduct(this.state.displayedProduct.id)
			.then((data) => {
				this.state.products.splice(this.state.displayedProductIndex, 1);

				this.setState({
					deletingProduct: false,
					products: this.state.products,
					displayedProductIndex: undefined,
					displayedProduct: null
				})
			})
			.catch((error) => {
				this.setState({
					formSubmitting: false,
				});
				for (let e of error.errors) {
					if(e.error_code === "product_under_use") {
						alert("No puedes borrar este producto porque esta siendo usado como ingrediente.\n\nProducto usado en:\n\n" + e.escandalls_using.join(', '))
					}
				}
			});
	}

	displayEscandall = escandallsIndex => {
		this.setState({
			displayedProductIndex: escandallsIndex,
			customMeasure: this.state.products[escandallsIndex].measure,
			loadingProduct: true,
		});

		productService.getProduct(this.state.products[escandallsIndex].id)
			.then(result => {
				result = JSON.parse(result)
				this.setState({
					displayedProduct: result,
					loadingProduct: false,
				});
			});
	}

	handleCreatedEscandall = escandall => {
		const products = this.state.products;
		products.unshift(escandall);
		this.setState(
			{
				products,
				editing: undefined,
				displayedProduct: escandall,
				displayedProductIndex: 0,
				customMeasure: escandall.measure,
			},
			// () => this.displayEscandall(0)
		);
	}

	handleEditedEscandall = escandall => {
		const products = this.state.products;
		products[this.state.displayedProductIndex] = escandall;
		this.setState(
			{
				products,
				editing: undefined,
				displayedProduct: escandall,
			},
			// unneeded because it was already being displayed at that position
			// () => this.displayEscandall(displayedProductIndex)
		);
	}

	customMeasureChanged = event => {
		const value = event.target.value;
		this.setState({
			customMeasure: value
		});
	}

	handleCloseEdit = () => {
		this.setState({
			editing: undefined
		});
	}

	getRenderedInfoItem = (title, content) => (
		<div>
			<p className='property-header'>{title}</p>
			<p className='property-content'>{content}</p>
		</div>
	);

	getRenderedAllergens = allergens => {
		const allergenIconStyle = {
			width: '50px',
			height: '50px',
			margin: '5px',
		}

		if (allergens && allergens.length > 0) {
			return allergens.map(allergen =>
				<img key={`esc-allergen-display-${allergen}`} style={allergenIconStyle} src={allergenService.getAllergen(allergen)} alt={this.props.t(`allergen_${allergen}`)} title={this.props.t(`allergen_${allergen}`)}/>,
			);
		}
	}

	getRenderedTags = tagIds =>
		tagIds.map(tagId => {
			const tag = userService.getTag(tagId);
			if (tag)
				return <Badge key={`esc-row-tag-${tagId}`} variant='primary' className='mr-1' style={{color: tag.text_color, backgroundColor: tag.color}}>{tag.name}</Badge>
			return null;
		});

	getRenderedDetail = () => {
		if (!this.checkedInRestaurant)
			return (
				<Card className='text-center'>
					<Card.Body>
						<Card.Text>
							{this.props.t('escandall_scene_no_restaurant_text')}
						</Card.Text>
						<Button variant="primary" onClick={() => this.props.history.push('/restaurants')}>{this.props.t('escandall_scene_no_restaurant_button')}</Button>
					</Card.Body>
				</Card>
			);

		if (this.state.displayedProductIndex !== undefined) {
			const product = this.state.products[this.state.displayedProductIndex];
			const productParser = new ProductParser(product);
			productParser.setCustomMeasure(this.state.customMeasure);

			let Image;
			if (product.photo) {
				let imageSrc = product.photo;
				if (window.location.hostname === 'localhost')
					imageSrc = `//localhost:8000${imageSrc}`;
				Image = <img className='.img-thumbnail' src={imageSrc} alt={product.name}/>;
			}

			let LoadingSpinner;
			if (this.state.loadingProduct)
				LoadingSpinner = (
					<Spinner animation="border" role="status">
						<span className="sr-only"></span>
					</Spinner>
				);

			let Ingredients;
			let Steps;
			if (!this.state.loadingProduct) {
				Ingredients = (
					<Row>
						<Col>
							<h5 className="text-left">{this.props.t('escandall_scene_ingredients')}</h5>
							<EscandallIngredients
								ingredients={this.state.displayedProduct.ingredients}
								customMeasure={this.state.customMeasure}
							/>
						</Col>
					</Row>
				);

				let StepsContent = <span>{this.props.t('escandall_scene_procedure_undefined')}</span>;
				if (this.state.displayedProduct.steps && this.state.displayedProduct.steps.length) {
					const StepRows = this.state.displayedProduct.steps.map((step, i) =>
						<ListGroup.Item className="text-left ml-2" key={i}>{i + 1}. {step}</ListGroup.Item>);

					StepsContent = (
						<ListGroup variant="flush">
							{StepRows}
						</ListGroup>
					);
				}

				Steps = (
					<Row>
						<Col>
							<h5 className="text-left mt-4">{this.props.t('escandall_scene_procedure')}</h5>
							{StepsContent}
						</Col>
					</Row>
				);
			}

			return (
				<Container>
					<Row>
						<Col>
							<h3 className='text-left mb-4 mt-3'>{product.name}</h3>
						</Col>
					</Row>
					<Row className='ml-4 mb-4'>
						<Col>
							<p className='property-header' style={{ marginBottom: '5px' }}>{this.props.t('escandall_scene_measure')}</p>
							<InputGroup className="mb-3">
								<Form.Control
									className='col-lg-4'
									type='number'
									min='0'
									step='0.00001'
									value={productParser.getCustomMeasure()}
									onChange={this.customMeasureChanged}
								/>
								<InputGroup.Append>
								<InputGroup.Text id="basic-addon2">{product.units}</InputGroup.Text>
								</InputGroup.Append>
							</InputGroup>
							{this.getRenderedInfoItem(this.props.t('escandall_scene_price'), productParser.price())}
							{this.getRenderedInfoItem(this.props.t('escandall_scene_net_cost'), productParser.netCost())}
						</Col>
						<Col className='text-left'>
							<p className='property-header'>{this.props.t('escandall_scene_allergens')}</p>
							{this.getRenderedAllergens(product.contains_allergens)}
							<p className='property-header'>{this.props.t('escandall_scene_tags')}</p>
							{this.getRenderedTags(product.tags)}
						</Col>
						<Col>
							{Image}
						</Col>
					</Row>
					{LoadingSpinner}
					{Ingredients}
					{Steps}
					<Row>
						<Col>
							<p className="float-left mb-0" style={{ color: '#dadada', position: 'absolute', bottom: 0 }}>{`Última actualització: ${moment(product.last_updated).format('DD MMMM YYYY, kk:mm')}`}</p>
							<div className="float-right">
								<Button variant="outline-secondary" className="mr-2" disabled={this.state.loadingProduct} onClick={this.onEditClicked}>{this.props.t('escandall_scene_edit')}</Button>
								<Button variant="outline-destructive" onClick={() => this.setState({ deletingProduct: product })}>{this.props.t('escandall_scene_delete')}</Button>
							</div>
						</Col>
					</Row>
				</Container>
			);
		}
	}

	getRenderedRows = () => {
		const renderedRows = this.state.products.map((product, i) => {
			const productParser = new ProductParser(product);
			// FIXME WIDTH 100% nomes hauria de ser si multiline... xD
			// if nom = tal qual
			// if nom + price = 2 rows
			// if nom + price + tags = 1 row, 1 row (2 cols)
			return (<ListGroup.Item className="text-left" active={this.state.displayedProductIndex === i} key={`escandall-display-row-${i}`} action onClick={() => this.displayEscandall(i)}>
					<span style={{ width: '100%' }} className='mb-1 float-left'>{product.name}</span>
					<span className='mb-1 float-right'>{productParser.netCost()}</span>
					<p className='mb-1'>{this.getRenderedTags(product.tags)}</p>
				</ListGroup.Item>);
			}
		);
		return renderedRows;
	}

	getRenderedEditionModal = () => {
		if (this.state.editing) {
			const isNew = EditingAction.creating === this.state.editing;
			return (
				<EscandallForm
					product={isNew ? undefined : productService.deepCopyProduct(this.state.displayedProduct)}
					isNew={isNew}
					isBase={this.props.is_base}
					handleCloseEdit={this.handleCloseEdit}
					handleCreatedEscandall={this.handleCreatedEscandall}
					handleEditedEscandall={this.handleEditedEscandall}
				/>
			);
		}
	}

	getRenderedDeletingModal = () => {
		if (this.state.deletingProduct) {
			return (
				<Modal show={true} onHide={() => this.setState({ deletingProduct: null })}>
					<Modal.Header closeButton>
						<Modal.Title>{this.props.t('escandall_scene_deleting_title')}</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<p>{this.props.t('escandall_scene_deleting_text')}{this.state.deletingProduct.name}?</p>
					</Modal.Body>

					<Modal.Footer>
						<SubmissionContainer loading={this.state.formSubmitting}>
							<Button variant="outline-secondary" onClick={() => this.setState({ deletingProduct: null })}>{this.props.t('escandall_scene_deleting_cancel')}</Button>
							<Button variant="destructive" onClick={this.onDeleteClicked}>{this.props.t('escandall_scene_deleting_delete')}</Button>
						</SubmissionContainer>
					</Modal.Footer>
				</Modal>
			);
		}
	}

	render() {
		return (
			<Row className='p-0 m-0' style={{ height: '100%' }}>
				<Col xs={4} className='border-right' style={{ height: '100%' }}>
					<div className='listContainer'>
						<div>
							<Form className="mt-4">
								<Form.Control type="text" placeholder={this.props.t('escandall_scene_search')} autoFocus onChange={this.onSearchChange} />
							</Form>
						</div>
						<div>
							<ListGroup variant="flush">
								{this.getRenderedRows()}
							</ListGroup>
						</div>
					</div>
					<Button variant="primary" onClick={this.onNewClicked} style={{ height: '59px', width: '100%', position: 'absolute', left: 0, bottom: 0, borderRadius: 0 }}>
						<span style={{ textTransform: 'uppercase', fontWeight: '800' }}>{this.props.t('escandall_scene_new')}</span>
						<FontAwesomeIcon style={{ marginLeft: '10px' }} icon="plus"/>
					</Button>
				</Col>
				<Col xs={8} id="detail" className="pt-4">
					{this.getRenderedDetail()}
					{this.getRenderedEditionModal()}
					{this.getRenderedDeletingModal()}
				</Col>
			</Row>
		);
	}
}

export default withTranslation()(withRouter(EscandallsScene));
