import i18n from "i18next";


export default class ProductParser {
	constructor(product) {
		this.product = product;
		this.customMeasure = this.product.measure;
	}

	setCustomMeasure = customMeasure => {
		this.customMeasure = customMeasure;
	}

	getCustomMeasure = () => {
		return this.customMeasure;
	}

	measure = (displayUnits=true) => {
		if (typeof(this.product.measure) !== 'number')
			return '-';
		let result = this.product.measure.toFixed(2);
		if (displayUnits)
			result += ' ' + i18n.t(`unit_${this.product.units}`);
		return result;
	}

	shrinkage = (displayUnits=true) => {
		let result = this.product.shrinkage * 100;
		result = result.toFixed(2).replace(/\.00$/, '');
		if (displayUnits)
			result += '%';
		return result;
	}

	netCost = (displayUnits=true) => {
		if (!(this.product.net_cost || this.product.net_cost === 0))
			return '-';
		let result = this.product.net_cost.toFixed(2).replace(/\.00$/, '');
		if (displayUnits)
			result += ' €/' + i18n.t(`unit_${this.product.units}`);
		return result;
	}

	grossCost = (displayUnits=true) => {
		if (!(this.product.gross_cost || this.product.gross_cost === 0))
			return '-';
		let result = this.product.gross_cost.toFixed(2).replace(/\.00$/, '');
		if (displayUnits)
			result += ' €/' + i18n.t(`unit_${this.product.units}`);
		return result;
	}

	price = (displayUnits=true) => {
		let result = '';
		if (this.product.type === 'product') {
			if (typeof(this.product.price) !== 'number')
				return '-';
			result = this.product.price;
		}
		else {
			if (typeof(this.product.net_cost) !== 'number')
				return '-';
			result = this.product.net_cost * this.customMeasure;
		}

		result = result.toFixed(2).replace(/\.00$/, '');
		if (displayUnits)
			result += ' €';
		return result;
	}
}
