import React from 'react';

import { Container, Col, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';


class Privacy extends React.Component {
	render () {
		return (
			<Container>
				<Row>
					<Col xs={4} className='mr-4' style={{ marginTop: '70px' }}>
						<p style={{ color: '#D4E056', textAlign: 'right', fontWeight: 800, marginBottom: '5px', fontSize: '36px' }}>{this.props.t('privacy_title')}</p>
					</Col>
					<Col xs={7} style={{ marginTop: '70px', whiteSpace: 'pre-line' }}>
						{this.props.t('privacy_content')}
					</Col>
				</Row>
			</Container>
		);
	}
};

export default withTranslation()(withRouter(Privacy));
