export default {
	terms_title: 'Termes de servei',
	terms_content: `
		1. Termes

		En accedir al lloc web a https://cuintab.com, accepteu estar obligat per aquestes condicions del servei, totes les lleis i normatives aplicables i accepteu que sou responsable del compliment de les lleis locals aplicables. Si no esteu d'acord amb cap d'aquests termes, se us prohibeix l'ús o l'accés a aquest lloc. Els materials continguts en aquest lloc web estan protegits per la legislació aplicable sobre drets d'autor i marques comercials.

		2. Utilitzeu la llicència

		Es concedeix el permís per descarregar temporalment una còpia del material (informació o programari) al lloc web de Cuintab només per a la visualització transitòria personal i no comercial. Aquesta és la concessió d’una llicència, no una transferència de títol, i segons aquesta llicència no podeu:
		        - modificar o copiar els materials;
		        - utilitzar els materials per a qualsevol propòsit comercial o per a qualsevol exposició pública (comercial o no comercial);
		        - intentar descompilar o fer enginyeria inversa qualsevol programari contingut al lloc web de Cuintab;
		        - eliminar els drets d'autor o altres notacions de propietat dels materials; o bé
		        - transferir el material a una altra persona o "reflectir" el material en qualsevol altre servidor.
		Aquesta llicència finalitzarà automàticament si infringiu alguna d'aquestes restriccions i Cuintab pot rescindir-la en qualsevol moment. En finalitzar la visualització d’aquests materials o en finalitzar aquesta llicència, haureu de destruir qualsevol material descarregat que tingueu, ja sigui en format electrònic o imprès.


		3. Exempció de responsabilitat

		El material del lloc web de Cuintab es proporciona "tal qual". Cuintab no ofereix cap garantia, expressa o implícita, i per això renuncia i nega totes les altres garanties, incloses, sense limitacions, garanties implícites o condicions de comercialització, adequació per a un propòsit particular o no infracció de la propietat intel·lectual o qualsevol altra violació de drets.
		A més, Cuintab no garanteix ni fa cap representació sobre la precisió, els resultats probables o la fiabilitat de l’ús dels materials al seu lloc web o relacionats amb aquests materials o en qualsevol dels llocs enllaçats a aquest lloc.


		4. Limitacions

		En cap cas, Cuintab ni els seus proveïdors seran responsables de cap dany (inclosos, sense limitacions, danys per pèrdua de dades o guanys o per interrupció empresarial) derivat de l’ús o la impossibilitat d’utilitzar els materials del lloc web de Cuintab, fins i tot si Cuintab o un representant autoritzat de Cuintab han estat avisats oralment o per escrit de la possibilitat d’aquest dany. Com que algunes jurisdiccions no permeten limitacions de garanties implícites, ni limitacions de responsabilitat per danys derivats o incidentals, és possible que aquestes limitacions no s'apliquin a vosaltres.

		5. Precisió dels materials

		Els materials que apareixen al lloc web de Cuintab poden incloure errors tècnics, tipogràfics o fotogràfics. Cuintab no garanteix que cap dels materials del seu lloc web sigui precís, complet o actual. Cuintab pot fer canvis en els materials continguts al seu lloc web en qualsevol moment sense previ avís. Tot i això, Cuintab no es compromet a actualitzar els materials.

		6. Enllaços

		Cuintab no ha revisat tots els llocs enllaçats al seu lloc web i no es fa responsable dels continguts d’aquest lloc enllaçat. La inclusió de qualsevol enllaç no implica l’aprovació per part de Cuintab del lloc. L’ús d’aquest lloc web enllaçat corre a compte i risc de l’usuari.

		7. Modificacions

		Cuintab pot revisar aquestes condicions del servei del seu lloc web en qualsevol moment sense previ avís. En utilitzar aquest lloc web, accepteu estar obligat a la versió actual d’aquests termes i condicions.

		8. Llei de govern

		Aquests termes i condicions es regeixen i s’interpreten d’acord amb les lleis de Barcelona i se sotmeten irrevocablement a la jurisdicció exclusiva dels jutjats d’aquest estat o ubicació.`,

	privacy_title: 'Política de privacitat',
	privacy_content: `
		La vostra privadesa és important per a nosaltres. La política de Cuintab és respectar la vostra privadesa quant a la informació que puguem recollir a través del nostre lloc web, https://cuintab.com i altres llocs que posseïm i operem.

		Només demanem informació personal quan realment la necessitem per proporcionar-vos un servei. El recopilem per mitjans legítims i justos, amb el vostre coneixement i consentiment. També us fem saber per què el recopilem i com s’utilitzarà.

		Només conservem la informació recopilada durant el temps necessari per proporcionar-vos el servei sol·licitat. Quines dades emmagatzemarem, protegirem amb mitjans comercialment acceptables per evitar pèrdues i robatoris, així com accés, divulgació, còpia, ús o modificació no autoritzats.

		No compartim informació d’identificació personal públicament ni amb tercers, excepte quan la llei ho exigeixi.

		El nostre lloc web pot enllaçar a llocs externs que no gestionem. Tingueu en compte que no tenim cap control sobre el contingut i les pràctiques d’aquests llocs i que no podem assumir cap responsabilitat per les seves respectives polítiques de privadesa.

		Vostè és lliure de rebutjar la nostra sol·licitud d’informació personal, entenent que és possible que no puguem proporcionar-li alguns dels serveis desitjats.

		L’ús continuat del nostre lloc web es considerarà com l’acceptació de les nostres pràctiques sobre privadesa i informació personal. Si teniu cap pregunta sobre com gestionem les dades d’usuari i la informació personal, no dubteu en posar-vos en contacte amb nosaltres.

		Aquesta política és efectiva a partir de l’1 de juny de 2020.`,
};
