import React from 'react';
import authenticationService from "../../services/AuthenticationService";
import SubmissionContainer from '../../components/SubmissionContainer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as yup from 'yup';


class Registration extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			displayRegistrationOk: false,
			formSubmitting: false,
			error: undefined,
		}
	}

	handleSubmit = values => {
		this.setState({
			formSubmitting: true,
		});
		authenticationService.register(values.firstName, values.password1, values.password2, values.email)
			.then(() => {
				this.setState({ displayRegistrationOk: true });
			}).catch(result => {
				this.setState({
					formSubmitting: false,
				});
				if (result) {
					if ('password1' in result) {
						if (result['password1'].includes('This password is too common.'))
							this.setState({ error: 'user_registration_error_common_password' });
						else
							this.setState({ error: 'user_registration_error_unknown_password_error' });
					}
				}
			});
	}

	renderFeedback = () => {
		return (
			<div>
				<p>
					{this.props.t('user_registration_registration_complete_feedback')}
				</p>
				<Button variant="secondary" type="submit" onClick={() => this.props.history.push('/login')}>
					{this.props.t('user_registration_registration_complete_goto_login')}
				</Button>
			</div>
		);
	}

	renderForm = () => {
		const formSchema = yup.object({
			firstName: yup.string().required('form_required_field'),
			email: yup.string().email('form_format_email').required('form_required_field'),
			password1: yup.string().min(8, 'form_password_length').matches(/(?!^\d+$)^.+$/, 'form_numeric_password').required('form_required_field'),
			password2: yup.string().required('form_required_field').oneOf([yup.ref('password1')], 'form_passwords_dont_match')
		});

		return (
			<Container className='pt-4 pb-4'>
				<Row>
					<Col xs={4} style={{ marginTop: '70px' }}>
						<p style={{ color: '#D4E056', textAlign: 'right', fontWeight: 800, marginBottom: '5px', fontSize: '36px' }}>{this.props.t('user_registration_title')}</p>
					</Col>
					<Col xs={4} className='ml-4' style={{ marginTop: '70px' }}>
						<Formik validationSchema={formSchema} initialValues={{firstName: '', email: '', password1: '', password2: ''}}>
							{({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
								<div>
									<Form.Group controlId="firstName">
										<Form.Label>{this.props.t('user_registration_name_title')}</Form.Label>
										<Form.Control
											autoFocus
											name="firstName"
											placeholder={this.props.t('user_registration_name_placeholder')}
											value={values.firstName}
											onChange={handleChange}
											isInvalid={errors.firstName}
											onBlur={handleBlur}
										/>
										<Form.Control.Feedback type="invalid">
											{this.props.t(errors.firstName)}
										</Form.Control.Feedback>
									</Form.Group>

									<Form.Group controlId="email">
										<Form.Label>{this.props.t('user_registration_email_title')}</Form.Label>
										<Form.Control
											type="email"
											name="email"
											placeholder={this.props.t('user_registration_email_placeholder')}
											value={values.email}
											onChange={handleChange}
											isInvalid={errors.email}
											onBlur={handleBlur}
										/>
										<Form.Control.Feedback type="invalid">
											{this.props.t(errors.email)}
										</Form.Control.Feedback>
									</Form.Group>

									<Form.Group controlId="password1">
										<Form.Label>{this.props.t('user_registration_password_title')}</Form.Label>
										<OverlayTrigger placement='right' overlay={<Tooltip id='password-info-tooltip'>{this.props.t('user_registration_password_info')}</Tooltip>}>
											<FontAwesomeIcon icon="info-circle"/>
										</OverlayTrigger>
										<Form.Control
											type="password"
											name="password1"
											placeholder={this.props.t('user_registration_password_placeholder')}
											value={values.password1}
											onChange={handleChange}
											isInvalid={errors.password1}
											onBlur={handleBlur}
										/>
										<Form.Control.Feedback type="invalid">
											{this.props.t(errors.password1)}
										</Form.Control.Feedback>
									</Form.Group>
									<Form.Group controlId="password2">
										<Form.Label>{this.props.t('user_registration_repeat_password_title')}</Form.Label>
										<Form.Control
											type="password"
											name="password2"
											placeholder={this.props.t('user_registration_repeat_password_placeholder')}
											value={values.password2}
											onChange={handleChange}
											isInvalid={errors.password2}
											onBlur={handleBlur}
										/>
										<Form.Control.Feedback type="invalid">
											{this.props.t(errors.password2)}
										</Form.Control.Feedback>
									</Form.Group>
									<SubmissionContainer loading={this.state.formSubmitting} style={{ justifyContent: 'left' }}>
										<Button variant="primary" type="submit" disabled={!isValid} onClick={isValid ? () => this.handleSubmit(values) : undefined}>
											{this.props.t('user_registration_registration_action')}
										</Button>
										<div className="error">{this.props.t(this.state.error)}</div>
									</SubmissionContainer>
								</div>
							)}
						</Formik>
						<p>
						{this.props.t('user_registration_acknowledge_legal')}
						{` `}
						<a href={'/terms'} style={{ color: 'var(--accent)' }}>{this.props.t('user_registration_terms_link')}</a>
						{`, `}
						<a href={'/privacy'} style={{ color: 'var(--accent)' }}>{this.props.t('user_registration_privacy_link')}</a>
						</p>
						<Button variant="link" type="submit" onClick={() => this.props.history.push('/login')}>
							{this.props.t('user_registration_already_registered_button')}
						</Button>
					</Col>
				</Row>
			</Container>
		);
	}

	render () {    
		return this.state.displayRegistrationOk ? this.renderFeedback() : this.renderForm();
	}
};

export default withTranslation()(withRouter(Registration));
