import React from 'react';
import authenticationService from "../../services/AuthenticationService";
import SubmissionContainer from '../../components/SubmissionContainer';

import { Container, Col, Row, Form, Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as yup from 'yup';


class PasswordReset extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			displayPasswordResetOk: false,
			formSubmitting: false,
		}
	}

	handleSubmit = values => {
		this.setState({
			formSubmitting: true,
		});

		authenticationService.passwordReset(values.email)
			.then(() => {
				this.setState({ displayPasswordResetOk: true });
			}).catch(result => {
				this.setState({
					formSubmitting: false,
				});
			});
	}

	renderFeedback = () => {
		return (
			<div>
				<p>
					{this.props.t('user_password_reset_email_sent_feedback')}
				</p>
				<Button variant="secondary" type="submit" onClick={() => this.props.history.push('/login')}>
					{this.props.t('user_password_reset_complete_goto_login')}
				</Button>
			</div>
		);
	}

	renderForm = () => {
		return (
			<Container className='pt-4 pb-4'>
				<Row>
					<Col xs={4} style={{ marginTop: '70px' }}>
						<p style={{ color: '#D4E056', textAlign: 'right', fontWeight: 800, marginBottom: '5px', fontSize: '36px' }}>{this.props.t('user_password_reset_title')}</p>
					</Col>
					<Col xs={4} className='ml-4' style={{ marginTop: '70px' }}>
						<Formik validationSchema={yup.object({ email: yup.string().email('form_format_email').required('form_required_field') })} initialValues={{ email: '' }}>
							{({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
								<div>
									<Form.Group controlId="email">
										<Form.Label>{this.props.t('user_password_reset_email_title')}</Form.Label>
										<Form.Control
											autoFocus
											type="email"
											name="email"
											placeholder={this.props.t('user_password_reset_email_placeholder')}
											value={values.email}
											onChange={handleChange}
											isInvalid={errors.email}
											onBlur={handleBlur}
										/>
										<Form.Control.Feedback type="invalid">
											{this.props.t(errors.email)}
										</Form.Control.Feedback>
									</Form.Group>
									<SubmissionContainer loading={this.state.formSubmitting} style={{ justifyContent: 'left' }}>
										<Button variant="primary" type="submit" disabled={!isValid} onClick={isValid ? () => this.handleSubmit(values) : undefined}>
											{this.props.t('user_password_reset_reset_action')}
										</Button>
									</SubmissionContainer>
								</div>
							)}
						</Formik>
						<Button variant="link" type="submit" onClick={() => this.props.history.push('/login')}>
							{this.props.t('user_password_reset_back_to_login_button')}
						</Button>
					</Col>
				</Row>
			</Container>
		);
	}

	render () {    
		return this.state.displayPasswordResetOk ? this.renderFeedback() : this.renderForm();
	}
};

export default withTranslation()(withRouter(PasswordReset));
