import Api from "./Api";

class UserService {
	constructor() {
		this.userInfoKey = 'user_info';
		this.checkInRestaurantKey = 'check_in_restaurant';
	}

	changePassword = (password1, password2) => {
		const requestSetup = {
			endpoint: '/auth/password/change/',
			method: 'POST',
			data: { new_password1: password1, new_password2: password2 },
			headers: Api.getHeaders(true),
		};
		Api.request(requestSetup)
			.then(() => null)
			.catch(result => {
				return result.response.data;
			});
	}

	confirmPasswordReset = (uid, token, new_password, repeated_new_password) => {
		const data = {
			uid: uid,
			token: token,
			new_password1: new_password,
			new_password2: repeated_new_password,
		};
		const requestSetup = {
			endpoint: '/auth/password/reset/confirm/',
			method: 'POST',
			data: data,
			headers: Api.getHeaders(false),
		};
		return Api.request(requestSetup);
	}

	updateUser = (firstName, locale) => {
		const data = {
			first_name: firstName,
			locale
		};
		const requestSetup = {
			endpoint: '/users/',
			method: 'PUT',
			data: data,
			headers: Api.getHeaders(true),
		};
		return new Promise((resolve, reject) => {
			Api.request(requestSetup)
				.then(userInfo => {
					this._setUserInfo(userInfo);
					const userInfoObj = JSON.parse(userInfo);
					resolve(userInfoObj);
				})
				.catch(error => {
					reject(error);
				}
			);
		});
	}

	_setUserInfo = userInfo => {
		window.sessionStorage.setItem(this.userInfoKey, userInfo);
	}

	_forceGetUserInfo = () => {
		const requestSetup = {
			endpoint: '/users/',
			method: 'GET',
			headers: Api.getHeaders(true),
		};
		return new Promise((resolve, reject) => {
			Api.request(requestSetup)
				.then(userInfo => {
					this._setUserInfo(userInfo);
					const userInfoObj = JSON.parse(userInfo);
					const checkInId = this.getCheckInRestaurantId();
					// check in to first if it wasn't checked into any restaurant or the restaurant id is not available anymore
					if (undefined === checkInId ||  undefined === userInfoObj.restaurant_memberships.find(membership => membership.restaurant.id === checkInId))
						this._checkInFIRSTRestaurant(userInfoObj);
					resolve(userInfoObj);
				})
				.catch(error => {
					reject(error);
				}
			);
		});
	}

	_getLocalUserInfo = () => {
		const userInfo = window.sessionStorage.getItem(this.userInfoKey);
		return JSON.parse(userInfo);
	}

	getUserInfo = () => {
		const userInfo = this._getLocalUserInfo();
		if (userInfo !== null && userInfo !== undefined) {
			// refresh in background
			this._forceGetUserInfo();
			return new Promise((resolve, reject) => {
				resolve(userInfo);
			});
		}
		return this._forceGetUserInfo();
	}

	clearUserInfo = () => {
		window.sessionStorage.removeItem(this.userInfoKey);
		window.sessionStorage.removeItem(this.checkInRestaurantKey);
	}

	_checkInFIRSTRestaurant = userInfo => {
		if (0 < userInfo.restaurant_memberships.length)
			this.checkInRestaurant(userInfo.restaurant_memberships[0].restaurant.id);
	}

	checkInRestaurant = restaurantId => {
		window.sessionStorage.setItem(this.checkInRestaurantKey, restaurantId);
	}

	getCheckInRestaurantId = () => {
		const restaurantId = window.sessionStorage.getItem(this.checkInRestaurantKey);
		return undefined !== restaurantId ? parseInt(restaurantId) : undefined;
	}

	getCheckInRestaurantInfo = () => {
		const restaurantId = this.getCheckInRestaurantId();
		const userInfo = this._getLocalUserInfo();
		if (restaurantId !== undefined) {
			const restaurant = userInfo.restaurant_memberships.find(membership => restaurantId === membership.restaurant.id)
			return restaurant;
		}
	}

	getRestaurantMemberships = () => this._getLocalUserInfo().restaurant_memberships;

	getTag = tagId => {
		const restaurantTags = this.getCheckInRestaurantInfo().restaurant.tags;
		return restaurantTags.find(restaurantTag => restaurantTag.id === tagId);
	}

	getFirstBrowserLocale = () => {
		/* https://stackoverflow.com/questions/1043339/javascript-for-detecting-browser-language-preference/46514247#46514247 */

		var nav = window.navigator,
			browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'],
			i,
			language,
			len,
			shortLanguage = null;

		// support for HTML 5.1 "navigator.languages"
		if (Array.isArray(nav.languages)) {
			for (i = 0; i < nav.languages.length; i++) {
				language = nav.languages[i];
				len = language.length;
				if (!shortLanguage && len) {
					shortLanguage = language;
				}
				if (language && len>2) {
					return language;
				}
			}
		}

		// support for other well known properties in browsers
		for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
			language = nav[browserLanguagePropertyKeys[i]];
			//skip this loop iteration if property is null/undefined.  IE11 fix.
			if (language == null) { continue; } 
			len = language.length;
			if (!shortLanguage && len) {
				shortLanguage = language;
			}
			if (language && len > 2) {
				return language;
			}
		}

		return shortLanguage;
	}

	getLocale = () => {
		const localUserInfo = this._getLocalUserInfo();
		return localUserInfo && localUserInfo.locale ? localUserInfo.locale : this.getFirstBrowserLocale();
	}

	contact = (contact, content) => {
		const data = { email: contact, content };
		const requestSetup = {
			endpoint: `/contact/`,
			method: 'POST',
			data,
			headers: Api.getHeaders(false),
		};
		return Api.request(requestSetup)
	}
}

export default new UserService();
