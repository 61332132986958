import React from "react";

import { Table } from 'react-bootstrap'
import { withTranslation } from 'react-i18next';

import IngredientsParser from '../../utils/IngredientsParser';


/**
 *  This class represents the displaying of the escandall's ingredients
 */
class EscandallIngredients extends React.Component {
	getRenderedRows = () => {
		const ingredientsParser = new IngredientsParser(this.props.ingredients, this.props.customMeasure)
		const renderedRows = this.props.ingredients.map((ingredient, i) => {
			const urlType = ingredient.ingredient_type === 'product' ? 'products' : 'preps';
			const costPercent = ingredientsParser.netCostPercent(i);
			const style = {
				// background: `-webkit-linear-gradient(left, #F4F7D4 ${costPercent},#ffffff ${costPercent})`,
				// background:    `-moz-linear-gradient(left, #F4F7D4 ${costPercent}, #ffffff ${costPercent})`,
				// background:     `-ms-linear-gradient(left, #F4F7D4 ${costPercent},#ffffff ${costPercent})`,
				// background:      `-o-linear-gradient(left, #F4F7D4 ${costPercent},#ffffff ${costPercent})`,
				background:         `linear-gradient(to right, #F4F7D4 ${costPercent},#ffffff ${costPercent})`
			};
			return (
				<tr key={`ingredient-row-${i}`}>
					<td className="text-left">
						<a href={`/${urlType}/${ingredient.ingredient}`}>{ingredient.name}</a>
						<span style={{ color: '#AAAAAA' }}>{ingredient.note ? ` (${ingredient.note})` : ''}</span>
					</td>
					<td className="text-left">
						{ingredientsParser.measure(i)}
					</td>
					<td style={{ color: '#AAAAAA' }}>
						{ingredientsParser.netPrice(i)}
					</td>
					<td style={style}>
						{ingredientsParser.netCost(i)} ({costPercent})
					</td>
				</tr>
			);
		});
		return renderedRows;
	}

	render() {
		return (
			<Table className="ml-4">
				<thead>
					<tr>
						<th style={{ borderTop: 0 }}>{this.props.t('escandall_scene_ingredient_name')}</th>
						<th style={{ borderTop: 0 }}>{this.props.t('escandall_scene_ingredient_measure')}</th>
						<th style={{ borderTop: 0 }}>{this.props.t('escandall_scene_ingredient_net_price')}</th>
						<th style={{ borderTop: 0 }}>{this.props.t('escandall_scene_ingredient_cost')}</th>
					</tr>
				</thead>
				<tbody>
					{this.getRenderedRows()}
				</tbody>
			</Table>
		);
	}
}

export default withTranslation()(EscandallIngredients);
