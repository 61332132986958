import React from "react";

import { Row, Col, ListGroup, Container, Button, Badge, Tabs, Tab, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RestaurantConfiguration from "./RestaurantConfiguration";
import RestaurantSubscription from "./RestaurantSubscription";
import RestaurantForm from "./RestaurantForm";
import SubmissionContainer from '../../components/SubmissionContainer';

import userService from '../../services/UserService';
import restaurantService from '../../services/RestaurantService';

import '../../styles/scenes/restaurants/RestaurantsScene.css';


/**
 *  This class represents the display of the list of escandalls and a side escandall detail
 */
class RestaurantsScene extends React.Component {
	constructor(props) {
		super(props);

		const memberships = userService.getRestaurantMemberships();

		this.state = {
			memberships,
			displayedRestaurantIndex: undefined,
			creating: false,
		};
	}

	componentDidMount() {
		const id = parseInt(this.props.match.params.id);
		if (!isNaN(id)) {
			const index = this.state.memberships.findIndex(membership => membership.restaurant.id === id);
			if (0 <= index)
				this.displayRestaurant(index);
		}
		// else {
		// 	const checkedInFound = this.state.memberships.findIndex(membership => membership.restaurant.id === parseInt(userService.getCheckInRestaurantId()));
		// 	if (checkedInFound >= 0)
		// 		this.displayRestaurant(checkedInFound);
		// }
		document.getElementById('content').addEventListener('scroll', this.onScroll);
	}

	componentWillUnmount() {
		document.getElementById('content').removeEventListener('scroll', this.onScroll);
	}

	displayRestaurant = restaurantIndex => {
		this.setState({
			displayedRestaurantIndex: restaurantIndex,
		});
	}

	onNewClicked = event => {
		this.setState({
			creating: true,
		});
	}

	createdRestaurant = restaurantId => {
		this.props.history.push(`/restaurants/${restaurantId}`);
		window.location.reload();
	}

	handleCloseEdit = () => {
		this.setState({
			creating: false,
		});
	}

	deleteRestaurant = () => {
		restaurantService.deleteRestaurant(this.state.deletingRestaurant.id)
			.then(() => {
				return userService._forceGetUserInfo()
					.then(() => {
						window.location.reload();
					});
			});
	}

	getRenderedTags = tagIds =>
		tagIds.map(tagId => {
			const tag = userService.getTag(tagId);
			if (tag)
				return <Badge key={`restaurant-tag-display-${tagId}`} variant='primary' className='mr-1' style={{color: tag.text_color, backgroundColor: tag.color}}>{tag.name}</Badge>
			return null;
		});

	getRenderedDetail = restaurantInfo => {
		return (
			<Container>
				<Tabs defaultActiveKey="general" id="restaurant-tabs">
					<Tab eventKey="general" title={this.props.t('restaurant_scene_configuration_tab')}>
						<RestaurantConfiguration key={`restaurant-info-${restaurantInfo.restaurant.id}`} restaurantInfo={restaurantInfo} />
						{/* TODO put into new category "Administration" with download excels, etc. */}
						<Button variant="outline-destructive" style={{ marginTop: '50px', marginBottom: '50px'}} onClick={() => this.setState({ deletingRestaurant: restaurantInfo.restaurant })}>{this.props.t('restaurant_scene_delete')}</Button>
					</Tab>
					<Tab eventKey="members" title={this.props.t('restaurant_scene_members_tab')} disabled>
						bbbbb
					</Tab>
					<Tab eventKey="log" title={this.props.t('restaurant_scene_log_tab')} disabled>
						cccccc
					</Tab>
					<Tab eventKey="subscription" title={this.props.t('restaurant_scene_subscription_tab')} disabled>
						<RestaurantSubscription key={`restaurant-subscription-${restaurantInfo.restaurant.id}`} restaurantInfo={restaurantInfo} />
					</Tab>
				</Tabs>
			</Container>
		);
	}
	
	getRenderedRows = () => {
		const renderedRows = this.state.memberships.map((membership, i) => {
			return (<ListGroup.Item className="text-left" active={this.state.displayedRestaurantIndex === i} key={`restaurant-membership-${i}`} action onClick={() => this.displayRestaurant(i)}>
					<span style={{ width: '100%' }} className='mb-1 float-left'>{membership.restaurant.name}</span>
					<Badge variant='primary' className='mr-1' style={{color: 'white', backgroundColor: 'black'}}>{membership.user_level}</Badge>
				</ListGroup.Item>);
			}
		);
		return renderedRows;
	}

	getRenderedEditionModal = () => {
		if (this.state.creating) {
			return (
				<RestaurantForm
					createdRestaurant={this.createdRestaurant}
					handleCloseEdit={this.handleCloseEdit}
				/>
			);
		}
	}

	getRenderedDeletingModal = () => {
		if (this.state.deletingRestaurant) {
			return (
				<Modal show={true} onHide={() => this.setState({ deletingRestaurant: null })}>
					<Modal.Header closeButton>
						<Modal.Title>{this.props.t('restaurant_scene_deleting_title')}</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<p>{this.props.t('restaurant_scene_deleting_text')} {this.state.deletingRestaurant.name}?</p>
					</Modal.Body>

					<Modal.Footer>
						<SubmissionContainer loading={this.state.formSubmitting}>
							<Button variant="outline-secondary" onClick={() => this.setState({ deletingRestaurant: null })}>{this.props.t('restaurant_scene_deleting_cancel')}</Button>
							<Button variant="destructive" onClick={this.deleteRestaurant}>{this.props.t('restaurant_scene_deleting_delete')}</Button>
						</SubmissionContainer>
					</Modal.Footer>
				</Modal>
			);
		}
	}

	render() {
		return (
			<Row className='p-0 m-0' style={{ height: '100%' }}>
				<Col xs={3} className='border-right' style={{ height: '100%' }}>
					<div className='listContainer'>
						<ListGroup variant="flush">
							{this.getRenderedRows()}
						</ListGroup>
					</div>
					<OverlayTrigger overlay={<Tooltip id='new-restaurant-tooltip'>{this.props.t('restaurant_scene_new_restaurant_tooltip')}</Tooltip>} show={!this.state.memberships.length}>
						<Button variant="primary" onClick={this.onNewClicked} style={{ height: '59px', width: '100%', position: 'absolute', left: 0, bottom: 0, borderRadius: 0 }}>
							<span style={{ textTransform: 'uppercase', fontWeight: '800' }}>{this.props.t('restaurant_scene_new_restaurant')}</span>
							<FontAwesomeIcon style={{ marginLeft: '10px' }} icon="plus"/>
						</Button>
					</OverlayTrigger>
				</Col>
				<Col xs={9} id="detail" className="pt-4">
					{this.state.displayedRestaurantIndex !== undefined ? this.getRenderedDetail(this.state.memberships[this.state.displayedRestaurantIndex]) : null }
				</Col>
				{this.getRenderedEditionModal()}
				{this.getRenderedDeletingModal()}
			</Row>
		);
	}
}

export default withTranslation()(withRouter(RestaurantsScene));
