import React from 'react';

import userService from '../../services/UserService';
import restaurantService from '../../services/RestaurantService';
import SubmissionContainer from '../../components/SubmissionContainer';

import { Formik } from 'formik';
import * as yup from 'yup';
import { Container, Row, Col, Form, Button, Badge, Modal, Table } from 'react-bootstrap';
import { ChromePicker } from 'react-color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';


class RestaurantConfiguration extends React.Component {
	constructor(props) {
		super(props);

		this.restaurantInfo = this.props.restaurantInfo;
		this.restaurantTags = this.restaurantInfo.restaurant.tags;

		this.state = {
			restaurantName: this.restaurantInfo.restaurant.name,

			selectedTag: null,
            showTagForm: false,
            deletingTag: false,

            formSubmitting: false,
		}
	}

	handleBasicInfoSubmit = values => {
		this.setState({
			formSubmitting: true,
		});

		let restaurant = this.restaurantInfo.restaurant;
		restaurant.name = values.name;
		restaurantService.updateRestaurant(restaurant)
			.then(restaurant => {
				userService.clearUserInfo();
				window.location.reload();
			})
			.catch((error) => {
				this.setState({
					formSubmitting: false,
				});

				if ("non_field_errors" in error) {
					this.setState({
						error: error.non_field_errors,
					});
				}
			});
	}

	handleTagNameChange = event => {
		const tag = this.state.selectedTag;
        tag.name = event.target.value;
		this.setState({
			selectedTag: tag
		});
	}

	handleTagTextColorChange = color => {
		const tag = this.state.selectedTag;
		tag.text_color = color.hex;
		this.setState({
			selectedTag: tag,
		});
	}

	handleTagBackgroundColorChange = color => {
		const tag = this.state.selectedTag;
		tag.color = color.hex;
		this.setState({
			selectedTag: tag,
		});
	}

	editTagHandler = tag => {
		this.setState({
			selectedTag: Object.assign({}, tag),
			showTagForm: true
		});
	}

	deleteTagRequested = tag => {
		this.setState({
			selectedTag: tag,
			deletingTag: true
		});
	}

	handleSaveTag = () => {
		if (this.state.selectedTag.id === undefined) {
			if (this.state.selectedTag.color && this.state.selectedTag.text_color) {
				this.setState({
					formSubmitting: true,
				});

				restaurantService.createTag(this.state.selectedTag)
					.then((tag) => {
						userService.clearUserInfo();
						window.location.reload();
					})
					.catch((error) => {
						this.setState({
							formSubmitting: false,
						});
					});
			}
		}
		else {
			restaurantService.updateTag(this.state.selectedTag)
				.then((tag) => {
					userService.clearUserInfo();
					window.location.reload();
				})
				.catch((error) => {
					this.setState({
						formSubmitting: false,
					});
				});
		}
	}

	handleCancelEditTag = event => {
		this.setState({
			selectedTag: null,
			showTagForm: false,
		});
	}

	deleteTag = tag => {
		this.setState({
			formSubmitting: true,
		});
		restaurantService.deleteTag(this.state.selectedTag)
			.then(() => {
				userService.clearUserInfo();
				window.location.reload();
			})
			.catch((error) => {
				this.setState({
					formSubmitting: false,
				});
			});
	}

	renderTagList = () => {
		const tags = this.restaurantTags.map((tag, i) => {
			const tagStyle = {
				backgroundColor: tag.color,
				color: tag.text_color
			};
			return (
				<tr key={`tag-row-${i}`}>
					<td>
						<Badge style={tagStyle}>
							{tag.name}
						</Badge>
					</td>
					<td style={{ width: '1%' }}>
						<Button variant="link" style={{ color: '#e0a800', marginLeft: '70px' }} onClick={() => this.editTagHandler(tag)}>
							<FontAwesomeIcon icon="edit"/>
						</Button>
					</td>
					<td style={{ width: '1%' }}>
						<Button variant="link" style={{ color: '#c82333' }} onClick={() => this.deleteTagRequested(tag)}>
							<FontAwesomeIcon icon="trash-alt"/>
						</Button>
					</td>
				</tr>			
			);
		});
		return (
			<div>
				<Table className="ml-4" style={{ width: '40%' }}>
					<tbody>
						{tags}
					</tbody>
				</Table>
				<Button variant="secondary" type="submit" className="float-left" onClick={() => this.editTagHandler({ color: '#D4E056', text_color: '#505160' })}>
					{this.props.t('restaurant_configuration_create_tag_button')}
				</Button>
			</div>
		);
	}

	renderTagForm = () => {
		const tagStyle = {
			backgroundColor: this.state.selectedTag.color,
			color: this.state.selectedTag.text_color
		};
		return (
			<Row>
				<Formik
					validationSchema={yup.object({ name: yup.string().required('form_required_field') })}
					initialValues={ { name: this.state.selectedTag.name } }
				>
					{({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
						<Col>
							<Row>
								<Form.Group controlId="restaurantName">
									<Form.Control
										name="name"
										placeholder={this.props.t('restaurant_configuration_tag_placeholder')}
										value={this.state.selectedTag.name}
										onChange={event => { this.handleTagNameChange(event); handleChange(event); }}
										isInvalid={errors.name}
										onBlur={handleBlur}
									/>
									<Form.Control.Feedback type="invalid">
										{this.props.t(errors.name)}
									</Form.Control.Feedback>
								</Form.Group>
							</Row>
							<Row className='mb-4'>
								<ChromePicker color={this.state.selectedTag.text_color} onChange={this.handleTagTextColorChange} />
								<ChromePicker color={this.state.selectedTag.color} onChange={this.handleTagBackgroundColorChange} />
							</Row>
							<Row className='m-2'>
								<Badge style={tagStyle}>
									{this.state.selectedTag.name}
								</Badge>
							</Row>
							<Row>
								<SubmissionContainer loading={this.state.formSubmitting}>
									<Button variant="primary" type="submit" disabled={!isValid} onClick={isValid ? () => this.handleSaveTag() : undefined}>
										{this.props.t('restaurant_configuration_tag_save_button')}
									</Button>
									<Button variant="outline-secondary" onClick={this.handleCancelEditTag}>
										{this.props.t('restaurant_configuration_tag_cancel_button')}
									</Button>
								</SubmissionContainer>
							</Row>
						</Col>
					)}
				</Formik>
			</Row>
		);
	}

	getRenderedDeletingModal = () => {
		return (
			<Modal show={this.state.deletingTag} onHide={() => this.setState({ deletingTag: false })}>
				<Modal.Header closeButton>
					<Modal.Title>{this.props.t('restaurant_configuration_tag_delete_title')}</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<p>{this.props.t('restaurant_configuration_tag_delete_subtitle')}</p>
				</Modal.Body>

				<Modal.Footer>
					<SubmissionContainer loading={this.state.formSubmitting}>
						<Button variant="secondary" onClick={() => this.setState({ deletingTag: false })}>{this.props.t('restaurant_configuration_tag_delete_cancel')}</Button>
						<Button variant="outline-destructive" onClick={this.deleteTag}>{this.props.t('restaurant_configuration_tag_delete_action')}</Button>
					</SubmissionContainer>
				</Modal.Footer>
			</Modal>
		);
	}

	render () {
		return (
			<Container>
				<Row>
					<Formik
						validationSchema={yup.object({ name: yup.string().required('form_required_field') })}
						initialValues={ { name: this.state.restaurantName } }
					>
						{({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
							<Col>
								<h4 className='text-left mb-4 mt-3'>{this.props.t('restaurant_configuration_basic_section_title')}</h4>
								
								<Form.Group controlId="restaurantName">
									<Form.Label>{this.props.t('restaurant_configuration_name_title')}</Form.Label>
									<Form.Control
										name="name"
										placeholder={this.props.t('restaurant_configuration_name_placeholder')}
										value={values.name}
										onChange={handleChange}
										isInvalid={errors.name}
										onBlur={handleBlur}
									/>
									<Form.Control.Feedback type="invalid">
										{this.props.t(errors.name)}
									</Form.Control.Feedback>
								</Form.Group>
								<SubmissionContainer loading={this.state.formSubmitting} style={{ justifyContent: 'left' }}>
									<Button variant="primary" disabled={!isValid} type="submit" onClick={isValid ? () => this.handleBasicInfoSubmit(values) : undefined}>
										{this.props.t('restaurant_configuration_basic_config_save_button')}
									</Button>
								</SubmissionContainer>
							</Col>
						)}
					</Formik>
				</Row>
				<Row>
					<Col>
						<h4 className='text-left mb-4 mt-3'>{this.props.t('restaurant_configuration_tags_section_title')}</h4>
						{this.state.showTagForm ? this.renderTagForm() : this.renderTagList()}
					</Col>
				</Row>
				{this.getRenderedDeletingModal()}
			</Container>
		);
	}
};

export default withTranslation()(RestaurantConfiguration); 
