import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import './styles/theme.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom'
import * as serviceWorker from './serviceWorker';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faBan, faTrashAlt, faEdit, faPlus, faGripLines, faInfoCircle, faBars, faUsers, faSmile, faHandSparkles } from '@fortawesome/free-solid-svg-icons'

import caLanding from './assets/locales/ca/landing';
import enLanding from './assets/locales/en/landing';
import esLanding from './assets/locales/es/landing';
import caTranslations from './assets/locales/ca/common';
import enTranslations from './assets/locales/en/common';
import esTranslations from './assets/locales/es/common';
import caLegal from './assets/locales/ca/legal';
import enLegal from './assets/locales/en/legal';
import esLegal from './assets/locales/es/legal';

import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import userService from './services/UserService';

i18n
	.use(initReactI18next)
	.init({
		resources: {
			ca: { translation: {...caTranslations, ...caLanding, ...caLegal }},
			en: { translation: {...enTranslations, ...enLanding, ...enLegal }},
			es: { translation: {...esTranslations, ...esLanding, ...esLegal }}
		},
		lng: userService.getLocale(),
		fallbackLng: 'en',
		supportedLngs: ['ca', 'es', 'en'],

		interpolation: {
		escapeValue: false
	}
	});

library.add(faBan, faTrashAlt, faEdit, faPlus, faGripLines, faInfoCircle, faBars, faUsers, faSmile, faHandSparkles);

ReactDOM.render((
    <BrowserRouter>
        <App />
    </BrowserRouter>
), document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
